import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { getTimeDiff } from '../../utils/date';

import styles from './styles.module.css';

const CountdownClock = () => {
  const [date, setDate] = useState('');
  const currentItem = useSelector((store) => store.searchFeed.currentItem);

  useEffect(() => {
    setTimeout(() => {
      setDate(getTimeDiff(currentItem && currentItem.due_date));
    }, 1000);
  }, [currentItem, date]);

  return (
    <h3 className={styles.text}>{date}</h3>
  );
};

export default CountdownClock;
