import React from 'react';
import { useSelector } from 'react-redux';

const ProductItemsAmountRight = () => {
  const total = useSelector((store) => store.productsFeed.totalProducts);
  const finished = useSelector((store) => store.productsFeed.currentItemNumber);

  if (finished > total) {
    return <h3>0/0</h3>;
  }

  return (
    <h3>{`${finished}/${total}`}</h3>
  );
};

export default ProductItemsAmountRight;
