import React, {
  useEffect, useState, useCallback,
} from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { KEYS } from '../../utils/keycode';
import ColorizedInput from '../ColorizedInput';
import AutocompleteSuggest from './AutocompleteSuggest';

import styles from './styles.module.css';

const AutocompleteInput = ({
  id,
  defaultValue,
  onChange,
  onKeyPress,
  field,
  options,
  disabled,
  onFocus,
  onShowChange,
  onTabKeyDown,
  ...rest
}) => {
  const [inputValue, setInputValue] = useState(defaultValue);
  const [suggestVisible, setSuggestVisible] = useState(false);
  const [keyboardIndex, setKeyboardIndex] = useState(-1);

  const onOutsideClick = useCallback((e) => {
    if (!e || !e.target.getAttribute || e.target.getAttribute('id') !== id) {
      setSuggestVisible(false);
      if (onShowChange) {
        onShowChange(false);
      }
    }
  }, [id]);

  const showSuggestion = () => {
    if (onShowChange) {
      onShowChange(true);
    }
    setSuggestVisible(true);
  };

  useEffect(() => () => {
  }, [onOutsideClick]);

  useEffect(() => {
    setInputValue(defaultValue);
  }, [defaultValue]);

  const onInputChange = async (e) => {
    setInputValue(e.target.value);
    onChange(e.target.value, field);
  };

  const onOptionClick = (e, opt) => {
    e.stopPropagation();
    e.preventDefault();
    setInputValue(opt);
    onChange(opt, field);
    onOutsideClick(e);
  };

  const onFocusHandler = (e) => {
    if (onFocus) {
      onFocus(e);
    }
    e.stopPropagation();
    e.preventDefault();
    showSuggestion();
  };

  const onKeyDown = (e) => {
    if (e.key === 'Tab') {
      if (onTabKeyDown) {
        onTabKeyDown(e);
      }
      onOutsideClick();
    } else if (e.key === KEYS.ARROW_DOWN) {
      setKeyboardIndex(Math.min(Math.max(0, keyboardIndex + 1), 4));
    } else if (e.key === KEYS.ARROW_UP) {
      setKeyboardIndex(Math.max(0, keyboardIndex - 1));
    } else if (e.key === KEYS.ENTER) {
      setInputValue(options[keyboardIndex]);
      setKeyboardIndex(-1);
      onChange(options[keyboardIndex], field);
      onOutsideClick();
    } else {
      showSuggestion();
    }
  };

  return (
    <div className={styles.container}>
      <ColorizedInput
        className={cx(styles.input)}
        value={inputValue}
        onChange={onInputChange}
        onFocus={onFocusHandler}
        onKeyPress={onKeyPress}
        onKeyDown={onKeyDown}
        id={id}
        autoComplete="off"
        disabled={disabled}
        {...rest}
      />
      {suggestVisible && options.length > 0 && !disabled && (
        <div className={styles.suggest}>
          <AutocompleteSuggest
            onOutsideClick={onOutsideClick}
            options={options}
            onOptionClick={onOptionClick}
            keyboardIndex={keyboardIndex}
          />
        </div>
      )}
    </div>
  );
};

AutocompleteInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  defaultValue: PropTypes.string,
  onKeyPress: PropTypes.func,
  id: PropTypes.string.isRequired,
  field: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  disabled: PropTypes.bool,
  onFocus: PropTypes.func,
  onShowChange: PropTypes.func,
  onTabKeyDown: PropTypes.func,
};

AutocompleteInput.defaultProps = {
  defaultValue: '',
  onKeyPress: null,
  field: '',
  disabled: false,
  onFocus: null,
  onShowChange: null,
  onTabKeyDown: null,
};

export default AutocompleteInput;
