import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import ReactImageMagnify from 'react-image-magnify';

import Loader from '../Loader';
import BulkMatchButtons from '../BulkMatchButtons';

import { getBorderCssStyleValue, getOutlineCssStyleValue } from '../../utils/border';
import { prefetchImagesByUrls, prefetchImagesWithoutCache } from '../../utils/image';

import styles from './styles.module.css';

const LOADER_DELAY_MS = 1000;

const ProductsCarousel = ({
  images,
  prefetchImages,
  mediumImages,
  largeImages,
  colors,
  mainImageIndex,
  onClick,
  border,
  align,
  matchButtons,
}) => {
  const [mainImageLoaded, setMainImageLoaded] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const loaderRef = useRef();
  const prevImages = useRef();

  useEffect(() => {
    if (prefetchImages) {
      prefetchImagesByUrls(prefetchImages);
    }
  }, [prefetchImages]);

  useEffect(() => {
    let timeout = null;
    if (images) {
      timeout = setTimeout(() => {
        prefetchImagesWithoutCache(images);
      }, 3000);
    }

    return () => clearTimeout(timeout);
  }, [images]);

  useEffect(() => {
    if (prevImages.current?.[0] === mediumImages[0]) {
      return;
    }

    prevImages.current = mediumImages;
    setMainImageLoaded(false);
    clearInterval(loaderRef.current);
    loaderRef.current = setTimeout(() => {
      setShowLoader(true);
    }, LOADER_DELAY_MS);
  }, [mediumImages]);

  const showMainImageLoader = !mainImageLoaded;

  return (
    <div className={styles.container}>
      {showLoader && <Loader className={styles.loader} />}
      <ReactImageMagnify
        smallImage={{
          alt: 'main',
          src: mediumImages[mainImageIndex],
          isFluidWidth: true,
          onLoad: () => {
            setMainImageLoaded(true);
            setShowLoader(false);
            clearInterval(loaderRef.current);
          },
        }}
        largeImage={{
          src: largeImages[mainImageIndex],
          width: 600,
          height: 750,
        }}
        enlargedImagePosition="over"
        imageClassName={cx(styles.mainImage)}
        style={{
          border: getBorderCssStyleValue(border),
          outline: getOutlineCssStyleValue(border),
          alignSelf: align === 'left' ? 'flex-start' : 'flex-end',
          visibility: showMainImageLoader ? 'hidden' : 'initial',
        }}
      />
      <div className={styles.bulkButtons}>
        {!!matchButtons && (
          <BulkMatchButtons
            itemSelector={matchButtons && matchButtons.itemSelector}
            matchAction={matchButtons && matchButtons.matchAction}
            finalizeIfNeed={matchButtons && matchButtons.finalizeIfNeed}
          />
        )}
      </div>
      <p className={styles.colors}>{colors.join(', ')}</p>
      <div
        className={styles.carousel}
        style={{
          alignSelf: align === 'left' ? 'flex-end' : 'flex-start',
        }}
      >
        {images.length > 1 && images.map((image, idx) => (
          <img
            key={image}
            src={image}
            alt="carousel"
            className={
              cx(styles.carouselItem, mainImageIndex === idx && styles.activeCarouselItem)
            }
            onClick={() => onClick && onClick(idx)}
          />
        ))}
      </div>
    </div>
  );
};

ProductsCarousel.propTypes = {
  images: PropTypes.arrayOf(PropTypes.string).isRequired,
  prefetchImages: PropTypes.arrayOf(PropTypes.string),
  mediumImages: PropTypes.arrayOf(PropTypes.string).isRequired,
  largeImages: PropTypes.arrayOf(PropTypes.string).isRequired,
  colors: PropTypes.arrayOf(PropTypes.string),
  mainImageIndex: PropTypes.number.isRequired,
  onClick: PropTypes.func,
  border: PropTypes.shape({
    color: PropTypes.string.isRequired,
    double: PropTypes.bool.isRequired,
  }),
  align: PropTypes.oneOf(['left', 'right']),
};

ProductsCarousel.defaultProps = {
  prefetchImages: [],
  colors: [],
  onClick: null,
  border: null,
  align: 'left',
};

export default ProductsCarousel;
