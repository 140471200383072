import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap';

const NoMoreItemsModal = ({
  title,
  button,
  selector,
  action,
}) => {
  const show = useSelector(selector);
  const dispatch = useDispatch();
  const history = useHistory();

  const onButtonClick = () => {
    dispatch(action());
    history.push('/');
  };

  return (
    <Modal show={show} backdrop="static" keyboard={false}>
      <Modal.Body>{title}</Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={onButtonClick}>
          {button}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

NoMoreItemsModal.propTypes = {
  title: PropTypes.string.isRequired,
  button: PropTypes.string.isRequired,
  selector: PropTypes.func.isRequired,
  action: PropTypes.func.isRequired,
};

export default NoMoreItemsModal;
