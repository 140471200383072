import { useEffect } from 'react';
import PropTypes from 'prop-types';

const KeyboardProvider = ({ children, onKeyDown, onKeyUp }) => {
  const disableRightClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    return false;
  };

  useEffect(() => {
    window.addEventListener('keydown', onKeyDown);
    window.addEventListener('contextmenu', disableRightClick);
    if (onKeyUp) {
      window.addEventListener('keyup', onKeyUp);
    }

    return () => {
      window.removeEventListener('keydown', onKeyDown);
      window.removeEventListener('contextmenu', disableRightClick);
      if (onKeyUp) {
        window.removeEventListener('keyup', onKeyUp);
      }
    };
  }, [onKeyDown, onKeyUp]);

  return children;
};

KeyboardProvider.propTypes = {
  children: PropTypes.node.isRequired,
  onKeyDown: PropTypes.func.isRequired,
};

export default KeyboardProvider;
