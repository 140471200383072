export const COLUMNS_KEY = 'columns';
export const COLUMNS_OPTIONS_KEY = 'columns_options';

const extractFromLocalStorage = (key, defaultValue = null) => {
  try {
    return JSON.parse(localStorage.getItem(key));
  } catch (err) {
    return defaultValue;
  }
};

export const getActiveColumnsData = () => extractFromLocalStorage(COLUMNS_KEY);

export const getColumnsOptionsData = () => extractFromLocalStorage(COLUMNS_OPTIONS_KEY);

export const saveActiveColumnsData = (data) => {
  localStorage.setItem(COLUMNS_KEY, JSON.stringify(data));
};

export const saveColumnsOptionsData = (data) => {
  localStorage.setItem(COLUMNS_OPTIONS_KEY, JSON.stringify(data));
};
