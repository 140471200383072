import { createSlice } from '@reduxjs/toolkit';

import { fetchDictionaries } from './middleware';

import { getOptionsFromStrings, getOptionsFromCategoriesList } from '../../utils/options';

const initialState = {
  brands: [],
  colors: [],
  categories: [],
};

export const dictionariesSlice = createSlice({
  name: 'dictionaries',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchDictionaries.fulfilled, (state, action) => {
      state.categories = getOptionsFromCategoriesList(action.payload?.categories ?? []);
      state.brands = getOptionsFromStrings(action.payload?.brands ?? []);
      state.colors = getOptionsFromStrings(action.payload?.colors ?? []);
    });
  },
});

export { fetchDictionaries };

export default dictionariesSlice.reducer;
