import React from 'react';

import SectionHeader from '../SectionHeader';
import TemplateNote from '../TemplateNote';
import SectionBodyLayout from '../SectionBodyLayout';
import BulkProductsScroll from '../BulkProductsScroll';
import ProductItemMainImage from '../ProductItemMainImage';
import ProductItemsAmountRight from '../ProductItemsAmountRight';

const BulkProductsSection = () => (
  <>
    <SectionHeader rightSideComponent={<ProductItemsAmountRight />}>
      <TemplateNote selector={(store) => store.searchFeed.currentItem} />
    </SectionHeader>
    <SectionBodyLayout listPosition="left" listComponent={<BulkProductsScroll />}>
      <ProductItemMainImage withMatchButtons />
    </SectionBodyLayout>
  </>
);

export default BulkProductsSection;
