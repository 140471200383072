import { createSlice } from '@reduxjs/toolkit';
import _uniqBy from 'lodash/uniqBy';

import { PRODUCT_ACTIONS } from '../../utils/actions';
import { NEXT_CHUNK_FIRST_ITEM } from '../../utils/chunk';

import {
  fetchProductsFeed,
  setMatchAction,
  eraseLastItem,
  bulkSkipAction,
  bulkNextPage,
  bulkPrevPage,
  checkAllMatched,
} from './middleware';

const initialState = {
  items: [],
  totalProducts: 0,
  currentItem: null,
  currentItemNumber: 0,
  mainImageIndex: 0,
  allItemsFetched: false,
  bulkMatchedIds: [],
  bulkOffset: 0,
  bulkBackwardOffset: 0,
  finalizeModal: false,
};

export const productsFeedSlice = createSlice({
  name: 'productsFeed',
  initialState,
  reducers: {
    setFinalizeModal: (state, action) => {
      state.finalizeModal = action.payload;
    },
    addBulkMatchedIds: (state, action) => {
      state.bulkMatchedIds = [...state.bulkMatchedIds, ...action.payload];
      state.items.forEach((item) => {
        if (state.bulkMatchedIds.includes(item.id)) {
          item.action = PRODUCT_ACTIONS.MODEL;
        }
      });
    },
    setCurrentItemNumber: (state, action) => {
      state.currentItemNumber = action.payload;
    },
    trimAfterId: (state, action) => {
      const index = state.items.findIndex((i) => i.id === action.payload);
      if (index !== -1) {
        state.items = state.items.slice(0, index + 1);
      }
    },
    trimBeforeId: (state, action) => {
      const index = state.items.findIndex((i) => i.id === action.payload);
      if (index !== -1) {
        state.items = state.items.slice(index);
      }
    },
    resetItems: (state) => {
      state.items = [];
      state.currentItem = null;
      state.mainImageIndex = 0;
      state.allItemsFetched = false;
      state.bulkMatchedIds = [];
    },
    setMainImageIndex: (state, action) => {
      const index = action.payload;

      if (state.currentItem && state.currentItem.images[index]) {
        state.mainImageIndex = index;
      }
    },
    moveMainImageIndex: (state, action) => {
      const diff = action.payload;

      if (!state.currentItem) {
        return;
      }

      let index = state.mainImageIndex + diff;

      if (index < 0) {
        index = state.currentItem.images.length - 1;
      } else if (index >= state.currentItem.images.length) {
        index = 0;
      }

      if (state.currentItem.images[index]) {
        state.mainImageIndex = index;
      }
    },
    setNextCurrentItem: (state) => {
      const newCurrentItem = state.items.find((i) => i.action === null);
      if (newCurrentItem) {
        state.currentItem = newCurrentItem;
        state.mainImageIndex = 0;
        state.currentItemNumber += 1;
      }
    },
    setPrevCurrentItem: (state) => {
      const currentIndex = state.items.findIndex((i) => i.id === state.currentItem.id);
      const newIndex = Math.max(0, currentIndex - 1);
      state.currentItem = state.items[newIndex];
      state.mainImageIndex = 0;
      state.currentItemNumber = Math.max(0, state.currentItemNumber - 1);
    },
    setItemByDirection: (state, action) => {
      const { direction } = action.payload;
      const currentIndex = state.items.findIndex((i) => i.id === state.currentItem.id);
      const newIndex = currentIndex + direction;
      if (state.items[newIndex]) {
        state.currentItem = state.items[newIndex];
        state.mainImageIndex = 0;
        state.currentItemNumber += direction;
      }
    },
    setBulkOffset: (state, action) => {
      const { offset } = action.payload;
      state.bulkOffset = offset;
    },
    setBulkBackwardOffset: (state, action) => {
      const { offset } = action.payload;
      state.bulkBackwardOffset = offset;
    },
    setCurrentItem: (state, action) => {
      const id = action.payload;
      if (state.currentItem.id === id) {
        return;
      }

      const newCurrentItem = state.items.find((item) => item.id === id);
      const lastUnmatchedItem = state.items.find((item) => !item.action);

      if (!newCurrentItem) {
        return;
      }

      if (
        lastUnmatchedItem
        && !newCurrentItem.action
        && newCurrentItem.id !== lastUnmatchedItem.id
      ) {
        return;
      }

      const indexDiff = state.items.findIndex(
        (item) => item.id === newCurrentItem.id,
      ) - state.items.findIndex(
        (item) => item.id === state.currentItem.id,
      );
      state.currentItem = newCurrentItem;
      state.mainImageIndex = 0;
      state.currentItemNumber += indexDiff;
    },
    setAnyCurrentItem: (state, action) => {
      const { id } = action.payload;

      if (state.currentItem.id === id) {
        return;
      }

      const newCurrentItem = state.items.find((item) => item.id === id);

      const indexDiff = state.items.findIndex(
        (item) => item.id === newCurrentItem.id,
      ) - state.items.findIndex(
        (item) => item.id === state.currentItem.id,
      );

      state.currentItem = newCurrentItem;
      state.mainImageIndex = 0;
      state.currentItemNumber += indexDiff;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchProductsFeed.fulfilled, (state, action) => {
      const {
        items,
        force,
        direction,
        next,
        count,
      } = action.payload;
      state.allItemsFetched = !items.length;
      state.totalProducts = count;

      if (force) {
        state.items = items;
      } else if (direction === 'top') {
        state.items = _uniqBy(
          [
            ...items,
            ...state.items,
          ],
          (item) => item.id,
        );
      } else {
        state.items = _uniqBy(
          [
            ...state.items,
            ...items,
          ],
          (item) => item.id,
        );
      }

      if (next === NEXT_CHUNK_FIRST_ITEM.FIRST) {
        const [first] = state.items;
        state.currentItem = first;
      } else if (next === NEXT_CHUNK_FIRST_ITEM.LAST) {
        state.currentItem = state.items[state.items.length - 1];
      } else {
        state.currentItem = state.items.find((item) => item.action === null)
          || state.items[state.items.length - 1];
      }
      state.mainImageIndex = 0;
      state.items.forEach((item) => {
        if (state.bulkMatchedIds.includes(item.id)) {
          item.action = PRODUCT_ACTIONS.MODEL;
        }
      });
    });

    builder.addCase(setMatchAction.fulfilled, (state, action) => {
      if (!action.payload || !action.payload.success) {
        return;
      }

      const item = state.items.find((i) => i.id === action.payload.id);
      if (item) {
        item.action = action.payload.action;
        state.currentItem.action = action.payload.action;
      }
    });

    builder.addCase(bulkSkipAction.fulfilled, (state, action) => {
      if (!action.payload || !action.payload.success) {
        return;
      }

      const items = state.items.filter((item) => action.payload.ids.includes(item.id));
      items.forEach((item) => {
        item.action = PRODUCT_ACTIONS.SKIP;
      });
    });

    builder.addCase(eraseLastItem.fulfilled, (state) => {
      const currentIndex = state.items.findIndex((i) => i.id === state.currentItem.id);
      if (currentIndex > 0) {
        const prevItem = state.items[currentIndex - 1];
        prevItem.action = null;
        state.currentItem = prevItem;
        state.mainImageIndex = 0;
      }
    });
  },
});

export {
  fetchProductsFeed,
  setMatchAction,
  eraseLastItem,
  bulkSkipAction,
  bulkNextPage,
  bulkPrevPage,
  checkAllMatched,
};

export const {
  setMainImageIndex,
  moveMainImageIndex,
  setCurrentItem,
  setNextCurrentItem,
  setPrevCurrentItem,
  setItemByDirection,
  resetItems,
  trimAfterId,
  trimBeforeId,
  setCurrentItemNumber,
  setAnyCurrentItem,
  addBulkMatchedIds,
  setBulkOffset,
  setBulkBackwardOffset,
  setFinalizeModal,
} = productsFeedSlice.actions;

export default productsFeedSlice.reducer;
