import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ItemsScroll from '../ItemsScroll';

import { fetchFeedbackSearchFeed, setCurrentItem } from '../../store/feedbackSearchFeed';

import { getScrollItemsFromSearchFeedItems } from '../../utils/options';

const FeedbackTemplateItemsScroll = () => {
  const dispatch = useDispatch();
  const items = useSelector((store) => store.feedbackSearchFeed.items);
  const currentItem = useSelector((store) => store.feedbackSearchFeed.currentItem);

  useEffect(() => {
    dispatch(fetchFeedbackSearchFeed());
  }, [dispatch]);

  const onItemClick = (itemId) => {
    dispatch(setCurrentItem(itemId));
  };

  return (
    <ItemsScroll
      items={getScrollItemsFromSearchFeedItems(items)}
      currentItemId={currentItem ? currentItem.id : null}
      onClick={onItemClick}
    />
  );
};

export default FeedbackTemplateItemsScroll;
