import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import {
  setMainImageIndex,
  setMatchAction,
  checkAllMatched,
  setFinalizeModal,
} from '../../store/productsFeed';

import { getBorderByAction } from '../../utils/border';
import { getNextImages } from '../../utils/image';

import ProductsCarousel from '../ProductsCarousel';

const ProductItemMainImage = ({ withMatchButtons }) => {
  const dispatch = useDispatch();
  const currentItem = useSelector((store) => store.productsFeed.currentItem);
  const mainImageIndex = useSelector((store) => store.productsFeed.mainImageIndex);
  const productItems = useSelector((store) => store.productsFeed.items);

  if (!currentItem) {
    return null;
  }

  const nextImages = getNextImages(productItems, currentItem);

  return (
    <ProductsCarousel
      matchButtons={withMatchButtons ? {
        itemSelector: (store) => store.productsFeed.currentItem,
        matchAction: setMatchAction,
        finalizeIfNeed: async () => {
          if (await dispatch(checkAllMatched()).unwrap()) {
            dispatch(setFinalizeModal(true));
          }
        },
      } : {}}
      images={currentItem.images}
      prefetchImages={nextImages}
      mediumImages={currentItem.medium_images}
      largeImages={currentItem.large_images}
      colors={currentItem.colors}
      mainImageIndex={mainImageIndex}
      onClick={(index) => dispatch(setMainImageIndex(index))}
      border={getBorderByAction(currentItem.action)}
      align="right"
    />
  );
};

ProductItemMainImage.propTypes = {
  withMatchButtons: PropTypes.bool,
};

ProductItemMainImage.defaultProps = {
  withMatchButtons: false,
};

export default ProductItemMainImage;
