import React from 'react';
import PropTypes from 'prop-types';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { BsEye } from 'react-icons/bs';

import ButtonWithIcon from '../ButtonWithIcon';

const ViewMatchesButton = ({ id }) => (
  <OverlayTrigger placement="left" overlay={<Tooltip>View Matches</Tooltip>}>
    <a href={`${process.env.REACT_APP_API_URL}/template-items/${id}/view-matches`} target="_blank">
      <ButtonWithIcon
        Icon={<BsEye />}
        size="sm"
        style={{ height: '30px' }}
      />
    </a>
  </OverlayTrigger>
);

ViewMatchesButton.propTypes = {
  id: PropTypes.number.isRequired,
};

export default ViewMatchesButton;
