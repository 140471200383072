import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import { ListGroup, Badge } from 'react-bootstrap';

import { formatDateToDisplay } from '../../utils/date';

import styles from './styles.module.css';

const TemplateSummary = ({
  name, date, progress, from, id,
}) => (
  <ListGroup.Item action>
    <Link to={`/admin/templates/${id}`} className={cx(styles.templateLink, 'd-flex justify-content-between align-items-center')}>
      <div>
        <p className={styles.templateName}>{name}</p>
        <p className={styles.templateDate}>{formatDateToDisplay(date)}</p>
      </div>
      {from && (
        <Badge bg={progress === from ? 'success' : 'danger'} pill>
          {`${((progress / from) * 100).toFixed(0)}% (${progress}/${from})`}
        </Badge>
      )}
    </Link>
  </ListGroup.Item>
);

TemplateSummary.propTypes = {
  name: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  progress: PropTypes.number,
  from: PropTypes.number.isRequired,
  id: PropTypes.number.isRequired,
};

TemplateSummary.defaultProps = {
  progress: 0,
};

export default TemplateSummary;
