import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Form } from 'react-bootstrap';

const ImageUploadModal = ({ show, onClose, onUpload }) => {
  const onSubmit = async (e) => {
    e.preventDefault();
    await onUpload(e.target.firstChild.files[0]);
    onClose();
  };

  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header>Upload image</Modal.Header>
      <Modal.Body>
        <Form id="image-upload" onSubmit={onSubmit}>
          <Form.Control type="file" required />
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="light" onClick={onClose}>
          Close
        </Button>
        <Button variant="primary" type="submit" form="image-upload">
          Upload
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

ImageUploadModal.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onUpload: PropTypes.func.isRequired,
};

ImageUploadModal.defaultProps = {
  show: false,
};

export default ImageUploadModal;
