/**
 * Find image by `id` and extract it's `src`
 * @param {(string|undefined)} id – image id
 * @param {Array<*>} images
 * @param {Array<*>} similarImages
 * @return {string} src
 */
export const findSrcOfHoveredImage = (id, images, similarImages) => {
  let src = '';
  if (id && id.includes('similar')) {
    const [,, row, column] = id.split('-');
    const imagesRow = similarImages.find((it) => `${it.id}` === row);

    src = imagesRow?.medium_images?.[column] ?? '';
  }

  if (id && !id.includes('similar')) {
    const [, row, column] = id.split('-');
    const imagesRow = images.find((it) => `${it.id}` === row);
    if (imagesRow) {
      const imagesColumn = imagesRow.images.find((it) => `${it.id}` === column);
      if (imagesColumn) {
        src = imagesColumn.medium_url;
      }
    }
  }

  return src;
};
