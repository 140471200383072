import React from 'react';
import PropTypes from 'prop-types';
import _debounce from 'lodash/debounce';
import { Form } from 'react-bootstrap';

import { formatAsHoursFromSeconds } from '../../../utils/date';
import { roundWithDecimal } from '../../../utils/format';

import DeleteButtonWithModal from '../../DeleteButtonWithModal';

import { Metric } from '../types';

const ExistingMatcherRow = ({
  matcher, onUpdate, onDelete, metrics,
}) => {
  const updateField = _debounce(async (e) => {
    await onUpdate(matcher.id, e.target.getAttribute('field'), e.target.value);
  }, 300);

  return (
    <tr>
      <td>{matcher.id}</td>
      <td>
        <Form.Control
          defaultValue={matcher.name}
          field="name"
          onChange={updateField}
        />
      </td>
      <td>
        <Form.Control
          type="password"
          placeholder="Fill to change"
          field="password"
          onChange={updateField}
        />
      </td>
      <td>
        <Form.Check
          type="checkbox"
          defaultChecked={matcher.enabled}
          field="enabled"
          onChange={(e) => onUpdate(matcher.id, 'enabled', e.target.checked)}
        />
      </td>
      <td>{metrics && `${roundWithDecimal(metrics.avg_click_speed)}s`}</td>
      <td>{metrics && `${roundWithDecimal(metrics.median_click_speed)}s`}</td>
      <td>{metrics && `${formatAsHoursFromSeconds(metrics.time)}`}</td>
      <td>{metrics && `${metrics.errors}%`}</td>
      <td>
        <DeleteButtonWithModal
          text="Delete"
          modalTitle="Delete Matcher"
          modalText="Are you sure you want to delete this matcher?"
          onDelete={() => onDelete(matcher.id)}
        />
      </td>
    </tr>
  );
};

ExistingMatcherRow.propTypes = {
  matcher: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    enabled: PropTypes.number.isRequired,
  }).isRequired,
  metrics: Metric,
  onUpdate: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

ExistingMatcherRow.defaultProps = {
  metrics: null,
};

export default ExistingMatcherRow;
