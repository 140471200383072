import React from 'react';
import { Modal, Button } from 'react-bootstrap';

const ResetImagesModal = ({ show, onCancel, onConfirm }) => (
  <Modal show={show} size="lg">
    <Modal.Header>
      Are you sure you want to reset these images?
    </Modal.Header>
    <Modal.Footer>
      <Button variant="light" onClick={onCancel}>
        No
      </Button>
      <Button variant="primary" onClick={onConfirm}>
        Yes
      </Button>
    </Modal.Footer>
  </Modal>
);

export default ResetImagesModal;
