import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  getFeedbackProductsFeed,
} from '../../utils/api';

export const fetchFeedbackProductsFeed = createAsyncThunk(
  'feedbackProductsFeed/fetchFeedbackProductsFeed',
  async (_, { getState }) => {
    const currentSearchItem = getState().feedbackSearchFeed.currentItem;

    if (currentSearchItem) {
      return getFeedbackProductsFeed(currentSearchItem.id);
    }

    return [];
  },
);
