import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Form, Button, Card, Alert,
} from 'react-bootstrap';

import styles from './styles.module.css';

const LoginForm = ({ onSubmit, hasError }) => {
  const [username, setUsername] = useState(null);
  const [password, setPassword] = useState(null);

  return (
    <Card className={styles.loginForm}>
      <Form onSubmit={(e) => {
        e.preventDefault();
        onSubmit(username, password);
      }}
      >
        <Form.Group className={styles.loginFormElement}>
          <Form.Label>Username</Form.Label>
          <Form.Control
            placeholder="Please enter your username"
            required
            onChange={(e) => setUsername(e.target.value)}
          />
        </Form.Group>

        <Form.Group className={styles.loginFormElement}>
          <Form.Label>Password</Form.Label>
          <Form.Control
            type="password"
            placeholder="Your password"
            required
            onChange={(e) => setPassword(e.target.value)}
          />
        </Form.Group>

        {hasError && <Alert variant="danger">Incorrect login or password</Alert>}

        <Button variant="primary" type="submit">
          Login
        </Button>
      </Form>
    </Card>
  );
};

LoginForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  hasError: PropTypes.bool,
};

LoginForm.defaultProps = {
  hasError: false,
};

export default LoginForm;
