import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'react-bootstrap';

const FinishTemplateItemModal = ({ show, onCancel, onConfirm }) => (
  <Modal show={show} size="lg">
    <Modal.Header>
      Matching is complete for this item,
      would you like to make any changes before moving onto the next item?
    </Modal.Header>
    <Modal.Footer>
      <Button variant="light" onClick={onConfirm}>
        No
      </Button>
      <Button variant="primary" onClick={onCancel}>
        Yes
      </Button>
    </Modal.Footer>
  </Modal>
);

FinishTemplateItemModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default FinishTemplateItemModal;
