import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import LazyLoad from 'vanilla-lazyload';

import styles from './styles.module.css';

if (!document.lazyLoadInstance) {
  document.lazyLoadInstance = new LazyLoad();
}

const LensImage = ({
  className,
  imageUrl,
  onClick,
  position,
  bigImageUrl,
  id,
  isHovered,
  disableLazy,
}) => {
  useEffect(() => {
    document.lazyLoadInstance.update();
  });

  return (
    <div className={styles.container}>
      <img
        data-src={imageUrl}
        alt="img"
        src={disableLazy && imageUrl}
        className={cx(className, styles.smallImage, !disableLazy && 'lazy')}
        onClick={onClick}
        id={id}
      />
      {isHovered && (
        <img
          src={bigImageUrl || imageUrl}
          alt="img"
          className={cx(styles.bigImage, position === 'left' && styles.bigImageLeft)}
        />
      )}
    </div>
  );
};

LensImage.propTypes = {
  imageUrl: PropTypes.string.isRequired,
  bigImageUrl: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
  position: PropTypes.oneOf(['left', 'right']).isRequired,
  id: PropTypes.string.isRequired,
  isHovered: PropTypes.bool,
};

LensImage.defaultProps = {
  className: null,
  onClick: null,
  bigImageUrl: null,
  isHovered: false,
};

export default LensImage;
