import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

export const formatDateToDisplay = (date) => new Date(date).toLocaleDateString('en-US');

export const formatDateAsValue = (date) => {
  if (!date) {
    return null;
  }

  return dayjs(date).format('YYYY-MM-DD');
};

export const getTimeDiff = (date) => {
  if (!date) {
    return '';
  }

  const d = dayjs.utc(date);
  const now = dayjs().utc();

  if (d.isBefore(now)) {
    return '0 Seconds';
  }

  const diffInSeconds = d.diff(now, 's');

  const hours = Math.floor(diffInSeconds / 60 / 60);
  const minutes = Math.floor((diffInSeconds - hours * 60 * 60) / 60);
  const seconds = diffInSeconds - hours * 60 * 60 - minutes * 60;

  if (hours) {
    return `${hours} Hours ${minutes} Minutes`;
  }

  if (minutes) {
    return `${minutes} Minutes`;
  }

  return `${seconds} Seconds`;
};

export const getNowAndOneMonthAgo = () => {
  const now = dayjs();
  const prev = now.subtract(1, 'week');

  return {
    now: formatDateAsValue(now),
    prev: formatDateAsValue(prev),
  };
};

export const formatAsHoursFromMinutes = (minutes) => {
  const hours = Math.trunc(minutes / 60);
  const minutesLeft = minutes - hours * 60;

  return `${hours}:${minutesLeft.toString().padStart(2, '0')}`;
};

export const formatAsHoursFromSeconds = (seconds) => {
  const minutes = Math.trunc(seconds / 60);
  const secondsLeft = seconds - minutes * 60;

  return `${formatAsHoursFromMinutes(minutes)}:${secondsLeft.toString().padStart(2, '0')}`;
};

export const formatForChart = (date) => dayjs(date).format('MMM D');
