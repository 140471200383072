import PropTypes from 'prop-types';

export const DaysBreakdown = PropTypes.arrayOf(PropTypes.shape({
  day: PropTypes.string.isRequired,
  minutes: PropTypes.number.isRequired,
}));

export const Intervals = PropTypes.arrayOf(PropTypes.shape({
  day: PropTypes.string.isRequired,
  time_from: PropTypes.string.isRequired,
  time_to: PropTypes.string.isRequired,
  minutes: PropTypes.number.isRequired,
}));

export const Metric = PropTypes.shape({
  user_id: PropTypes.number.isRequired,
  avg_click_speed: PropTypes.number.isRequired,
  median_click_speed: PropTypes.number.isRequired,
  time: PropTypes.number.isRequired,
  errors: PropTypes.number.isRequired,
  days_breakdown: DaysBreakdown.isRequired,
  intervals: Intervals.isRequired,
});
