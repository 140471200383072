import React, { useState, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';

import ProtectedRoute from './ProtectedRoute';

import useAuth from './hooks/useAuth';

import LoginPage from './pages/LoginPage';
import AdminHomePage from './pages/AdminHomePage';
import AdminCreateTemplatePage from './pages/AdminCreateTemplatePage';
import AdminEditTemplatePage from './pages/AdminEditTemplatePage';
import AdminMatchersPage from './pages/AdminMatchersPage';
import AdminInspectionPage from './pages/AdminInspectionPage';
import MatcherHomePage from './pages/MatcherHomePage';
import MatcherFeedbackPage from './pages/MatcherFeedbackPage';
import MatcherBulkMatchPage from './pages/MatcherBulkMatchPage';

const Routes = () => {
  const [checked, setChecked] = useState(false);
  const { check } = useAuth();

  useEffect(() => {
    const checkAuth = async () => {
      await check();
      setChecked(true);
    };

    checkAuth();
    // eslint-disable-next-line
  }, []);

  if (!checked) {
    return null;
  }

  return (
    <Router>
      <Switch>
        <Route exact path="/login">
          <LoginPage />
        </Route>
        <ProtectedRoute adminOnly exact path="/admin/home">
          <AdminHomePage />
        </ProtectedRoute>
        <ProtectedRoute adminOnly exact path="/admin/templates">
          <AdminCreateTemplatePage />
        </ProtectedRoute>
        <ProtectedRoute adminOnly exact path="/admin/templates/:id">
          <AdminEditTemplatePage />
        </ProtectedRoute>
        <ProtectedRoute adminOnly exact path="/admin/matchers">
          <AdminMatchersPage />
        </ProtectedRoute>
        <ProtectedRoute adminOnly exact path="/admin/inspection">
          <AdminInspectionPage />
        </ProtectedRoute>
        <ProtectedRoute exact path="/matcher/home">
          <MatcherHomePage />
        </ProtectedRoute>
        <ProtectedRoute exact path="/matcher/match-bulk">
          <MatcherBulkMatchPage />
        </ProtectedRoute>
        <ProtectedRoute exact path="/matcher/feedback">
          <MatcherFeedbackPage />
        </ProtectedRoute>
        <Route exact path="/">
          <Redirect to="/admin/home" />
        </Route>
      </Switch>
    </Router>
  );
};

export default Routes;
