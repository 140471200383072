import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import styles from './styles.module.css';

const TemplateNote = ({ selector }) => {
  const currentItem = useSelector(selector);

  if (!currentItem || !currentItem.note) {
    return null;
  }

  return (
    <h2 className={styles.note}>{currentItem.note}</h2>
  );
};

TemplateNote.propTypes = {
  selector: PropTypes.func.isRequired,
};

export default TemplateNote;
