import _last from 'lodash/last';
import { BULK_CHUNK_COUNT } from '../../utils/chunk';

export const isLastChunk = (store) => {
  const {
    currentItemNumber,
    totalProducts: lastItemNumber,
  } = store.productsFeed;

  const totalChunks = Math.ceil(lastItemNumber / BULK_CHUNK_COUNT);
  const currentChunk = Math.ceil(currentItemNumber / BULK_CHUNK_COUNT);

  return currentChunk >= totalChunks;
};

export const isLastItemMatched = (store) => {
  const {
    currentItemNumber,
    totalProducts: lastItemNumber,
  } = store.productsFeed;

  return currentItemNumber === lastItemNumber && _last(store.productsFeed.items).action;
};

export const isAllMatched = (store) => {
  const isLastPage = isLastChunk(store);

  if (!isLastPage) {
    return false;
  }

  return isLastItemMatched(store);
};
