import React from 'react';

import AdminLayout from '../../components/AdminLayout';
import MatchLayout from '../../components/MatchLayout';
import BulkProductsMismatchedSection from '../../components/BulkProductsMismatchedSection';
import TemplatesMismatchedSection from '../../components/TemplatesMismatchedSection';
import InspectionPageKeyboardProvider from '../../components/InspectionPageKeyboardProvider';
import InspectionNote from '../../components/InspectionNote';

import { setNoMoreItems } from '../../store/mismatchedSearchFeed';

import styles from './styles.module.css';

const AdminInspectionPage = () => (
  <AdminLayout>
    <InspectionNote className={styles.note} />
    <InspectionPageKeyboardProvider>
      <MatchLayout
        withHeader
        leftSideComponent={<BulkProductsMismatchedSection />}
        rightSideComponent={<TemplatesMismatchedSection />}
        finalModalOptions={{
          title: 'Great job! No more mismatches!',
          button: 'Back to Dashboard',
          selector: (store) => store.mismatchedSearchFeed.noMoreItems,
          action: () => setNoMoreItems(false),
        }}
      />
    </InspectionPageKeyboardProvider>
  </AdminLayout>
);

export default AdminInspectionPage;
