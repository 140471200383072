import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ItemsScroll from '../ItemsScroll';

import { fetchMismatchedSearchFeed } from '../../store/mismatchedSearchFeed';

import { getScrollItemsFromSearchFeedItems } from '../../utils/options';

const MismatchedTemplateItemsScroll = () => {
  const dispatch = useDispatch();
  const items = useSelector((store) => store.mismatchedSearchFeed.items);
  const currentItem = useSelector((store) => store.mismatchedSearchFeed.currentItem);

  useEffect(() => {
    dispatch(fetchMismatchedSearchFeed());
  }, [dispatch]);

  return (
    <ItemsScroll
      items={getScrollItemsFromSearchFeedItems(items)}
      currentItemId={currentItem ? currentItem.id : null}
    />
  );
};

export default MismatchedTemplateItemsScroll;
