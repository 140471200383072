import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { BsFillLockFill, BsFillUnlockFill } from 'react-icons/bs';

import styles from './styles.module.css';

const InputWithLock = ({ children, enabled }) => {
  const [locked, setLocked] = useState(true);

  if (!enabled) {
    return children;
  }

  const onClick = () => {
    if (locked) {
      const target = document.getElementById(children.props.id);
      if (target) {
        setTimeout(() => {
          target.click();
          target.focus();
        }, 0);
      }
    }
    setLocked(!locked);
  };

  const onBlur = () => {
    setLocked(true);
  };

  const updatedChild = React.cloneElement(
    children,
    {
      onBlur,
      disabled: locked,
      style: {
        backgroundColor: locked ? '#eee' : '',
        paddingLeft: '20px',
      },
    },
  );

  return (
    <div className={styles.container}>
      {updatedChild}
      <div
        className={styles.button}
        onClick={onClick}
      >
        <div className={cx(styles.bg, locked && styles.bgLocked)}>
          {locked ? <BsFillLockFill style={{ color: '#999999' }} /> : <BsFillUnlockFill />}
        </div>
      </div>
    </div>
  );
};

InputWithLock.propTypes = {
  children: PropTypes.node.isRequired,
  enabled: PropTypes.bool,
};

InputWithLock.defaultProps = {
  enabled: false,
};

export default InputWithLock;
