import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { withDebounce, DEBOUCE_SCROLL_MS } from '../../utils/debounce';

import styles from './styles.module.css';

const InfiniteBidirectionalScroll = ({
  hasMoreTop,
  hasMoreBottom,
  nextTop,
  nextBottom,
  className,
  children,
}) => {
  const scrollerDiv = useRef();
  const lock = useRef();
  const topLoaded = useRef();
  const [height, setHeigth] = useState(0);

  useEffect(() => {
    if (topLoaded.current && scrollerDiv.current.scrollHeight !== height) {
      scrollerDiv.current.scrollTop = scrollerDiv.current.scrollHeight - height;
      topLoaded.current = false;
    }
  }, [children, height]);

  const reachedTop = () => {
    const { firstChild, scrollTop, offsetTop } = scrollerDiv.current;

    if (!firstChild) {
      return false;
    }

    return scrollTop + offsetTop <= firstChild.offsetTop;
  };

  const reachedBottom = () => {
    const {
      lastChild,
      scrollTop,
      offsetTop,
      offsetHeight,
    } = scrollerDiv.current;

    if (!lastChild) {
      return false;
    }

    const currentPosition = scrollTop + offsetTop + offsetHeight;
    return currentPosition >= lastChild.offsetTop + lastChild.offsetHeight - 500;
  };

  const handleScroll = withDebounce(async () => {
    if (reachedTop() && hasMoreTop && !lock.current) {
      lock.current = true;
      if (nextTop) {
        topLoaded.current = true;
        setHeigth(scrollerDiv.current.scrollHeight);

        await nextTop();
      }
      lock.current = false;
    }

    if (reachedBottom() && hasMoreBottom && !lock.current) {
      lock.current = true;
      if (nextBottom) {
        await nextBottom();
      }
      lock.current = false;
    }

    return false;
  }, DEBOUCE_SCROLL_MS);

  return (
    <div
      ref={scrollerDiv}
      onScroll={handleScroll}
      className={cx(styles.scroller, className)}
    >
      {children}
    </div>
  );
};

InfiniteBidirectionalScroll.propTypes = {
  hasMoreTop: PropTypes.bool,
  hasMoreBottom: PropTypes.bool,
  nextTop: PropTypes.func,
  nextBottom: PropTypes.func,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

InfiniteBidirectionalScroll.defaultProps = {
  hasMoreTop: false,
  hasMoreBottom: false,
  nextTop: null,
  nextBottom: null,
  className: null,
};

export default InfiniteBidirectionalScroll;
