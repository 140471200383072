import React from 'react';

import MatchLayout from '../../components/MatchLayout';
import ProductsFeedbackSection from '../../components/ProductsFeedbackSection';
import TemplatesFeedbackSection from '../../components/TemplatesFeedbackSection';

const MatcherFeedbackPage = () => (
  <MatchLayout
    leftSideComponent={<ProductsFeedbackSection />}
    rightSideComponent={<TemplatesFeedbackSection />}
  />
);

export default MatcherFeedbackPage;
