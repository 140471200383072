import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { useSelector, useDispatch } from 'react-redux';

import { setImageSidebarFocus } from '../../store/templateItems';

import ImageInfiniteSelector from '../ImageInfiniteSelector';
import FocusHandler from './FocusHandler';

import MinimizeButton from './MinimizeButton';

import styles from './styles.module.css';

const ImageSelectorSidebar = ({ onChoose, onSimilarChoose }) => {
  const dispatch = useDispatch();
  const currentTemplateItem = useSelector((store) => {
    const { items, currentItemId: itemId } = store.templateItems;
    if (itemId === null) {
      return null;
    }

    return items.find((i) => i.id === itemId);
  });
  const imageSidebarFocused = useSelector((store) => store.templateItems.imageSidebarFocused);
  const hoveredImageSrc = useSelector((store) => store.templateItems.hoveredImageSrc);
  const minimized = useSelector((store) => store.columns.options.minimizeStaticColumn);

  return (
    <>
      {hoveredImageSrc && (
        <img
          className={styles.preview}
          src={hoveredImageSrc}
          alt="preview-img"
        />
      )}
      <div
        className={cx(styles.container, minimized && styles.minimized)}
        id="image-infinite-selector"
      >
        <MinimizeButton minimized={minimized} />
        <FocusHandler
          focused={imageSidebarFocused}
          onFocusChanged={(value) => dispatch(setImageSidebarFocus(value))}
        >
          <ImageInfiniteSelector
            disableKeyboard={!imageSidebarFocused}
            item={currentTemplateItem}
            onImageClick={(imageId) => onChoose(currentTemplateItem?.id, imageId)}
            onSimilarImageClick={(rowId) => onSimilarChoose(currentTemplateItem?.id, rowId)}
            scrollableTarget="image-infinite-selector"
            minimized={minimized}
          />
        </FocusHandler>
      </div>
    </>
  );
};

ImageSelectorSidebar.propTypes = {
  onChoose: PropTypes.func.isRequired,
  onSimilarChoose: PropTypes.func.isRequired,
};

export default ImageSelectorSidebar;
