import React from 'react';
import PropTypes from 'prop-types';

import styles from './styles.module.css';

const ThreeColumnLayout = ({ leftComponent, rightComponent, children }) => (
  <div className={styles.container}>
    <div className={styles.left}>{leftComponent}</div>
    <div className={styles.center}>{children}</div>
    <div className={styles.right}>{rightComponent}</div>
  </div>
);

ThreeColumnLayout.propTypes = {
  leftComponent: PropTypes.node,
  rightComponent: PropTypes.node,
  children: PropTypes.node,
};

ThreeColumnLayout.defaultProps = {
  leftComponent: null,
  rightComponent: null,
  children: null,
};

export default ThreeColumnLayout;
