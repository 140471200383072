import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

const MatchingItemsCounter = ({ counter, className }) => (
  <p className={cx('text-secondary', className)}>{`Count: ${counter}`}</p>
);

MatchingItemsCounter.propTypes = {
  counter: PropTypes.number.isRequired,
  className: PropTypes.string,
};

MatchingItemsCounter.defaultProps = {
  className: '',
};

export default MatchingItemsCounter;
