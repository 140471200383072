import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'react-bootstrap';

import styles from './styles.module.css';

const MergeModal = ({
  show, onConfirm, onCancel, data,
}) => (
  <Modal show={show} size="lg">
    <Modal.Header>
      <span>
        Please confirm these two items are an
        {' '}
        <b>EXACT</b>
        {' '}
        match
      </span>
    </Modal.Header>
    <Modal.Body>
      {data && (
        <>
          <div className={styles.imagesRow}>
            {data.images1.map((src) => (
              <img key={src} src={src} alt="img" className={styles.image} />
            ))}
          </div>
          <div className={styles.imagesRow}>
            {data.images2.map((src) => (
              <img key={src} src={src} alt="img" className={styles.image} />
            ))}
          </div>
        </>
      )}
    </Modal.Body>
    <Modal.Footer>
      <Button variant="light" onClick={onCancel}>
        Cancel
      </Button>
      <Button variant="primary" onClick={onConfirm}>
        Confirm
      </Button>
    </Modal.Footer>
  </Modal>
);

MergeModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  data: PropTypes.objectOf({
    images1: PropTypes.arrayOf(PropTypes.string).isRequired,
    images2: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
};

export default MergeModal;
