import { createSlice } from '@reduxjs/toolkit';

import {
  fetchFeedbackSearchFeed,
} from './middleware';

const initialState = {
  items: [],
  currentItem: null,
  mainImageIndex: 0,
};

export const feedbackSearchFeed = createSlice({
  name: 'feedbackSearchFeed',
  initialState,
  reducers: {
    setCurrentItem: (state, action) => {
      const nextItem = state.items.find((i) => i.id === action.payload);

      if (nextItem) {
        state.currentItem = nextItem;
        state.mainImageIndex = 0;
      }
    },
    setMainImageIndex: (state, action) => {
      const index = action.payload;

      if (state.currentItem && state.currentItem.images[index]) {
        state.mainImageIndex = index;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchFeedbackSearchFeed.fulfilled, (state, action) => {
      state.items = action.payload;
      const [firstItem] = action.payload;
      if (firstItem) {
        state.currentItem = firstItem;
      }
    });
  },
});

export {
  fetchFeedbackSearchFeed,
};

export const { setCurrentItem, setMainImageIndex } = feedbackSearchFeed.actions;

export default feedbackSearchFeed.reducer;
