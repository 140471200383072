import React, { useState } from 'react';
import { BsTrash } from 'react-icons/bs';
import { Dropdown, Modal, Button } from 'react-bootstrap';

import ButtonWithIcon from '../ButtonWithIcon';

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <div
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
  </div>
));

const DeleteButtonWithDropdown = ({ onDelete, onDeleteEverywhere }) => {
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteEverywhereModal, setDeleteEverywhereModal] = useState(false);

  return (
    <>
      <Dropdown>
        <Dropdown.Toggle as={CustomToggle}>
          <ButtonWithIcon
            Icon={<BsTrash />}
            variant="danger"
          />
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item onClick={() => setDeleteModal(true)}>Delete query</Dropdown.Item>
          <Dropdown.Item
            onClick={() => setDeleteEverywhereModal(true)}
          >
            Delete query everywhere
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>

      <Modal show={deleteModal} onHide={() => setDeleteModal(false)}>
        <Modal.Header>
          <Modal.Title>Delete search query</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this search query?</Modal.Body>
        <Modal.Footer>
          <Button variant="light" onClick={() => setDeleteModal(false)}>Close</Button>
          <Button
            variant="danger"
            onClick={() => {
              setDeleteModal(false);
              onDelete();
            }}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={deleteEverywhereModal} onHide={() => setDeleteEverywhereModal(false)}>
        <Modal.Header>
          <Modal.Title>Delete search query everywhere</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete ALL linked search queries including matched ones?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" onClick={() => setDeleteEverywhereModal(false)}>Close</Button>
          <Button
            variant="danger"
            onClick={() => {
              setDeleteEverywhereModal(false);
              onDeleteEverywhere();
            }}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DeleteButtonWithDropdown;
