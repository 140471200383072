import React from 'react';
import { Provider } from 'react-redux';
import 'bootstrap/dist/css/bootstrap.min.css';

import Routes from './Routes';
import { useProvideAuth, authContext } from './hooks/useAuth';

import store from './store';

const App = () => {
  const auth = useProvideAuth();

  return (
    <div>
      <authContext.Provider value={auth}>
        <Provider store={store}>
          <Routes />
        </Provider>
      </authContext.Provider>
    </div>
  );
};

export default App;
