export const getCurrentItemNumnder = (store) => {
  const index = store.mismatchedProductsFeed.items.findIndex(
    (it) => it.id === store.mismatchedProductsFeed.currentItem?.id,
  );

  if (index === -1) {
    return 1;
  }

  return index + 1;
};
