import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { setMainImageIndex } from '../../store/mismatchedSearchFeed';

import ProductsCarousel from '../ProductsCarousel';

const MismatchedTemplateMainImage = () => {
  const dispatch = useDispatch();
  const currentItem = useSelector((store) => store.mismatchedSearchFeed.currentItem);
  const mainImageIndex = useSelector((store) => store.mismatchedSearchFeed.mainImageIndex);

  if (!currentItem) {
    return null;
  }

  return (
    <ProductsCarousel
      images={currentItem.images}
      mediumImages={currentItem.medium_images}
      largeImages={currentItem.large_images}
      mainImageIndex={mainImageIndex}
      onClick={(idx) => dispatch(setMainImageIndex(idx))}
      align="left"
    />
  );
};

export default MismatchedTemplateMainImage;
