import { COLORS } from './colors';
import { getBorderByAction } from './border';

export const getOptionsFromStrings = (strings, options = []) => {
  if (!options.length) {
    return strings.map((s) => ({ label: s, value: s }));
  }

  return strings.map((s) => {
    const stringWithValue = options.find((item) => item.value === s);
    return ({
      value: stringWithValue.value,
      label: stringWithValue.text,
    });
  });
};

export const getOptionsFromCategoriesList = (categories) => categories.map((c) => ({
  ...c,
  label: c.text,
}));

export const getScrollItemsFromSearchFeedItems = (items) => items.map((item) => ({
  id: item.id,
  image: item.images[0],
  border: item.finished ? {
    colors: [COLORS.FINISHED_ITEM],
  } : null,
}));

export const getScrollItemsFromProducts = (items, actionField = 'action') => items.map((item) => ({
  id: item.id,
  image: item.images[0],
  border: getBorderByAction(item[actionField]),
}));
