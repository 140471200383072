import React from 'react';
import cx from 'classnames';
import { BsArrowLeft, BsArrowRight } from 'react-icons/bs';

import styles from './styles.module.css';

const SideArrowButton = ({ reversed, onClick, disabled }) => (
  <div className={cx(styles.button, disabled && styles.disabled)} onClick={onClick}>
    {reversed
      ? <BsArrowLeft className={styles.arrow} />
      : <BsArrowRight className={styles.arrow} />}
  </div>
);

export default SideArrowButton;
