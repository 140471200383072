import React from 'react';

import SectionBodyLayout from '../SectionBodyLayout';
import SectionHeader from '../SectionHeader';
import MismatchedProductMainImage from '../MismatchedProductMainImage';
import BulkMismatchedProductsScroll from '../BulkMismatchedProductsScroll';
import MismatchedItemsAmountRight from '../MismatchedItemsAmountRight';

const BulkProductsMismatchedSection = () => (
  <>
    <SectionHeader leftSideComponent={<MismatchedItemsAmountRight />} />
    <SectionBodyLayout listPosition="left" listComponent={<BulkMismatchedProductsScroll />}>
      <MismatchedProductMainImage withMatchButtons />
    </SectionBodyLayout>
  </>
);

export default BulkProductsMismatchedSection;
