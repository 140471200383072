import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  getTemplateById,
  updateTemplateById as updateTemplateByIdApiCall,
} from '../../utils/api';

export const fetchTemplateById = createAsyncThunk(
  'editableTemplate/fetchTemplate',
  async (id) => getTemplateById(id),
);

export const updateTemplateById = createAsyncThunk(
  'editableTemplate/updateTemplateById',
  async ({ field, value }, { getState }) => {
    const { editableTemplate } = getState();
    const newTemplate = { ...editableTemplate, [field]: value };
    return updateTemplateByIdApiCall(editableTemplate.id, newTemplate);
  },
);
