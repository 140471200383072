import { createAction } from '@reduxjs/toolkit';
import {
  getActiveColumnsData,
  saveActiveColumnsData,
  getColumnsOptionsData,
  saveColumnsOptionsData,
} from '../../utils/localStorage';

export const loadColumnsFromLocalStorage = () => (dispatch, getState) => {
  const lsActiveColumnsData = getActiveColumnsData();
  const lsColumnsOptionsData = getColumnsOptionsData();
  const state = getState();

  if (lsActiveColumnsData) {
    const allHidden = Object.keys(lsActiveColumnsData).every((key) => !lsActiveColumnsData[key]);
    if (allHidden) {
      saveActiveColumnsData(state.columns.items);
    } else {
      dispatch(createAction('columns/setColumns')(lsActiveColumnsData));
    }
  } else {
    saveActiveColumnsData(state.columns.items);
  }

  if (lsColumnsOptionsData) {
    dispatch(createAction('columns/setOptions')(lsColumnsOptionsData));
  } else {
    saveColumnsOptionsData(state.columns.options);
  }
};

export const toggleColumnWithLocalStorage = (columnName) => (dispatch, getState) => {
  dispatch(createAction('columns/toggleColumn')(columnName));
  saveActiveColumnsData(getState().columns.items);
};

export const toggleMinimizeStaticColumnWithLocalStorage = () => (dispatch, getState) => {
  dispatch(createAction('columns/toggleMinimizeStaticColumn')());
  saveColumnsOptionsData(getState().columns.options);
};
