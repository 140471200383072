import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import styles from './styles.module.css';

const MatchingItemWords = ({
  words,
  onClick,
  input,
  className,
  short,
  onHide,
  id,
}) => {
  const clickHandler = (e) => {
    if (e.target.id === id || e.target.id.startsWith('word-')) {
      return;
    }

    onHide();
  };

  useEffect(() => {
    window.addEventListener('click', clickHandler);
    return () => window.removeEventListener('click', clickHandler);
  }, []);

  return (
    <ul
      className={cx(
        styles.container,
        short && styles.short,
        className,
        words.length === 0 && styles.hidden,
      )}
    >
      {words.map((word, idx) => (
        <li
          id={`word-${idx}`}
          key={word.word}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            onClick(word.word);
          }}
          className={cx(styles.word, input && input.includes(`-${word.word}`) && styles.used)}
        >
          {`${word.word}: ${word.count}`}
        </li>
      ))}
    </ul>
  );
};

MatchingItemWords.propTypes = {
  words: PropTypes.arrayOf(PropTypes.shape({
    word: PropTypes.string.isRequired,
    count: PropTypes.number.isRequired,
  })).isRequired,
  onClick: PropTypes.func.isRequired,
  onHide: PropTypes.func.isRequired,
  id: PropTypes.number.isRequired,
  className: PropTypes.string,
  input: PropTypes.string,
  short: PropTypes.bool,
};

MatchingItemWords.defaultProps = {
  className: '',
  input: '',
  short: false,
};

export default MatchingItemWords;
