import _debounce from 'lodash/debounce';
import _throttle from 'lodash/throttle';

export const DEBOUNCE_MS = 500;
export const DEBOUCE_AOUTOCOMPLETE_MS = 300;
export const THROTTLE_MS = 500;
export const THROTTLE_SCROLL_MS = 300;
export const DEBOUCE_SCROLL_MS = 300;
export const THROTTLE_MATCH_MS = 250;

export const withDebounce = (f, delay = DEBOUNCE_MS) => _debounce(f, delay);

export const withThrottle = (f, delay = THROTTLE_MS) => _throttle(f, delay);
