import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ItemsScroll from '../ItemsScroll';

import {
  fetchMismatchedProductsFeed,
  setCurrentItem,
  bulkSkipMismatchedAction,
  mismatchedPrevChunk,
  mismatchedDoubleNextChunk,
} from '../../store/mismatchedProductsFeed';
import { getScrollItemsFromProducts } from '../../utils/options';
import { getCurrentChunk, isFirstChunk, getNextChunk } from '../../utils/chunk';
import BulkArrows from '../BulkArrows';

import styles from './styles.module.css';

const BulkMismatchedProductsScroll = () => {
  const dispatch = useDispatch();
  const currentSearchItem = useSelector((store) => store.mismatchedSearchFeed.currentItem);
  const items = useSelector((store) => store.mismatchedProductsFeed.items);
  const currentItem = useSelector((store) => store.mismatchedProductsFeed.currentItem);

  const disableUp = useSelector((store) => isFirstChunk(
    store.mismatchedProductsFeed.items,
    store.mismatchedProductsFeed.currentItem,
  ));
  const canDoubleSkip = useSelector((store) => {
    const chunk = getNextChunk(
      store.mismatchedProductsFeed.items,
      store.mismatchedProductsFeed.currentItem,
    );
    return chunk.some((it) => it.action);
  });

  useEffect(() => {
    if (currentSearchItem) {
      dispatch(fetchMismatchedProductsFeed(currentSearchItem.id));
    }
  }, [currentSearchItem, dispatch]);

  const onItemClick = (itemId) => {
    dispatch(setCurrentItem(itemId));
  };

  const onPrevClick = () => {
    dispatch(mismatchedPrevChunk());
  };
  const onNextClick = () => {
    dispatch(bulkSkipMismatchedAction());
  };
  const onDoubleSkipClick = () => {
    dispatch(mismatchedDoubleNextChunk());
  };

  return (
    <div className={styles.container}>
      <ItemsScroll
        items={getCurrentChunk(getScrollItemsFromProducts(items), currentItem)}
        currentItemId={currentItem ? currentItem.id : null}
        onClick={onItemClick}
        rtl
      />
      <BulkArrows
        onUp={onPrevClick}
        onDown={onNextClick}
        onDoubleSkip={onDoubleSkipClick}
        disableUp={disableUp}
        canDoubleSkip={canDoubleSkip}
      />
    </div>
  );
};

export default BulkMismatchedProductsScroll;
