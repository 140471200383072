import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Dropdown, Form } from 'react-bootstrap';

import { COLUMN_NAMES, toggleColumnWithLocalStorage } from '../../store/columns';

const EditTemplateColumnsPicker = ({ className }) => {
  const dispatch = useDispatch();
  const columns = useSelector((store) => store.columns.items);

  const onChange = (columnName) => () => {
    dispatch(toggleColumnWithLocalStorage(columnName));
  };

  return (
    <Dropdown className={className} autoClose="outside">
      <Dropdown.Toggle variant="info" size="sm">Columns</Dropdown.Toggle>

      <Dropdown.Menu>
        {Object.keys(columns).map((columnName) => (
          <Dropdown.Item key={columnName} onClick={onChange(columnName)}>
            <Form.Check
              className="pe-none"
              id={columnName}
              type="checkbox"
              label={COLUMN_NAMES[columnName]}
              checked={columns[columnName]}
            />
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

EditTemplateColumnsPicker.propTypes = {
  className: PropTypes.string,
};

EditTemplateColumnsPicker.defaultProps = {
  className: '',
};

export default EditTemplateColumnsPicker;
