import { createSlice } from '@reduxjs/toolkit';

import {
  fetchMismatchedSearchFeed,
} from './middleware';

const initialState = {
  items: [],
  currentItem: null,
  noMoreItems: false,
  mainImageIndex: 0,
};

export const mismatchedSearchFeed = createSlice({
  name: 'mismatchedSearchFeed',
  initialState,
  reducers: {
    markCurrentItemAsFinished: (state) => {
      const itemIndex = state.items.findIndex((i) => i.id === state.currentItem.id);
      const nextItem = state.items[itemIndex + 1];
      if (nextItem) {
        state.currentItem = nextItem;
        state.mainImageIndex = 0;
      } else {
        state.noMoreItems = true;
      }
    },
    setNoMoreItems: (state, action) => {
      state.noMoreItems = action.payload;
    },
    setMainImageIndex: (state, action) => {
      const index = action.payload;

      if (state.currentItem && state.currentItem.images[index]) {
        state.mainImageIndex = index;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchMismatchedSearchFeed.fulfilled, (state, action) => {
      state.items = action.payload;
      state.currentItem = action.payload.find((item) => !item.finished);
      if (!state.currentItem) {
        state.noMoreItems = true;
      }
    });
  },
});

export {
  fetchMismatchedSearchFeed,
};

export const {
  markCurrentItemAsFinished,
  setNoMoreItems,
  setMainImageIndex,
} = mismatchedSearchFeed.actions;

export default mismatchedSearchFeed.reducer;
