/**
 * @typedef {{ word: string, count: number }} Word
 */

/**
 * Merge old words and new words with respect to the order
 * @param {Word[]} oldWords - list of existing words
 * @param {Word[]} newWords - list of new words
 * @returns {Word[]}
 */
export const mergeWordsWithOrder = (oldWords, newWords) => {
  const memo = new Set();
  const result = oldWords.map((word) => {
    const update = newWords.find((w) => w.word === word.word);
    if (update) {
      memo.add(update.word);
      return { ...word, count: update.count };
    }

    return { ...word, count: 0 };
  });

  return [...result, ...newWords.filter((w) => !memo.has(w.word))];
};

/**
 * Adds a word to the end of the `input` or removes it
 * @param {string} input - original string
 * @param {string} word - word to toggle
 * @returns {string} - `input` with "-`word`" as the last part or without it
 */
export const toggleWord = (input, word) => {
  const targetWord = ` -${word}`;
  if (input && input.includes(targetWord)) {
    return input.replace(targetWord, '');
  }

  return `${input || ''}${targetWord}`;
};
