import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import { getCurrentChunk, getNextChunk } from '../../utils/chunk';

import {
  getMismatchedProductsFeed,
  setFinalMatch,
  setBulkSkipMismatchedAction,
  finalizeMismatchTemplateItem,
} from '../../utils/api';

export const fetchMismatchedProductsFeed = createAsyncThunk(
  'mismatchedProductsFeed/fetchMismatchedProductsFeed',
  async (_, { getState }) => {
    const currentSearchItem = getState().mismatchedSearchFeed.currentItem;

    if (currentSearchItem) {
      return getMismatchedProductsFeed(currentSearchItem.id);
    }

    return [];
  },
);

export const setMismatchedMatchAction = createAsyncThunk(
  'mismatchedProductsFeed/setMismatchedMatchAction',
  async (payload, { getState }) => {
    const { action } = payload;
    const { mismatchedSearchFeed, mismatchedProductsFeed } = getState();
    const currentSearchItem = mismatchedSearchFeed.currentItem;
    const currentProductItem = mismatchedProductsFeed.currentItem;
    const { note } = mismatchedProductsFeed;

    if (currentSearchItem && currentProductItem) {
      const response = await setFinalMatch(
        currentSearchItem.id,
        currentProductItem.id,
        action,
        note,
      );

      if (response && response.success) {
        return { id: currentProductItem.id, action };
      }
    }

    return null;
  },
);

export const finilizeCurrentItem = createAsyncThunk(
  'mismatchedProductsFeed/finilizeCurrentItem',
  async (_, { getState, dispatch }) => {
    const { mismatchedSearchFeed } = getState();
    if (mismatchedSearchFeed.currentItem) {
      await finalizeMismatchTemplateItem(mismatchedSearchFeed.currentItem.id);
    }
    dispatch(createAction('mismatchedSearchFeed/markCurrentItemAsFinished')());
    dispatch(createAction('toasts/toggleNextItemToast')());
  },
);

export const bulkSkipMismatchedAction = createAsyncThunk(
  'mismatchedProductsFeed/bulkSkipMismatchedAction',
  async (_, { getState, dispatch }) => {
    const currentSearchItem = getState().mismatchedSearchFeed.currentItem;
    const productItems = getState().mismatchedProductsFeed.items;
    const currentProductItem = getState().mismatchedProductsFeed.currentItem;

    if (currentSearchItem && productItems?.length) {
      const ids = getCurrentChunk(productItems, currentProductItem)
        .filter((it) => !it.action)
        .map((it) => it.id);

      let response = null;
      if (ids.length) {
        response = await setBulkSkipMismatchedAction(
          currentSearchItem.id,
          ids,
        );
      }

      if ((response && response.success) || !ids.length) {
        const next = getNextChunk(productItems, currentProductItem);

        if (next?.length) {
          return {
            ...response,
            ids,
            nextId: next[0].id,
          };
        }

        dispatch(createAction('mismatchedProductsFeed/setFinishModal')(true));
      }
    }

    return {};
  },
);

export const setNextCurrentItem = createAsyncThunk(
  'mismatchedProductsFeed/setNextCurrentItem',
  async (_, { getState, dispatch }) => {
    const { items, currentItem } = getState().mismatchedProductsFeed;
    const chunk = getCurrentChunk(items, currentItem);

    // В последнем айтеме текущего чанка
    if (chunk[chunk.length - 1]?.id === currentItem.id) {
      const next = getNextChunk(items, currentItem);

      // Есть следующий чанк
      if (next.length) {
        await dispatch(bulkSkipMismatchedAction());
        return;
      }

      // Нет следующего чанка
      await dispatch(createAction('mismatchedProductsFeed/setFinishModal')(true));
      return;
    }

    // Просто шагаем вперед
    await dispatch(createAction('mismatchedProductsFeed/setNextCurrentItemSimple')());
  },
);
