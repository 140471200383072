import React, {
  useState, useEffect, useRef,
} from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { getTemplateItemWords, getAutocompleteValue } from '../../utils/api';
import { mergeWordsWithOrder } from '../../utils/words';
import { withDebounce } from '../../utils/debounce';

import AutocompleteInput from '../AutocompleteInput';
import MatchingItemWords from '../MatchingItemWords';

import styles from './styles.module.css';

const TemplateItemNameInput = ({
  onWordClick,
  isNotEmpty,
  itemId,
  input,
  completed,
  short,
  brands,
  ...rest
}) => {
  const dispatch = useDispatch();
  const [wordsVisiable, setWordsVisiable] = useState(false);
  const [optionsVisiable, setOptionsVisiable] = useState(false);
  const [words, setWords] = useState([]);
  const [options, setOptions] = useState([]);
  const merge = useRef();

  const handleWordClick = async (word) => {
    merge.current = true;
    await onWordClick(word);
    await new Promise((resolve) => {
      setTimeout(() => {
        resolve(true);
      }, 300);
    });
    const newWords = await getTemplateItemWords(itemId);
    const mergedWords = mergeWordsWithOrder(words, newWords);
    setWords(mergedWords);
  };

  useEffect(() => {
    const fetchWords = async () => {
      const fetchedWords = await getTemplateItemWords(itemId);
      let newWords = fetchedWords;
      if (merge.current) {
        newWords = mergeWordsWithOrder(words, fetchedWords);
        merge.current = false;
      }
      setWords(newWords);
    };

    if (merge.current === true) {
      merge.current = false;
      return;
    }

    if (wordsVisiable && !!isNotEmpty) {
      fetchWords();
    } else {
      setWords([]);
    }
  }, [wordsVisiable, itemId, isNotEmpty, dispatch, input]);

  const fetchOptionsHandler = withDebounce(() => {
    getAutocompleteValue(input, brands.split(';')).then((newOptions) => {
      setOptions(newOptions);
    });
  });

  useEffect(() => {
    const fetchOptions = async () => {
      await fetchOptionsHandler();
    };

    if (optionsVisiable) {
      fetchOptions();
    }
  }, [input, brands, optionsVisiable]);

  return (
    <div className={styles.wrapper}>
      {wordsVisiable && !completed && (
        <MatchingItemWords
          id={rest.id}
          words={words}
          onClick={handleWordClick}
          onHide={() => setWordsVisiable(false)}
          input={input}
          short={short}
        />
      )}
      <AutocompleteInput
        {...rest}
        options={options}
        defaultValue={input}
        onShowChange={setOptionsVisiable}
        onFocus={(e) => {
          if (rest.onFocus) {
            rest.onFocus(e);
          }
          setWordsVisiable(true);
        }}
        onTabKeyDown={() => {
          setWordsVisiable(false);
        }}
      />
    </div>
  );
};

TemplateItemNameInput.propTypes = {
  onWordClick: PropTypes.func.isRequired,
  isNotEmpty: PropTypes.bool.isRequired,
  input: PropTypes.string,
  completed: PropTypes.bool,
  short: PropTypes.bool,
  itemId: PropTypes.number.isRequired,
  brands: PropTypes.arrayOf().isRequired,
};

TemplateItemNameInput.defaultProps = {
  input: '',
  completed: false,
  short: false,
};

export default TemplateItemNameInput;
