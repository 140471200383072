import React from 'react';
import PropTypes from 'prop-types';
import { BsReverseBackspaceReverse } from 'react-icons/bs';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import ButtonWithIcon from '../ButtonWithIcon';

const RevertButton = ({
  onClick,
  disabled = false,
}) => (
  <OverlayTrigger placement="top" overlay={<Tooltip>Cancel changes</Tooltip>}>
    <div>
      <ButtonWithIcon
        Icon={<BsReverseBackspaceReverse />}
        size="sm"
        variant="danger"
        onFocus={(e) => {
          e.target.blur();
        }}
        onClick={onClick}
        style={{ height: '30px' }}
        disabled={disabled}
      />
    </div>
  </OverlayTrigger>
);

RevertButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

RevertButton.defaultProps = {
  disabled: false,
};

export default RevertButton;
