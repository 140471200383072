import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch, useSelector } from 'react-redux';

import {
  fetchItemImages,
  fetchSimilarImages,
  resetAllImages,
  setHoveredImageSrc,
} from '../../store/templateItems';

import KeyboardProvider from '../KeyboardProvider';
import LensImage from '../LensImage';
import ImageRowCarousel from './ImageRowCarousel';

import { KEYS } from '../../utils/keycode';
import { findSrcOfHoveredImage } from '../../utils/sidebarImageSelector';

import styles from './styles.module.css';

const ImageInfiniteSelector = ({
  item, onImageClick, scrollableTarget, disableKeyboard, onSimilarImageClick, minimized,
}) => {
  const dispatch = useDispatch();

  const similarImages = useSelector((store) => store.templateItems.similarImages);
  const images = useSelector((store) => store.templateItems.images);
  const offset = useSelector((store) => store.templateItems.imagesOffset);
  const hasMore = useSelector((store) => store.templateItems.imagesMore);
  const currentItemChanges = useSelector((store) => store.templateItems.currentItemChanges);

  const [rowIndex, setRowIndex] = useState(0);
  const [columnIndex, setColumnIndex] = useState(0);
  const prevItem = useRef();
  const prevScrollTarget = useRef();

  const rowIndexWithOffset = rowIndex >= similarImages.length
    ? rowIndex - similarImages.length
    : rowIndex;

  // Lens effect
  useEffect(() => {
    const onHover = (e) => {
      const { id } = e.target;
      const src = findSrcOfHoveredImage(id, images, similarImages);

      dispatch(setHoveredImageSrc(src));
    };

    const onMouseLeave = () => {
      dispatch(setHoveredImageSrc(''));
    };

    const target = document.getElementById(scrollableTarget);
    target?.addEventListener('mouseover', onHover);
    target?.addEventListener('mouseleave', onMouseLeave);

    return () => {
      target?.removeEventListener('mouseover', onHover);
      target?.removeEventListener('mouseleave', onMouseLeave);
    };
  }, [similarImages, images]);

  useEffect(() => {
    const resetScroll = () => {
      const container = document.getElementById(scrollableTarget);
      if (container) {
        container.scrollTop = 0;
      }
    };

    const loadImages = async (scrollResetNeed) => {
      await Promise.all([
        dispatch(fetchSimilarImages({ id: item.id })),
        dispatch(fetchItemImages({ id: item.id, offset: 0 })),
      ]);

      if (scrollResetNeed) {
        resetScroll();
      }
    };

    const resetImages = () => {
      setRowIndex(0);
      setColumnIndex(0);
      resetScroll();
      dispatch(resetAllImages());
    };

    if (item) {
      if (currentItemChanges?.completed ?? item.completed) {
        resetImages();
      } else {
        let scrollResetNeed = false;
        if (item.id !== prevItem.current?.id) {
          setRowIndex(0);
          setColumnIndex(0);
          scrollResetNeed = true;
        }
        prevItem.current = item;
        loadImages(scrollResetNeed);
      }
      prevItem.current = item;
    }
  }, [item, scrollableTarget]);

  useEffect(() => {
    const id = `images-${rowIndex}`;
    const target = document.getElementById(id);
    if (target) {
      if (prevScrollTarget.current?.id === id) {
        return;
      }

      prevScrollTarget.current = target;

      target.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [columnIndex, rowIndex, rowIndexWithOffset, images, similarImages]);

  const fetchData = async () => {
    await dispatch(fetchItemImages({ id: item.id, offset }));
  };

  const handleKeyDown = (e) => {
    if (disableKeyboard) {
      return;
    }

    switch (e.code) {
      case KEYS.ARROW_UP:
        e.preventDefault();
        setRowIndex(Math.max(0, rowIndex - 1));
        break;
      case KEYS.ARROW_DOWN:
        e.preventDefault();
        setRowIndex(Math.min(images.length + similarImages.length - 1, rowIndex + 1));
        break;
      case KEYS.ARROW_LEFT:
        e.preventDefault();
        if (minimized) {
          break;
        }
        setColumnIndex(Math.max(0, columnIndex - 1));
        break;
      case KEYS.ARROW_RIGHT:
        e.preventDefault();
        if (minimized) {
          break;
        }
        if (rowIndex >= similarImages.length) {
          setColumnIndex(Math.min(
            images[rowIndexWithOffset].images.length - 1,
            columnIndex + 1,
          ));
        } else {
          setColumnIndex(Math.min(
            similarImages[rowIndexWithOffset].images.length - 1,
            columnIndex + 1,
          ));
        }
        break;
      case KEYS.ENTER:
        e.preventDefault();
        if (rowIndex < similarImages.length) {
          onSimilarImageClick(similarImages[rowIndex].id);
        } else {
          onImageClick(images[rowIndexWithOffset].images[columnIndex].id);
        }
        break;
      default:
        break;
    }
  };

  const isSameSequence = (templateItem, similarImage) => (
    templateItem?.product_id
    && templateItem.product_id === similarImage.product_id
  ) || (
    templateItem?.template_item_image_group_id
    && templateItem.template_item_image_group_id === similarImage.template_item_image_group_id
  );

  return (
    <KeyboardProvider onKeyDown={handleKeyDown}>
      <InfiniteScroll
        scrollableTarget={scrollableTarget}
        dataLength={images.length}
        next={fetchData}
        hasMore={hasMore}
        className={styles.container}
      >
        <div className={cx(styles.similar, !similarImages.length && styles.invisible)}>
          {minimized && similarImages.map((i, row) => (
            <ImageRowCarousel
              key={i.id}
              images={i.images.map((it) => ({ url: it }))}
              rowId={`images-${row}`}
              currentRowId={i.id}
              imageClassName={styles.image}
              onImageClick={() => onSimilarImageClick(i.id)}
              rowClassName={cx(
                !disableKeyboard && row === rowIndex && styles.selectedRow,
              )}
              similar
            />
          ))}
          {!minimized && similarImages.map((i, row) => (
            <div
              key={i.id}
              className={cx(
                styles.row,
                styles.similarRow,
                !disableKeyboard && row === rowIndex && styles.selectedRow,
                isSameSequence(item, i) && styles.choosenRow,
              )}
              onClick={() => onSimilarImageClick(i.id)}
            >
              <div
                className={styles.imagesRow}
                id={`images-${row}`}
              >
                {i.images.map((image, idx) => (
                  <LensImage
                    className={cx(styles.image)}
                    imageUrl={image}
                    bigImageUrl={i.medium_images[idx]}
                    position={idx > 2 ? 'left' : 'right'}
                    id={`similar-images-${i.id}-${idx}`}
                  />
                ))}
              </div>
            </div>
          ))}
        </div>
        {minimized && images.map((i, row) => (
          <ImageRowCarousel
            key={i.id}
            images={i.images}
            rowId={`images-${row + similarImages.length}`}
            currentRowId={i.id}
            imageClassName={styles.image}
            onImageClick={onImageClick}
            rowClassName={cx(
              !disableKeyboard && row + similarImages.length === rowIndex && styles.selectedRow,
            )}
          />
        ))}
        {!minimized && images.map((i, row) => (
          <div
            key={i.id}
            className={cx(
              styles.row,
              !disableKeyboard && row + similarImages.length === rowIndex && styles.selectedRow,
              i.id === item?.product_id && styles.choosenRow,
            )}
          >
            <div
              className={cx(styles.imagesRow, minimized && styles.imagesRowSmall)}
              id={`images-${row + similarImages.length}`}
            >
              {i.images.map((image, column) => (
                <LensImage
                  className={cx(
                    styles.image,
                    !disableKeyboard
                    && columnIndex === column
                    && row + similarImages.length === rowIndex
                    && styles.imageSelected,
                  )}
                  onClick={() => onImageClick(image.id)}
                  position={column > 2 ? 'left' : 'right'}
                  imageUrl={image.url}
                  bigImageUrl={image.medium_url}
                  key={`images-${i.id}-${image.id}`}
                  id={`images-${i.id}-${image.id}`}
                />
              ))}
            </div>
            <p className={cx(styles.rowBrands, 'text-secondary')}>
              <span><strong>{i.name}</strong></span>
              <span>{` – ${i.brand}`}</span>
            </p>
          </div>
        ))}
      </InfiniteScroll>
    </KeyboardProvider>
  );
};

ImageInfiniteSelector.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number.isRequired,
    product_images: PropTypes.arrayOf.isRequired,
    product_id: PropTypes.number.isRequired,
    template_item_image_group_id: PropTypes.number.isRequired,
    completed: PropTypes.number.isRequired,
  }),
  onImageClick: PropTypes.func.isRequired,
  onSimilarImageClick: PropTypes.func.isRequired,
  scrollableTarget: PropTypes.string.isRequired,
  disableKeyboard: PropTypes.bool,
  minimized: PropTypes.bool,
};

ImageInfiniteSelector.defaultProps = {
  disableKeyboard: false,
  item: null,
  minimized: false,
};

export default ImageInfiniteSelector;
