import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { setMainImageIndex, setMismatchedMatchAction } from '../../store/mismatchedProductsFeed';
import { getBorderByAction } from '../../utils/border';

import ProductsCarousel from '../ProductsCarousel';

const MismatchedProductMainImage = ({ withMatchButtons }) => {
  const dispatch = useDispatch();
  const currentItem = useSelector((store) => store.mismatchedProductsFeed.currentItem);
  const mainImageIndex = useSelector((store) => store.mismatchedProductsFeed.mainImageIndex);

  if (!currentItem) {
    return null;
  }

  return (
    <ProductsCarousel
      matchButtons={withMatchButtons ? {
        itemSelector: (store) => store.mismatchedProductsFeed.currentItem,
        matchAction: setMismatchedMatchAction,
      } : {}}
      images={currentItem.images}
      mediumImages={currentItem.medium_images}
      largeImages={currentItem.large_images}
      colors={currentItem.colors}
      mainImageIndex={mainImageIndex}
      onClick={(index) => dispatch(setMainImageIndex(index))}
      border={getBorderByAction(currentItem.action)}
      align="right"
    />
  );
};

export default MismatchedProductMainImage;
