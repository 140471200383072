import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Toast, ToastContainer } from 'react-bootstrap';

import { toggleNextItemToast } from '../../store/toasts';

const NextItemToast = () => {
  const dispatch = useDispatch();
  const nextItemToastIsVisible = useSelector((store) => store.toasts.nextItem);

  return (
    <ToastContainer position="top-center">
      <Toast
        onClose={() => dispatch(toggleNextItemToast())}
        show={nextItemToastIsVisible}
        bg="info"
        delay={2000}
        autohide
      >
        <Toast.Body>Next!</Toast.Body>
      </Toast>
    </ToastContainer>
  );
};

export default NextItemToast;
