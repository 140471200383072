import React from 'react';
import { useSelector } from 'react-redux';
import { Container, Table } from 'react-bootstrap';

import { selectIntervalsForCurrentMatcher } from '../../store/matchers/selectors';

import { formatAsHoursFromSeconds } from '../../utils/date';

const MatcherHoursBreakdown = () => {
  const intervals = useSelector(selectIntervalsForCurrentMatcher());

  if (!intervals || !intervals.length) {
    return null;
  }

  return (
    <Container className="mt-5">
      <h4>Hours Breakdown</h4>
      <Table size="sm" className="w-50" hover>
        <thead>
          <th>Date</th>
          <th>Interval</th>
          <th>Total Time</th>
        </thead>
        <tbody>
          {intervals.map((interval) => (
            <tr key={`${interval.day}${interval.time_from}${interval.time_to}`}>
              <td>{interval.day}</td>
              <td>{`${interval.time_from} - ${interval.time_to}`}</td>
              <td>{formatAsHoursFromSeconds(interval.seconds)}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  );
};

export default MatcherHoursBreakdown;
