import React from 'react';
import Pagination from 'react-bootstrap/Pagination';
import Dropdown from 'react-bootstrap/Dropdown';

const PaginationBlock = ({
  current, total, pageSize, onChange, onChangePageSize, options = [30, 50, 100],
}) => {
  const fixedCurrent = current + 1 > total ? total - 1 : current;
  return (
    <div>
      <Pagination className="flex-wrap">
        <Pagination.First onClick={() => onChange(0)} disabled={fixedCurrent <= 0} />
        <Pagination.Prev onClick={() => onChange(fixedCurrent - 1)} disabled={fixedCurrent <= 0} />
        {Array(total).fill(null).map((_, index) => (
          <Pagination.Item
            active={index === fixedCurrent}
            onClick={() => onChange(index)}
          >
            {index + 1}
          </Pagination.Item>
        ))}
        <Pagination.Next
          onClick={() => onChange(fixedCurrent + 1)}
          disabled={fixedCurrent >= total - 1}
        />
        <Pagination.Last onClick={() => onChange(total - 1)} disabled={fixedCurrent >= total - 1} />
      </Pagination>
      <Dropdown>
        <Dropdown.Toggle variant="Secondary">
          {`Items per page: ${pageSize}`}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {options.map((size) => (
            <Dropdown.Item key={size} onClick={() => onChangePageSize(size)}>
              {size}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default PaginationBlock;
