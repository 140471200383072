import React from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import MatcherLayout from '../../components/MatcherLayout';

import styles from './styles.module.css';

const MatcherHomePage = () => (
  <MatcherLayout contentClassName={styles.content}>
    <Link to="/matcher/match-bulk">
      <Button variant="primary" size="lg">
        Start matching
      </Button>
    </Link>
  </MatcherLayout>
);

export default MatcherHomePage;
