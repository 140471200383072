import React from 'react';

import TemplateItemMainImage from '../TemplateItemMainImage';
import CountdownClock from '../CountdownClock';
import TemplateItemsAmountLeft from '../TemplateItemsAmountLeft';
import SectionHeader from '../SectionHeader';
import SectionBodyLayout from '../SectionBodyLayout';
import TemplateItemsScroll from '../TemplateItemsScroll';

const TemplatesSection = () => (
  <>
    <SectionHeader leftSideComponent={<TemplateItemsAmountLeft />}>
      <CountdownClock />
    </SectionHeader>
    <SectionBodyLayout listPosition="right" listComponent={<TemplateItemsScroll />}>
      <TemplateItemMainImage />
    </SectionBodyLayout>
  </>
);

export default TemplatesSection;
