import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import scrollIntoView from 'scroll-into-view';

import { getBoxShadow } from '../../utils/border';

import InfiniteBidirectionalScroll from '../InfiniteBidirectionalScroll';

import styles from './styles.module.css';

const SMOOTH_BEHAVIOR = {
  behavior: 'smooth',
  block: 'start',
  inline: 'center',
};

const ItemsScroll = ({
  items, currentItemId, onClick, rtl, infiniteOptions,
}) => {
  const itemRef = useRef();
  const needAutoScroll = useRef(true);

  useEffect(() => {
    if (needAutoScroll.current) {
      if (itemRef.current) {
        setTimeout(() => {
          itemRef.current.scrollIntoView(SMOOTH_BEHAVIOR);
        }, 1000);
      }
      needAutoScroll.current = false;
    }
  }, [needAutoScroll]);

  useEffect(() => {
    if (currentItemId !== null && itemRef.current) {
      scrollIntoView(itemRef.current, {
        time: 250,
        align: {
          top: 0,
          topOffset: 4,
        },
      });
    }
  }, [currentItemId]);

  return (
    <InfiniteBidirectionalScroll
      className={cx(styles.container, rtl && styles.rtl)}
      hasMoreTop={infiniteOptions ? infiniteOptions.hasMoreTop : false}
      nextTop={infiniteOptions ? infiniteOptions.nextTop : null}
      hasMoreBottom={infiniteOptions ? infiniteOptions.hasMoreBottom : false}
      nextBottom={infiniteOptions ? infiniteOptions.nextBottom : null}
    >
      {items.map((item) => (
        <img
          key={item.id}
          src={item.image}
          alt="scroll"
          style={{
            boxShadow: getBoxShadow(item.border, currentItemId === item.id),
          }}
          className={cx(
            styles.image,
            currentItemId === item.id && styles.active,
            !onClick && styles.noInteraction,
          )}
          onClick={() => onClick && onClick(item.id)}
          ref={currentItemId === item.id ? itemRef : null}
        />
      ))}
    </InfiniteBidirectionalScroll>
  );
};

ItemsScroll.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    image: PropTypes.string.isRequired,
    border: PropTypes.shape({
      color: PropTypes.string.isRequired,
      double: PropTypes.bool,
    }),
  })).isRequired,
  currentItemId: PropTypes.number,
  onClick: PropTypes.func,
  rtl: PropTypes.bool,
  infiniteOptions: PropTypes.shape({
    hasMoreTop: PropTypes.bool,
    nextTop: PropTypes.func,
    hasMoreBottom: PropTypes.bool,
    nextBottom: PropTypes.func,
  }),
};

ItemsScroll.defaultProps = {
  currentItemId: null,
  rtl: false,
  infiniteOptions: null,
  onClick: null,
};

export default ItemsScroll;
