import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'react-bootstrap';
import { BsTrash } from 'react-icons/bs';

import ButtonWithIcon from '../ButtonWithIcon';

const DeleteButtonWithModal = ({
  onDelete, text, modalTitle, modalText, size, Icon, buttonClassName,
}) => {
  const [modalIsOpened, setModalIsOpened] = useState(false);

  return (
    <>
      <Modal show={modalIsOpened} onHide={() => setModalIsOpened(false)}>
        <Modal.Header>
          <Modal.Title>{modalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{modalText}</Modal.Body>
        <Modal.Footer>
          <Button variant="light" onClick={() => setModalIsOpened(false)}>Close</Button>
          <Button
            variant="danger"
            onClick={() => {
              setModalIsOpened(false);
              onDelete();
            }}
          >
            {text || 'Delete'}
          </Button>
        </Modal.Footer>
      </Modal>
      <ButtonWithIcon
        Icon={Icon || <BsTrash />}
        size={size}
        text={text}
        variant="danger"
        onClick={() => setModalIsOpened(true)}
        className={buttonClassName}
      />
    </>
  );
};

DeleteButtonWithModal.propTypes = {
  onDelete: PropTypes.func.isRequired,
  text: PropTypes.string,
  modalTitle: PropTypes.string.isRequired,
  modalText: PropTypes.string.isRequired,
  size: PropTypes.string,
  Icon: PropTypes.node,
  buttonClassName: PropTypes.string,
};

DeleteButtonWithModal.defaultProps = {
  size: '',
  Icon: null,
  text: '',
  buttonClassName: '',
};

export default DeleteButtonWithModal;
