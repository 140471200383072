import React from 'react';
import { Container, Form } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';

import { setDateFrom, setDateTo } from '../../../../store/matchers';

import { formatDateAsValue } from '../../../../utils/date';

import styles from './styles.module.css';

const DatePicker = () => {
  const dispatch = useDispatch();
  const dateFrom = useSelector((store) => store.matchers.dateFrom);
  const dateTo = useSelector((store) => store.matchers.dateTo);

  return (
    <Container className="d-flex align-items-center">
      <span className={styles.label}>Date From:</span>
      <Form.Control
        className={styles.dateInput}
        type="date"
        value={formatDateAsValue(dateFrom) || ''}
        onChange={(e) => dispatch(setDateFrom(e.target.value))}
        required
      />
      <span className={styles.label}>Date To:</span>
      <Form.Control
        className={styles.dateInput}
        type="date"
        value={formatDateAsValue(dateTo) || ''}
        onChange={(e) => dispatch(setDateTo(e.target.value))}
        required
      />
    </Container>
  );
};

export default DatePicker;
