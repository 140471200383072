import React from 'react';
import PropTypes from 'prop-types';
import {
  Route,
  Redirect,
} from 'react-router-dom';

import useAuth from './hooks/useAuth';

const ProtectedRoute = ({ children, adminOnly, ...rest }) => {
  const { isLoggedIn, user } = useAuth();

  return (
    <Route
      {...rest}
      render={({ location }) => {
        if (!isLoggedIn) {
          return <Redirect to={{ pathname: '/login', state: { from: location } }} />;
        }

        if (adminOnly && user.type !== 'main') {
          return <Redirect to={{ pathname: '/matcher/home', state: { from: location } }} />;
        }

        if (!adminOnly && user.type === 'main') {
          return <Redirect to={{ pathname: '/admin/home', state: { from: location } }} />;
        }

        return children;
      }}
    />
  );
};

ProtectedRoute.propTypes = {
  children: PropTypes.node.isRequired,
  adminOnly: PropTypes.bool,
};

ProtectedRoute.defaultProps = {
  adminOnly: false,
};

export default ProtectedRoute;
