import React, { useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';

const FocusHandler = ({ children, focused, onFocusChanged }) => {
  const wrapperRef = useRef();

  const onOutsideClick = useCallback((e) => {
    if (wrapperRef.current && !wrapperRef.current.contains(e.target)) {
      onFocusChanged(false);
    }
  }, [onFocusChanged]);

  const onInsideClick = () => {
    onFocusChanged(true);
  };

  useEffect(() => {
    if (focused) {
      window.addEventListener('click', onOutsideClick);
    }

    return () => window.removeEventListener('click', onOutsideClick);
  }, [focused, onOutsideClick]);

  return (
    <div
      onClick={onInsideClick}
      ref={wrapperRef}
      style={{ height: '100%' }}
    >
      {children}
    </div>
  );
};

FocusHandler.propTypes = {
  children: PropTypes.node.isRequired,
  focused: PropTypes.bool.isRequired,
  onFocusChanged: PropTypes.func.isRequired,
};

export default FocusHandler;
