import { createAsyncThunk, createAction } from '@reduxjs/toolkit';
import { withDebounce, DEBOUCE_AOUTOCOMPLETE_MS } from '../../utils/debounce';

import {
  updateMatcherById,
  deleteMatcherById,
  createMatcher as createMatcherByApi,
  getMatchers as getMatchersByApi,
  getMetricsForAllMatchers,
} from '../../utils/api';

const SET_ERROR_ACTION = 'matchers/setError';
const SET_DATE_FROM_ACTION = 'matchers/setDateFromLocal';
const SET_DATE_TO_ACTION = 'matchers/setDateToLocal';

const UNIQ_NAMES_ERR = 'Names must be unique';

const updateWithDebounce = withDebounce(async (matcherId, newMatcher, dispatch) => {
  const success = await updateMatcherById(matcherId, newMatcher);
  dispatch(createAction(SET_ERROR_ACTION)({ error: success ? '' : UNIQ_NAMES_ERR }));
}, DEBOUCE_AOUTOCOMPLETE_MS);

const findMatcherById = (id, state) => state.matchers.matchers.find(
  (matcher) => matcher.id === id,
);

export const getMatchers = createAsyncThunk(
  'matchers/getMatchers',
  () => getMatchersByApi(),
);

export const createMatcher = createAsyncThunk(
  'matchers/createMatcher',
  async ({ name, password, enabled }, { dispatch }) => {
    const success = await createMatcherByApi(name, password, enabled);
    dispatch(createAction(SET_ERROR_ACTION)({ error: success ? '' : UNIQ_NAMES_ERR }));
    dispatch(getMatchers());
  },
);

export const deleteMatcher = createAsyncThunk(
  'matchers/deleteMatcher',
  async (id, { dispatch, getState, rejectWithValue }) => {
    const matcherToDelete = findMatcherById(id, getState());
    if (!matcherToDelete) {
      return rejectWithValue({ error: `Matcher with id: ${id} not found` });
    }

    await deleteMatcherById(id);
    dispatch(getMatchers());
    return Promise.resolve();
  },
);

export const updateMatcher = createAsyncThunk(
  'matchers/updateMatcher',
  ({ id, field, newValue }, { dispatch, getState, rejectWithValue }) => {
    const matcherToUpdate = findMatcherById(id, getState());
    if (!matcherToUpdate) {
      return rejectWithValue({ error: `Matcher with id: ${id} not found` });
    }

    const newMatcher = { ...matcherToUpdate, [field]: newValue };
    updateWithDebounce(id, newMatcher, dispatch);

    return newMatcher;
  },
);

export const getMetrics = createAsyncThunk(
  'matchers/getMetrics',
  (_, { getState }) => {
    const { dateFrom, dateTo } = getState().matchers;

    return getMetricsForAllMatchers(dateFrom, dateTo);
  },
);

export const setDateFrom = createAsyncThunk(
  'matchers/setDateFrom',
  (newDate, { dispatch }) => {
    dispatch(createAction(SET_DATE_FROM_ACTION)(newDate));
    dispatch(getMetrics());
  },
);

export const setDateTo = createAsyncThunk(
  'matchers/setDateTo',
  (newDate, { dispatch }) => {
    dispatch(createAction(SET_DATE_TO_ACTION)(newDate));
    dispatch(getMetrics());
  },
);
