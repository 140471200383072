import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';

import LoginForm from '../../components/LoginForm';

import useAuth from '../../hooks/useAuth';

import styles from './styles.module.css';

const LoginPage = () => {
  const { logIn, isLoggedIn } = useAuth();
  const [hasError, setHasError] = useState(false);

  const onSubmitLoginForm = async (username, password) => {
    const success = await logIn(username, password);

    if (!success) {
      setHasError(true);
    }
  };

  if (isLoggedIn) {
    return <Redirect to="/" />;
  }

  return (
    <div className={styles.loginPage}>
      <LoginForm onSubmit={onSubmitLoginForm} hasError={hasError} />
    </div>
  );
};

export default LoginPage;
