import React from 'react';

import AdminLayout from '../../components/AdminLayout';
import MatchersTable from '../../components/MatchersTable';
import DatePicker from '../../components/MatchersTable/Metrics/DatePicker';
import MatchersSelector from '../../components/MatchersSelector';
import MatcherHoursBreakdown from '../../components/MatcherHoursBreakdown';
import MatchersHoursPerDayChart from '../../components/MatchersHoursPerDayChart';

const AdminMatchersPage = () => (
  <AdminLayout>
    <DatePicker />
    <MatchersTable />
    <MatchersSelector />
    <MatchersHoursPerDayChart />
    <MatcherHoursBreakdown />
  </AdminLayout>
);

export default AdminMatchersPage;
