import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { setMainImageIndex } from '../../store/feedbackProductsFeed';
import { getBorderByAction } from '../../utils/border';

import ProductsCarousel from '../ProductsCarousel';

const FeedbackProductMainImage = () => {
  const dispatch = useDispatch();
  const currentItem = useSelector((store) => store.feedbackProductsFeed.currentItem);
  const mainImageIndex = useSelector((store) => store.feedbackProductsFeed.mainImageIndex);

  if (!currentItem) {
    return null;
  }

  return (
    <ProductsCarousel
      images={currentItem.images}
      mediumImages={currentItem.medium_images}
      largeImages={currentItem.large_images}
      colors={currentItem.colors}
      mainImageIndex={mainImageIndex}
      onClick={(index) => dispatch(setMainImageIndex(index))}
      border={getBorderByAction(currentItem.correct_action)}
      align="right"
    />
  );
};

export default FeedbackProductMainImage;
