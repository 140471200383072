import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  sendProlongEvent,
} from '../../utils/api';

export const prolongTimer = createAsyncThunk(
  'prolong/prolongTimer',
  () => sendProlongEvent(),
);
