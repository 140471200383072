import React from 'react';
import cx from 'classnames';

import styles from './styles.module.css';

const Dots = ({ className, current, total }) => (
  <div
    className={cx(styles.container, className)}
  >
    {Array.from(Array(total).keys()).map((it) => (
      <div
        className={cx(styles.dot, it === current && styles.active)}
        key={it}
      />
    ))}
  </div>
);

export default Dots;
