import React from 'react';

import SectionBodyLayout from '../SectionBodyLayout';
import FeedbackTemplateItemsScroll from '../FeedbackTemplateItemsScroll';
import FeedbackTemplateMainImage from '../FeedbackTemplateMainImage';

const TemplatesFeedbackSection = () => (
  <SectionBodyLayout listPosition="right" listComponent={<FeedbackTemplateItemsScroll />}>
    <FeedbackTemplateMainImage />
  </SectionBodyLayout>
);

export default TemplatesFeedbackSection;
