export const itemNotEmpty = (item, currentItemChanges) => item
  && (currentItemChanges?.brands ?? item.brands)
  && (currentItemChanges?.categories ?? item.categories)
  && ((currentItemChanges?.brands ?? item.brands ?? []).length
    || (currentItemChanges?.name ?? item.name)
    || (currentItemChanges?.description ?? item.description)
    || (currentItemChanges?.colors ?? item.colors ?? []).length
    || (currentItemChanges?.categories ?? item.categories ?? []).length
    || (currentItemChanges?.fabric ?? item.fabric)
  );
