import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Spinner } from 'react-bootstrap';

import styles from './styles.module.css';

const Loader = ({ className }) => (
  <div className={cx(styles.container, className)}>
    <Spinner animation="border" variant="primary" />
  </div>
);

Loader.propTypes = {
  className: PropTypes.string,
};

Loader.defaultProps = {
  className: '',
};

export default Loader;
