import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { BsEye } from 'react-icons/bs';

import ButtonWithIcon from '../ButtonWithIcon';

const ViewQueryButton = ({ id }) => (
  <OverlayTrigger placement="left" overlay={<Tooltip>View Query</Tooltip>}>
    <a href={`${process.env.REACT_APP_API_URL}/template-items/${id}/view-query`} target="_blank">
      <ButtonWithIcon
        Icon={<BsEye />}
        size="sm"
        variant="info"
        style={{ height: '30px' }}
      />
    </a>
  </OverlayTrigger>
);

export default ViewQueryButton;
