import { createAsyncThunk, createAction } from '@reduxjs/toolkit';

import {
  getProductsFeed,
  setMatchActionForProductThrowsError,
  eraseLastMatch,
  setBulkSkipActionForProductTrowsError,
} from '../../utils/api';

import { fetchSearchFeed } from '../searchFeed/middleware';

import { isAllMatched, isLastChunk } from './selectors';
import { PRODUCT_ACTIONS } from '../../utils/actions';
import { BULK_CHUNK_COUNT, NEXT_CHUNK_FIRST_ITEM } from '../../utils/chunk';

export const fetchProductsFeed = createAsyncThunk(
  'productsFeed/fetchProductsFeed',
  async ({
    offset, count, force, direction, next,
  }, { getState, dispatch }) => {
    const currentSearchItem = getState().searchFeed.currentItem;

    if (currentSearchItem) {
      const response = await getProductsFeed(currentSearchItem.id, offset, count);

      if (response.count > 0) {
        return {
          items: response.products,
          force,
          count: response.count,
          direction,
          next,
        };
      }

      dispatch(createAction('searchFeed/markCurrentItemAsFinished')());
    }

    return {
      items: [],
      force,
      direction,
      next,
    };
  },
);

export const setMatchAction = createAsyncThunk(
  'productsFeed/setMatchAction',
  async ({ action, mergeApproval }, { getState, dispatch }) => {
    dispatch(createAction('prolong/resetTimer')());

    const currentSearchItem = getState().searchFeed.currentItem;
    const currentProductItem = getState().productsFeed.currentItem;

    if (currentSearchItem && currentProductItem) {
      try {
        const response = await setMatchActionForProductThrowsError(
          currentSearchItem.id,
          currentProductItem.id,
          action,
          mergeApproval,
        );
        if (!response) {
          return null;
        }

        return {
          id: currentProductItem.id,
          action,
          ...response,
        };
      } catch (err) {
        if (err.response?.status === 404) {
          dispatch(fetchSearchFeed());
        }
        return null;
      }
    }

    return null;
  },
);

export const bulkSkipAction = createAsyncThunk(
  'productsFeed/bulkSkipAction',
  async (_, { getState, dispatch }) => {
    dispatch(createAction('prolong/resetTimer')());

    const currentSearchItem = getState().searchFeed.currentItem;
    const itemsToSkip = getState().productsFeed.items?.filter(
      (item) => !item.action || item.action === PRODUCT_ACTIONS.SKIP,
    );

    if (itemsToSkip && itemsToSkip.length) {
      const ids = itemsToSkip.map((item) => item.id);
      try {
        const response = await setBulkSkipActionForProductTrowsError(
          currentSearchItem.id,
          ids,
        );

        if (!response) {
          return null;
        }

        return {
          ids,
          ...response,
        };
      } catch (err) {
        if (err.response?.status === 404) {
          dispatch(fetchSearchFeed());
        }
        return null;
      }
    }

    return null;
  },
);

export const eraseLastItem = createAsyncThunk(
  'productsFeed/eraseLastItem',
  async (_, { getState }) => {
    const currentSearchItem = getState().searchFeed.currentItem;
    const { productsFeed } = getState();
    const currentIndex = productsFeed.items.findIndex((i) => i.id === productsFeed.currentItem.id);

    if (currentIndex > 0) {
      return eraseLastMatch(currentSearchItem.id, productsFeed.items[currentIndex - 1]);
    }

    return {};
  },
);

export const bulkPrevPage = createAsyncThunk(
  'productsFeed/bulkPrevPage',
  async (_, { getState, dispatch }) => {
    const nextOffset = getState().productsFeed.bulkOffset - BULK_CHUNK_COUNT;
    dispatch(createAction('productsFeed/setCurrentItemNumber')(nextOffset + 5));
    dispatch(createAction('productsFeed/setBulkOffset')({ offset: nextOffset }));
    dispatch(fetchProductsFeed({
      offset: nextOffset,
      count: BULK_CHUNK_COUNT,
      force: true,
      next: NEXT_CHUNK_FIRST_ITEM.LAST,
    }));
  },
);

export const bulkNextPage = createAsyncThunk(
  'productsFeed/bulkNextPage',
  async (_, { getState, dispatch }) => {
    let goNext = true;
    await dispatch(bulkSkipAction()).unwrap();
    const allMatched = isLastChunk(getState());
    if (allMatched) {
      dispatch(createAction('productsFeed/setFinalizeModal')(true));
      goNext = false;
    }

    if (!goNext) {
      return;
    }

    const nextOffset = getState().productsFeed.bulkOffset + BULK_CHUNK_COUNT;
    dispatch(createAction('productsFeed/setBulkBackwardOffset')({ offset: nextOffset }));
    dispatch(createAction('productsFeed/setCurrentItemNumber')(nextOffset + 1));
    dispatch(createAction('productsFeed/setBulkOffset')({ offset: nextOffset }));
    dispatch(fetchProductsFeed({
      offset: nextOffset,
      count: BULK_CHUNK_COUNT,
      force: true,
      next: NEXT_CHUNK_FIRST_ITEM.FIRST,
    }));
  },
);

export const checkAllMatched = createAsyncThunk(
  'productsFeed/checkAllMatched',
  (_, { getState }) => isAllMatched(getState()),
);

export const checkLastChunk = createAsyncThunk(
  'productsFeed/checkLastChunk',
  (_, { getState }) => isLastChunk(getState()),
);
