import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal, Button } from 'react-bootstrap';
import { Redirect } from 'react-router-dom';

import {
  prolongTimer,
  tick,
  resetTimer,
} from '../../store/prolong';

// Show popup before 10s to end
const SHOW_POPUP_MOMENT = 10 * 1000;

const ProlongPopup = () => {
  const dispatch = useDispatch();
  const timer = useSelector((store) => store.prolong.timer);

  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(tick());
    }, 1000);

    return () => {
      clearInterval(interval);
      dispatch(resetTimer());
    };
  }, [dispatch]);

  const show = timer < SHOW_POPUP_MOMENT;

  const onProlong = () => {
    dispatch(prolongTimer());
  };

  if (timer <= 0) {
    return <Redirect to={{ pathname: '/matcher/home' }} />;
  }

  return (
    <Modal show={show} backdrop="static">
      <Modal.Header>
        {`Inactivity timer: ${timer / 1000} s`}
      </Modal.Header>
      <Modal.Footer>
        <Button variant="primary" onClick={onProlong}>
          Prolong
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ProlongPopup;
