import { createSlice } from '@reduxjs/toolkit';

import {
  loadColumnsFromLocalStorage,
  toggleColumnWithLocalStorage,
  toggleMinimizeStaticColumnWithLocalStorage,
} from './middleware';

export const COLUMN_NAMES = {
  number: 'Number',
  sku: 'SKU',
  manual_price: 'Manual Price',
  image: 'Image',
  show_images: 'Show all images',
  brands: 'Brand',
  name: 'Name',
  description: 'Description',
  colors: 'Colors',
  categories: 'Categories',
  fabric: 'Fabric',
  note: 'Note',
  static: 'Static Column',
};

const initialState = {
  items: Object.keys(COLUMN_NAMES).reduce((acc, current) => ({
    ...acc,
    [current]: true,
  }), {}),
  options: {
    minimizeStaticColumn: false,
  },
};

export const columnsSlice = createSlice({
  name: 'columns',
  initialState,
  reducers: {
    toggleColumn: (state, action) => {
      if (action.payload in state.items) {
        state.items[action.payload] = !state.items[action.payload];
      }
    },
    setColumns: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        if (key in state.items) {
          state.items[key] = action.payload[key];
        }
      });
    },
    setOptions: (state, action) => {
      state.options = action.payload;
    },
    toggleMinimizeStaticColumn: (state) => {
      state.options.minimizeStaticColumn = !state.options.minimizeStaticColumn;
    },
  },
});

export const {
  toggleColumns,
  setColumns,
  setOptions,
  toggleMinimizeStaticColumn,
} = columnsSlice.actions;

export {
  loadColumnsFromLocalStorage,
  toggleColumnWithLocalStorage,
  toggleMinimizeStaticColumnWithLocalStorage,
};

export default columnsSlice.reducer;
