import React from 'react';
import { useSelector } from 'react-redux';

const TemplateItemsAmountLeft = () => {
  const items = useSelector((store) => store.searchFeed.items);
  const total = items.length;
  const finished = items.filter((item) => item.finished).length;

  return (
    <h3>{`${finished}/${total}`}</h3>
  );
};

export default TemplateItemsAmountLeft;
