import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { KEYS } from '../../utils/keycode';
import { PRODUCT_ACTIONS } from '../../utils/actions';

import {
  moveMainImageIndex,
  setMismatchedMatchAction,
  setPrevCurrentItem,
  setNextCurrentItem,
  bulkSkipMismatchedAction,
  setFinishModal,
  finilizeCurrentItem,
} from '../../store/mismatchedProductsFeed';
import { withThrottle, THROTTLE_MATCH_MS } from '../../utils/debounce';

import KeyboardProvider from '../KeyboardProvider';
import FinishTemplateItemModal from '../FinishTemplateItemModal';

const InspectionPageKeyboardProvider = ({ children }) => {
  const dispatch = useDispatch();
  const disabled = useSelector((store) => store.mismatchedProductsFeed.noteInputActive);
  const currentItem = useSelector((store) => store.mismatchedProductsFeed.currentItem);
  const finishModal = useSelector((store) => store.mismatchedProductsFeed.finishModal);

  const onMatchKeyPressed = async (action) => {
    const nextAction = currentItem.action && currentItem.action !== PRODUCT_ACTIONS.SKIP
      ? PRODUCT_ACTIONS.SKIP
      : action;
    await dispatch(setMismatchedMatchAction({ action: nextAction }));
    await dispatch(setNextCurrentItem());
  };

  const onKeyUp = withThrottle((e) => {
    if (disabled) {
      return;
    }

    const { code } = e;

    switch (code) {
      case KEYS.SPACE:
        e.stopPropagation();
        dispatch(bulkSkipMismatchedAction());
        break;
      default:
        break;
    }
  }, THROTTLE_MATCH_MS);

  const onKeyDown = withThrottle((e) => {
    if (disabled) {
      return;
    }

    const preventDeafult = () => {
      e.preventDefault();
      e.stopPropagation();
    };

    const { code } = e;

    switch (code) {
      case KEYS.ARROW_DOWN:
        preventDeafult();
        dispatch(setNextCurrentItem());
        break;
      case KEYS.ARROW_UP:
        preventDeafult();
        dispatch(setPrevCurrentItem());
        break;
      case KEYS.KEY_A:
        onMatchKeyPressed(PRODUCT_ACTIONS.MODEL);
        break;
      case KEYS.KEY_S:
        onMatchKeyPressed(PRODUCT_ACTIONS.EXACT);
        break;
      case KEYS.ARROW_RIGHT:
        dispatch(moveMainImageIndex(1));
        break;
      case KEYS.ARROW_LEFT:
        dispatch(moveMainImageIndex(-1));
        break;
      default:
        break;
    }
  }, THROTTLE_MATCH_MS);

  return (
    <>
      <KeyboardProvider onKeyDown={onKeyDown} onKeyUp={onKeyUp}>
        {children}
      </KeyboardProvider>
      <FinishTemplateItemModal
        show={finishModal}
        onCancel={() => dispatch(setFinishModal(false))}
        onConfirm={() => {
          dispatch(setFinishModal(false));
          dispatch(finilizeCurrentItem());
        }}
      />
    </>
  );
};

InspectionPageKeyboardProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default InspectionPageKeyboardProvider;
