export const selectMetricsForCurrentMatcher = () => (store) => {
  const { matchers: { selectedMatcher, metrics } } = store;

  if (!selectedMatcher || !Object.values(metrics).length) {
    return null;
  }

  const matcherMetrics = Object.values(metrics)
    .find((metric) => metric.user_id === selectedMatcher.id);

  return matcherMetrics || null;
};

export const selectIntervalsForCurrentMatcher = () => (store) => {
  const metrics = selectMetricsForCurrentMatcher()(store);

  return metrics && metrics.intervals ? metrics.intervals : [];
};

export const selectDaysBreakdownForCurrentMatcher = () => (store) => {
  const metrics = selectMetricsForCurrentMatcher()(store);

  return metrics && metrics.days_breakdown ? metrics.days_breakdown : [];
};
