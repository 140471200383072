import { createSlice } from '@reduxjs/toolkit';
import { prolongTimer } from './middleware';

// 1 min 10 seconds
const TIME_MS = 1 * 60 * 1000 + 10 * 1000;

const initialState = {
  timer: TIME_MS,
};

export const prolongSlice = createSlice({
  name: 'prolong',
  initialState,
  reducers: {
    resetTimer: (state) => {
      state.timer = TIME_MS;
    },
    tick: (state) => {
      state.timer = Math.max(0, state.timer - 1000);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(prolongTimer.fulfilled, (state) => {
      state.timer = TIME_MS;
    });
  },
});

export const {
  resetTimer,
  tick,
} = prolongSlice.actions;

export { prolongTimer };

export default prolongSlice.reducer;
