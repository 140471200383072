import React from 'react';
import PropTypes from 'prop-types';
import {
  Navbar, Container, Nav,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { BsBoxArrowInLeft } from 'react-icons/bs';

import ButtonWithIcon from '../ButtonWithIcon';

import useAuth from '../../hooks/useAuth';

import styles from './styles.module.css';

const AdminLayout = ({ children, contentStyle }) => {
  const { user, logOut } = useAuth();

  return (
    <div>
      <Navbar bg="light" className={styles.navbar}>
        <Container>
          <Nav>
            <Navbar.Brand>
              <Link to="/admin/home" className={styles.menuLinkItem}>
                Admin Panel
              </Link>
            </Navbar.Brand>
            <Link to="/admin/matchers" className={styles.menuLinkItem}>
              Matchers
            </Link>
            <Link to="/admin/inspection" className={styles.menuLinkItem}>
              Secondary Inspection
            </Link>
          </Nav>
          <Nav>
            <Nav.Link disabled>{user.name}</Nav.Link>
            <ButtonWithIcon
              text="Log out"
              variant="danger"
              onClick={logOut}
              Icon={<BsBoxArrowInLeft />}
            />
          </Nav>
        </Container>
      </Navbar>
      <div
        className={styles.pageContent}
        style={contentStyle}
      >
        {children}
      </div>
    </div>
  );
};

AdminLayout.propTypes = {
  children: PropTypes.node,
  contentStyle: PropTypes.shape(),
};

AdminLayout.defaultProps = {
  children: null,
  contentStyle: {},
};

export default AdminLayout;
