import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { BsBackspace } from 'react-icons/bs';

import ButtonWithIcon from '../ButtonWithIcon';

const BackButton = ({ href }) => {
  const history = useHistory();

  const onClick = () => {
    if (href) {
      history.push(href);
    } else {
      history.goBack();
    }
  };

  return (
    <ButtonWithIcon
      Icon={<BsBackspace />}
      text="Back"
      onClick={onClick}
    />
  );
};

BackButton.propTypes = {
  href: PropTypes.string,
};

BackButton.defaultProps = {
  href: null,
};

export default BackButton;
