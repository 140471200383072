import { configureStore } from '@reduxjs/toolkit';

import templatesReducer from './templates';
import editableTemplateReducer from './editableTemplate';
import templateItemsReducer from './templateItems';
import dictionariesReducer from './dictionaries';
import searchFeedReducer from './searchFeed';
import productsFeedReducer from './productsFeed';
import mismatchedSearchFeedReducer from './mismatchedSearchFeed';
import mismatchedProductsFeedReducer from './mismatchedProductsFeed';
import feedbackSearchFeedReducer from './feedbackSearchFeed';
import feedbackProductsFeedReducer from './feedbackProductsFeed';
import columnsReducer from './columns';
import toastsReducer from './toasts';
import matchersReducer from './matchers';
import prolongReducer from './prolong';

const store = configureStore({
  reducer: {
    templates: templatesReducer,
    editableTemplate: editableTemplateReducer,
    templateItems: templateItemsReducer,
    dictionaries: dictionariesReducer,
    searchFeed: searchFeedReducer,
    productsFeed: productsFeedReducer,
    mismatchedSearchFeed: mismatchedSearchFeedReducer,
    mismatchedProductsFeed: mismatchedProductsFeedReducer,
    feedbackSearchFeed: feedbackSearchFeedReducer,
    feedbackProductsFeed: feedbackProductsFeedReducer,
    columns: columnsReducer,
    toasts: toastsReducer,
    matchers: matchersReducer,
    prolong: prolongReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: {
      ignoredActionPaths: ['payload.config', 'payload.request', 'meta.arg.image'],
    },
  }),
});

export default store;
