import React from 'react';
import { useSelector } from 'react-redux';

import { getCurrentItemNumnder } from '../../store/mismatchedProductsFeed/selectors';

const MismatchedItemsAmountRight = () => {
  const total = useSelector((store) => store.mismatchedProductsFeed.totalProducts);
  const finished = useSelector(getCurrentItemNumnder);

  return <h3>{`${finished}/${total}`}</h3>;
};

export default MismatchedItemsAmountRight;
