import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { fetchSearchFeed } from '../../store/searchFeed';

import { getScrollItemsFromSearchFeedItems } from '../../utils/options';
import { REFETCH_TEMPLATE_ITEMS_MS } from '../../utils/refresh';

import ItemsScroll from '../ItemsScroll';

const TemplateItemsScroll = () => {
  const dispatch = useDispatch();
  const items = useSelector((store) => store.searchFeed.items);
  const currentItem = useSelector((store) => store.searchFeed.currentItem);

  useEffect(() => {
    dispatch(fetchSearchFeed());

    const interval = setInterval(() => {
      dispatch(fetchSearchFeed());
    }, REFETCH_TEMPLATE_ITEMS_MS);

    return () => clearInterval(interval);
  }, [dispatch]);

  return (
    <ItemsScroll
      items={getScrollItemsFromSearchFeedItems(items)}
      currentItemId={currentItem ? currentItem.id : null}
    />
  );
};

export default TemplateItemsScroll;
