import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  nextItem: false,
};

export const toastsSlice = createSlice({
  name: 'toasts',
  initialState,
  reducers: {
    toggleNextItemToast: (state) => {
      state.nextItem = !state.nextItem;
    },
  },
});

export const { toggleNextItemToast } = toastsSlice.actions;

export default toastsSlice.reducer;
