import React from 'react';
import {
  BarChart, Bar, YAxis, XAxis,
} from 'recharts';
import { useSelector } from 'react-redux';
import { Container } from 'react-bootstrap';

import { selectDaysBreakdownForCurrentMatcher } from '../../store/matchers/selectors';

import { formatForChart } from '../../utils/date';

const MatchersHoursPerDayChart = () => {
  const daysBreakdown = useSelector(selectDaysBreakdownForCurrentMatcher());
  const selectedMatcher = useSelector((store) => store.matchers.selectedMatcher);

  if (!daysBreakdown || !daysBreakdown.length) {
    return null;
  }

  const data = daysBreakdown.map((dayBreakdown) => ({
    name: formatForChart(dayBreakdown.day),
    amt: dayBreakdown.seconds,
  }));

  return (
    <Container className="mt-5">
      <h4>{`Hours by Day for ${selectedMatcher.name}`}</h4>
      <BarChart width={650} height={300} data={data}>
        <Bar dataKey="amt" fill="#8884d8" />
        <XAxis dataKey="name" />
        <YAxis />
      </BarChart>
    </Container>
  );
};

export default MatchersHoursPerDayChart;
