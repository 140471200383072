export const KEYS = {
  SPACE: 'Space',
  KEY_A: 'KeyA',
  KEY_S: 'KeyS',
  KEY_D: 'KeyD',
  ARROW_RIGHT: 'ArrowRight',
  ARROW_LEFT: 'ArrowLeft',
  ARROW_DOWN: 'ArrowDown',
  ARROW_UP: 'ArrowUp',
  ENTER: 'Enter',
  TAB: 'Tab',
  BACKSPACE: 'Backspace',
  BACKSLASH: 'Backslash',
};
