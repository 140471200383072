import React from 'react';

import MatchLayout from '../../components/MatchLayout';
import TemplatesSection from '../../components/TemplatesSection';
import BulkProductsSection from '../../components/BulkProductsSection';
import ProlongPopup from '../../components/ProlongPopup';
import BulkMatchPageKeyboardKeysProvider from '../../components/BulkMatchPageKeyboardKeysProvider';

import { setNoMoreItems } from '../../store/searchFeed';

const MatcherBulkMatchPage = () => (
  <BulkMatchPageKeyboardKeysProvider>
    <ProlongPopup />
    <MatchLayout
      leftSideComponent={<BulkProductsSection />}
      rightSideComponent={<TemplatesSection />}
      finalModalOptions={{
        title: "Great job! You've matched all products!",
        button: 'Back to Dashboard',
        selector: (store) => store.searchFeed.noMoreItems,
        action: () => setNoMoreItems(false),
      }}
    />
  </BulkMatchPageKeyboardKeysProvider>
);

export default MatcherBulkMatchPage;
