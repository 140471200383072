export const NEXT_IMAGES_AMOUNT = 15;

const imagesQueue = {};

export const prefetchImagesWithoutCache = (urls) => {
  urls.forEach((src) => {
    const img = new Image();
    img.src = src;
  });
};

export const prefetchImagesByUrls = (urls) => {
  const toRemove = Object.keys(imagesQueue).filter((src) => !urls.includes(src));
  toRemove.forEach((src) => {
    imagesQueue[src].src = '';
    delete imagesQueue[src];
  });
  urls.forEach((src) => {
    if (imagesQueue[src]) {
      return;
    }

    const img = new Image();
    imagesQueue[src] = img;
    img.src = src;
  });
};

export const getNextImages = (items, currentItem) => {
  const images = [];
  const currentIndex = items.findIndex((i) => currentItem.id === i.id);

  for (let i = 0; i < NEXT_IMAGES_AMOUNT; i += 1) {
    const nextItem = items[currentIndex + i];
    if (nextItem && nextItem.medium_images && nextItem.medium_images[0]) {
      images.push(nextItem.medium_images[0]);
    } else {
      break;
    }

    if (nextItem && nextItem.images) {
      nextItem.images.forEach((image) => {
        images.push(image);
      });
    }
  }

  return images;
};
