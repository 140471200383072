import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { createTemplate } from '../../store/templates';
import { resetLocal } from '../../store/editableTemplate';

import AdminLayout from '../../components/AdminLayout';
import EditTemplateForm from '../../components/EditTemplateForm';

const AdminCreateTemplatePage = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(resetLocal());
  }, [dispatch]);

  return (
    <AdminLayout>
      <EditTemplateForm
        onCreate={async (name, dueDate, submissionDate) => {
          const newTemplate = await dispatch(createTemplate({ name, dueDate, submissionDate }))
            .unwrap();
          history.push(`/admin/templates/${newTemplate.id}`);
        }}
      />
    </AdminLayout>
  );
};

export default AdminCreateTemplatePage;
