import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import dayjs from 'dayjs';
import { Form, OverlayTrigger, Tooltip } from 'react-bootstrap';

import styles from './styles.module.css';

const regexp = /[\d.]/g;
const MAX_PRICE = 999999.99;

const PriceInput = ({ value, updatedAt, ...rest }) => {
  const input = useRef();

  const onKeyPress = (e) => {
    if (!e.key.match(regexp)) {
      e.preventDefault();
      return false;
    }

    const nextValue = e.target.value * 10 + Number.parseInt(e.key, 10);
    if (nextValue > MAX_PRICE) {
      e.preventDefault();
      return false;
    }

    return true;
  };

  const formatedUpdatedAt = `Last updated: ${dayjs(updatedAt).format('MMM DD, YYYY h:mma')}`;
  const isRecentlyUpdated = dayjs(updatedAt).isAfter(dayjs(new Date()).subtract(5, 'days'));

  return (
    <div className={styles.wrapper}>
      <OverlayTrigger placement="top" overlay={updatedAt ? <Tooltip>{formatedUpdatedAt}</Tooltip> : <span />}>
        <div>
          <Form.Control
            size="sm"
            type="number"
            min="0"
            max={MAX_PRICE}
            step="0.01"
            value={value}
            ref={input}
            {...rest}
            onKeyPress={onKeyPress}
            className={cx(isRecentlyUpdated && styles.recentlyUpdated)}
          />
        </div>
      </OverlayTrigger>
    </div>
  );
};

PriceInput.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.number,
  updatedAt: PropTypes.string,
};

PriceInput.defaultProps = {
  onChange: null,
  value: 0,
  updatedAt: null,
};

export default PriceInput;
