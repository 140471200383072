import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import SeparationLine from '../SeparationLine';
import NoMoreItemsModal from '../NoMoreItemsModal';
import NextItemToast from '../NextItemToast';

import styles from './styles.module.css';

const MatchLayout = ({
  leftSideComponent,
  rightSideComponent,
  finalModalOptions,
  withHeader,
}) => (
  <div className={cx(styles.container, withHeader && styles.withHeader)}>
    <div className={styles.side}>
      {leftSideComponent}
    </div>
    <SeparationLine />
    <div className={styles.side}>
      {rightSideComponent}
    </div>

    {finalModalOptions && <NoMoreItemsModal {...finalModalOptions} /> }

    <NextItemToast />
  </div>
);

MatchLayout.propTypes = {
  leftSideComponent: PropTypes.node.isRequired,
  rightSideComponent: PropTypes.node.isRequired,
  finalModalOptions: PropTypes.shape({
    title: PropTypes.string.isRequired,
    button: PropTypes.string.isRequired,
    selector: PropTypes.func.isRequired,
    action: PropTypes.func.isRequired,
  }),
  withHeader: PropTypes.bool,
};

MatchLayout.defaultProps = {
  withHeader: false,
  finalModalOptions: null,
};

export default MatchLayout;
