import React from 'react';

import SectionHeader from '../SectionHeader';
import TemplateNote from '../TemplateNote';
import SectionBodyLayout from '../SectionBodyLayout';
import FeedbackProductsScroll from '../FeedbackProductsScroll';
import FeedbackProductMainImage from '../FeedbackProductMainImage';
import BackButton from '../BackButton';

const ProductsFeedbackSection = () => (
  <>
    <SectionHeader leftSideComponent={<BackButton href="/matcher/home" />}>
      <TemplateNote selector={(store) => store.feedbackProductsFeed.currentItem} />
    </SectionHeader>
    <SectionBodyLayout listPosition="left" listComponent={<FeedbackProductsScroll />}>
      <FeedbackProductMainImage />
    </SectionBodyLayout>
  </>
);

export default ProductsFeedbackSection;
