import { createSlice } from '@reduxjs/toolkit';

import {
  fetchSearchFeed,
  finalizeCurrentTemplateItem,
} from './middleware';

const initialState = {
  items: [],
  currentItem: null,
  noMoreItems: false,
  mainImageIndex: 0,
};

export const searchFeedSlice = createSlice({
  name: 'searchFeed',
  initialState,
  reducers: {
    markCurrentItemAsFinished: (state) => {
      state.items.find((item) => item.id === state.currentItem.id).finished = true;
      const nextItem = state.items.find((item) => !item.finished);
      if (nextItem) {
        state.currentItem = nextItem;
        state.mainImageIndex = 0;
        state.items = state.items.filter((it) => !it.finished);
      } else {
        state.noMoreItems = true;
      }
    },
    setNoMoreItems: (state, action) => {
      state.noMoreItems = action.payload;
    },
    setMainImageIndex: (state, action) => {
      const index = action.payload;

      if (state.currentItem && state.currentItem.images[index]) {
        state.mainImageIndex = index;
      }
    },
    resetItems: (state) => {
      state.items = [];
      state.currentItem = null;
      state.noMoreItems = false;
      state.mainImageIndex = 0;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSearchFeed.fulfilled, (state, action) => {
      const unfinishedItems = action.payload.filter((it) => !it.finished);
      state.items = unfinishedItems;
      if (!state.currentItem || !action.payload.find((it) => it.id === state.currentItem.id)) {
        const [first] = unfinishedItems;
        state.currentItem = first;
      }

      if (!state.currentItem) {
        state.noMoreItems = true;
        state.items = [];
        state.currentItem = null;
      }
    });
  },
});

export {
  fetchSearchFeed,
  finalizeCurrentTemplateItem,
};

export const {
  markCurrentItemAsFinished,
  setNoMoreItems,
  setMainImageIndex,
  resetItems,
} = searchFeedSlice.actions;

export default searchFeedSlice.reducer;
