import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { InputGroup, OverlayTrigger, Tooltip } from 'react-bootstrap';

import styles from './styles.module.css';
import ButtonWithIcon from '../../ButtonWithIcon';

const ImageUploaderInputGroup = ({
  buttons,
  disabled,
  withImage,
}) => (
  <div className={styles.container}>
    <InputGroup
      size="sm"
      disabled={disabled}
      className={cx(
        'flex-nowrap',
        styles.inputGroup,
        withImage && styles.inputGroupWithImage,
        disabled && styles.inputGroupDisabled,
      )}
    >
      {buttons.filter((btn) => btn).map((btn) => (
        <ButtonWithIcon
          key={btn.hint}
          variant="outline-secondary"
          onClick={btn.onClick}
          disabled={disabled}
          tabindex="-1"
          Icon={(
            <OverlayTrigger placement="top" overlay={<Tooltip>{btn.hint}</Tooltip>}>
              <div>{btn.icon}</div>
            </OverlayTrigger>
          )}
        />
      ))}
    </InputGroup>
  </div>
);

ImageUploaderInputGroup.propTypes = {
  disabled: PropTypes.bool,
  withImage: PropTypes.bool,
  buttons: PropTypes.arrayOf(PropTypes.shape({
    hint: PropTypes.string.isRequired,
    icon: PropTypes.node.isRequired,
    onClick: PropTypes.func.isRequired,
  })).isRequired,
};

ImageUploaderInputGroup.defaultProps = {
  disabled: false,
  withImage: false,
};

export default ImageUploaderInputGroup;
