import React from 'react';
import { PropTypes } from 'prop-types';
import cx from 'classnames';

import { BsArrowUpSquare, BsArrowDownSquare, BsArrowDownCircle } from 'react-icons/bs';
import { IconContext } from 'react-icons';

import styles from './styles.module.css';

const BulkArrows = ({
  onUp,
  onDown,
  disableUp,
  disableDown,
  canDoubleSkip,
  onDoubleSkip,
}) => (
  <div className={styles.container}>
    <IconContext.Provider value={{ size: 70 }}>
      <BsArrowUpSquare className={cx(styles.icon, disableUp && styles.disabled)} onClick={onUp} />
      <BsArrowDownSquare
        className={cx(styles.icon, disableDown && styles.disabled)}
        onClick={onDown}
      />
    </IconContext.Provider>
    {canDoubleSkip && (
      <BsArrowDownCircle
        className={cx(styles.icon, styles.secondary)}
        size={40}
        onClick={onDoubleSkip}
      />
    )}
  </div>
);

BulkArrows.propTypes = {
  onUp: PropTypes.func.isRequired,
  onDown: PropTypes.func.isRequired,
  onDoubleSkip: PropTypes.func.isRequired,
  disableUp: PropTypes.bool,
  disableDown: PropTypes.bool,
  canDoubleSkip: PropTypes.bool,
};

BulkArrows.defaultProps = {
  disableUp: false,
  disableDown: false,
  canDoubleSkip: false,
};

export default BulkArrows;
