import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Form } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';

import {
  updateItem,
  deleteItem,
  deleteItemEverywhere,
  uploadImage,
  deleteImage,
  resetImages,
  revertItem,
  setCurrentItemId,
  setImageSidebarFocus,
  setItemChanges,
} from '../../../store/templateItems';

import { getOptionsFromStrings } from '../../../utils/options';
import { toggleWord } from '../../../utils/words';
import { KEYS } from '../../../utils/keycode';
import { itemNotEmpty } from '../../../utils/validate';
import { roundWithDecimal, addCommasToThousands } from '../../../utils/format';
import { withDebounce } from '../../../utils/debounce';

import DropdownInput from '../../DropdownInput';
import CompleteButtonWithModal from '../../CopleteButtonWithModal';
import ImageUploader from '../../ImageUploader';
import MatchingItemsCounter from '../../MatchingItemsCounter';
import PriceInput from '../../PriceInput';
import ViewMatchesButton from '../../ViewMatchesButton';
import TemplateItemNameInput from '../../TemplateItemNameInput';
import RevertButton from '../../RevertButton';
import ColorizedInput from '../../ColorizedInput';
import InputWithLock from '../../InputWithLock';
import ViewQueryButton from '../../ViewQueryButton';
import DeleteButtonWithDropdown from '../../DeleteButtonWithDropdown';

import styles from '../styles.module.css';

const ExistingTemplateItemRow = ({
  item,
  onItemCompleted,
  withoutDelete,
  idx,
  last,
  rawIndex,
}) => {
  const dispatch = useDispatch();

  const { brands, colors, categories } = useSelector((store) => store.dictionaries);
  const columns = useSelector((store) => store.columns.items);
  const {
    percent, percent2, percent3, percent4, currency,
  } = useSelector((store) => store.editableTemplate);
  const currentItemId = useSelector((store) => store.templateItems.currentItemId);
  const currentItemChanges = useSelector((store) => {
    if (currentItemId === item.id) {
      return store.templateItems.currentItemChanges;
    }
    return null;
  });

  const getFieldValue = (
    fieldName,
    defaultValue = '',
  ) => currentItemChanges?.[fieldName] ?? (item[fieldName] || defaultValue);

  const itemHasSnapshot = getFieldValue('snapshot_taken_at', null) !== null;
  const itemHasChanges = currentItemChanges && Object.keys(currentItemChanges)
    .filter((it) => it !== 'count').length > 0;
  const locked = itemHasSnapshot || getFieldValue('completed', false);
  const itemHasImages = getFieldValue('template_images', []).length > 0 || getFieldValue('product_images', []).length > 0;
  const doubleLocked = itemHasImages && !getFieldValue('completed', false);

  const onArbitraryInputChange = useCallback(async (value, field) => {
    await dispatch(updateItem({ id: item.id, field, value }));
  }, [item, dispatch]);

  const onArbitraryWithDebounceChange = useCallback(withDebounce(async (value, field) => {
    await dispatch(updateItem({ id: item.id, field, value }));
    await dispatch(setItemChanges({ id: item.id, field, value }));
  }), [item, dispatch, last]);

  const onTextInputChange = async (e) => {
    const { value } = e.target;
    const field = e.target.getAttribute('field');
    await dispatch(setItemChanges({ id: item.id, field, value }));
    await onArbitraryWithDebounceChange(value, field);
  };
  const onMultiselectChange = useCallback(withDebounce(async (options, field) => {
    await dispatch(setItemChanges({ id: item.id, field, value: options.map((o) => o.value) }));
    await onArbitraryInputChange(options.map((o) => o.value), field);
  }), [onArbitraryInputChange]);

  const onComplete = async (value, field) => {
    await dispatch(setItemChanges({ id: item.id, field, value }));
    await onArbitraryInputChange(value, field);

    if (value) {
      onItemCompleted();
    }

    return true;
  };

  const onKeyPress = async (e) => {
    if (e.code === KEYS.ENTER) {
      e.target.blur();
      await onComplete();
    } else if (e.code === KEYS.TAB) {
      e.preventDefault();
      e.target.blur();
      dispatch(setImageSidebarFocus(true));
    }
  };

  const onAddWordToName = async (word) => {
    const name = currentItemChanges?.name ?? (item.name || '');
    const newName = toggleWord(name, word);
    await dispatch(updateItem({
      id: item.id,
      value: newName,
      field: 'name',
    }));
    await dispatch(setItemChanges({
      id: item.id,
      value: newName,
      field: 'name',
    }));
  };

  const onFocus = () => {
    dispatch(setCurrentItemId({ id: item.id }));
  };

  return (
    <tr>
      <td className={cx(!columns.number && styles.disabledColumn, locked && styles.disabledRow)}>
        <p>{idx + 1}</p>
      </td>
      <td className={cx(!columns.sku && styles.disabledColumn, locked && styles.disabledRow)}>
        <InputWithLock enabled={doubleLocked}>
          <Form.Control
            size="sm"
            field="sku"
            id={`sku-${item.id}`}
            value={getFieldValue('sku')}
            required
            onChange={onTextInputChange}
            onKeyPress={onKeyPress}
            onFocus={onFocus}
          />
        </InputWithLock>
      </td>
      <td className={cx(
        !columns.manual_price && styles.disabledColumn,
      )}
      >
        <InputWithLock enabled={locked}>
          <PriceInput
            field="manual_price"
            updatedAt={item.price_updated_at}
            onChange={onTextInputChange}
            onKeyPress={onKeyPress}
            value={getFieldValue('manual_price')}
            onFocus={onFocus}
            id={`price-${item.id}`}
          />
        </InputWithLock>
        {[percent, percent2, percent3, percent4].map((p) => p > 0 && (
          <div className="text-secondary">
            <small>
              {addCommasToThousands(roundWithDecimal(getFieldValue('manual_price') * (p / 100) * currency))}
            </small>
          </div>
        ))}
      </td>
      <td className={cx(!columns.image && styles.disabledColumn)}>
        <ImageUploader
          onUpload={(image) => dispatch(uploadImage({ id: item.id, image }))}
          onDelete={(imageId) => dispatch(deleteImage({ id: item.id, imageId }))}
          onReset={() => dispatch(resetImages(item.id))}
          templateImages={item.template_images}
          productImages={item.product_images}
          disabled={locked}
        />
      </td>
      <td className={cx(!columns.brands && styles.disabledColumn, locked && styles.disabledRow)}>
        <div>
          <InputWithLock enabled={doubleLocked}>
            <DropdownInput
              id={`brands-${item.id}`}
              options={brands}
              initialValue={getOptionsFromStrings(getFieldValue('brands', []))}
              onChange={onMultiselectChange}
              name="brands"
              field="brands"
              onFocus={onFocus}
            />
          </InputWithLock>
          {Number.isInteger(getFieldValue('count', 0)) && (
            <MatchingItemsCounter counter={getFieldValue('count', 0)} className={styles.counter} />
          )}
        </div>
      </td>
      <td
        className={cx(!columns.name && styles.disabledColumn, locked && styles.disabledRow)}
        style={{ minWidth: 110 }}
      >
        <InputWithLock enabled={doubleLocked}>
          <TemplateItemNameInput
            onWordClick={onAddWordToName}
            itemId={item.id}
            id={`name-${item.id}`}
            input={getFieldValue('name')}
            options={item.nameOptions || []}
            brands={
              getOptionsFromStrings(getFieldValue('brands', [])).map((it) => it.value).join(';')
            }
            onChange={onArbitraryWithDebounceChange}
            onKeyPress={onKeyPress}
            field="name"
            completed={getFieldValue('completed', false)}
            isNotEmpty={itemNotEmpty(item, currentItemChanges)}
            short={rawIndex === 0 || rawIndex === 1}
            onFocus={onFocus}
          />
        </InputWithLock>
      </td>
      <td className={cx(!columns.colors && styles.disabledColumn, locked && styles.disabledRow)}>
        <InputWithLock enabled={doubleLocked}>
          <DropdownInput
            id={`colors-${item.id}`}
            options={colors}
            initialValue={getOptionsFromStrings(getFieldValue('colors', []))}
            onChange={onMultiselectChange}
            name="colors"
            field="colors"
            onFocus={onFocus}
          />
        </InputWithLock>
      </td>
      <td className={cx(
        !columns.description && styles.disabledColumn,
        locked && styles.disabledRow,
      )}
      >
        <InputWithLock enabled={doubleLocked}>
          <ColorizedInput
            size="sm"
            value={getFieldValue('description')}
            required
            field="description"
            onChange={onTextInputChange}
            onKeyPress={onKeyPress}
            onFocus={onFocus}
            id={`description-${item.id}`}
          />
        </InputWithLock>
      </td>
      <td className={cx(
        !columns.categories && styles.disabledColumn,
        locked && styles.disabledRow,
      )}
      >
        <InputWithLock enabled={doubleLocked}>
          <DropdownInput
            id={`categories-${item.id}`}
            options={categories}
            initialValue={getOptionsFromStrings(
              getFieldValue('categories', []),
              categories,
            )}
            onChange={onMultiselectChange}
            name="categories"
            field="categories"
            onFocus={onFocus}
          />
        </InputWithLock>
      </td>
      <td className={cx(!columns.fabric && styles.disabledColumn, locked && styles.disabledRow)}>
        <InputWithLock enabled={doubleLocked}>
          <ColorizedInput
            size="sm"
            field="fabric"
            value={getFieldValue('fabric')}
            required
            onChange={onTextInputChange}
            onKeyPress={onKeyPress}
            onFocus={onFocus}
            id={`fabric-${item.id}`}
          />
        </InputWithLock>
      </td>
      <td className={cx(!columns.note && styles.disabledColumn, locked && styles.disabledRow)}>
        <InputWithLock enabled={doubleLocked}>
          <Form.Control
            size="sm"
            field="note"
            value={getFieldValue('note')}
            required
            onChange={onTextInputChange}
            onKeyDown={onKeyPress}
            onFocus={onFocus}
            id={`note-${item.id}`}
            className={styles.noteInput}
          />
        </InputWithLock>
      </td>
      <td>
        {!withoutDelete && (
          <DeleteButtonWithDropdown
            onDelete={() => dispatch(deleteItem(item.id))}
            onDeleteEverywhere={() => dispatch(deleteItemEverywhere(item))}
          />
        )}
      </td>
      {!itemHasSnapshot && itemHasImages && (
        <td>
          <CompleteButtonWithModal
            value={getFieldValue('completed')}
            onChange={async (field, value) => {
              await onFocus();
              await onComplete(field, value);
            }}
            field="completed"
          />
        </td>
      )}
      {item.view_matches && (
        <td>
          <ViewMatchesButton id={item.id} />
        </td>
      )}
      {!last && (
        <td>
          <ViewQueryButton id={item.id} />
        </td>
      )}
      {!last && !locked && (
        <td>
          <RevertButton
            onClick={() => dispatch(revertItem({ id: item.id }))}
            disabled={!itemHasChanges}
          />
        </td>
      )}
    </tr>
  );
};

ExistingTemplateItemRow.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number.isRequired,
    template_id: PropTypes.number.isRequired,
    sku: PropTypes.string,
    brands: PropTypes.arrayOf(PropTypes.string).isRequired,
    name: PropTypes.string,
    description: PropTypes.string,
    colors: PropTypes.arrayOf(PropTypes.string).isRequired,
    categories: PropTypes.arrayOf(PropTypes.string).isRequired,
    fabric: PropTypes.string,
    note: PropTypes.string,
    completed: PropTypes.bool.isRequired,
    product_images: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
      url: PropTypes.string.isRequired,
    })).isRequired,
    template_images: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
      url: PropTypes.string.isRequired,
    })).isRequired,
    count: PropTypes.number,
    nameOptions: PropTypes.arrayOf(PropTypes.string),
    snapshot_taken_at: PropTypes.string,
    duplicate_id: PropTypes.number,
    manual_price: PropTypes.number,
    view_matches: PropTypes.bool.isRequired,
    price_updated_at: PropTypes.string,
  }).isRequired,
  idx: PropTypes.number,
  onItemCompleted: PropTypes.func.isRequired,
  withoutDelete: PropTypes.bool,
  last: PropTypes.bool.isRequired,
};

ExistingTemplateItemRow.defaultProps = {
  withoutDelete: false,
  idx: 0,
};

export default ExistingTemplateItemRow;
