import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _groupBy from 'lodash/groupBy';
import {
  Table, Container, Alert,
} from 'react-bootstrap';

import {
  getMatchers,
  updateMatcher,
  deleteMatcher,
  createMatcher,
  getMetrics,
} from '../../store/matchers';

import ExistingMatcherRow from './ExistingMatcherRow';
import NewMatcherRow from './NewMatcherRow';

const MatchersTable = () => {
  const dispatch = useDispatch();
  const matchers = useSelector((store) => store.matchers.matchers);
  const metrics = useSelector((store) => store.matchers.metrics);
  const error = useSelector((store) => store.matchers.error);

  const metricsByMatcherId = _groupBy(metrics, 'user_id');

  useEffect(() => {
    dispatch(getMatchers());
    dispatch(getMetrics());
  }, [dispatch]);

  const onCreate = async (name, password, enabled) => {
    dispatch(createMatcher({ name, password, enabled }));
  };

  const onDelete = (id) => {
    dispatch(deleteMatcher(id));
  };

  const onUpdate = (id, field, newValue) => {
    dispatch(updateMatcher({ id, field, newValue }));
  };

  return (
    <Container>
      <Table size="sm">
        <thead>
          <tr>
            <th>id</th>
            <th>name</th>
            <th>password</th>
            <th>enabled</th>
            <th>ACS</th>
            <th>MCS</th>
            <th>Time</th>
            <th>Errors</th>
            <th>action</th>
          </tr>
        </thead>
        <tbody>
          {matchers.map((matcher) => (
            <ExistingMatcherRow
              matcher={matcher}
              metrics={(metricsByMatcherId[matcher.id] || [])[0]}
              key={matcher.id}
              onUpdate={onUpdate}
              onDelete={onDelete}
            />
          ))}
          <NewMatcherRow onCreate={onCreate} formId="new-matcher" />
        </tbody>
      </Table>
      {error && <Alert variant="danger">{error}</Alert>}
    </Container>
  );
};

export default MatchersTable;
