import { createAsyncThunk, createAction } from '@reduxjs/toolkit';

import {
  getTemplateItems,
  createTemplateItem,
  updateTemplateItemById,
  deleteTemplateItemById,
  uploadImageToTemplateItem,
  chooseImageFromDB,
  deleteUploadedImage,
  unsetImageFromDb,
  copyFromSimilarTemplate,
  getItemImages,
  getSimilarImages,
  deleteTemplateItemByProductId,
  deleteTemplateItemsByImageGroupId,
} from '../../utils/api';
import { focusTemplateItemField } from '../../utils/focus';

export const EMPTY_ITEM = {
  sku: '',
  brands: [],
  name: '',
  description: '',
  colors: [],
  categories: [],
  fabric: '',
  note: '',
  completed: false,
  snapshot_taken_at: null,
  duplicate_id: null,
  price_updated_at: null,
};

export const fetchTemplateItems = createAsyncThunk(
  'templateItems/fetchTemplateItems',
  (id) => getTemplateItems(id),
);

export const createItem = createAsyncThunk(
  'templateItems/createItem',
  async (_, { getState }) => {
    const { templateItems: { newItem }, editableTemplate } = getState();
    return createTemplateItem(editableTemplate.id, newItem);
  },
);

export const fetchItemImages = createAsyncThunk(
  'templateItems/fetchItemImages',
  async ({ id, offset }) => {
    const [response] = await getItemImages(id, offset);

    return ({
      images: response ?? [],
      imagesOffset: offset + (response?.length ?? 0),
      imagesMore: response?.length > 0,
      fromOffset: offset,
    });
  },
);

export const fetchSimilarImages = createAsyncThunk(
  'templateItems/fetchSimilarImages',
  async ({ id }) => {
    const response = await getSimilarImages(id);

    return {
      similarImages: response,
    };
  },
);

export const updateItem = createAsyncThunk(
  'templateItems/updateItem',
  (
    payload,
    { getState, dispatch },
  ) => {
    const { id, field, value } = payload;
    const { templateItems: { items, currentItemChanges }, editableTemplate } = getState();
    const itemToUpdate = items.find((i) => i.id === id);
    if (itemToUpdate) {
      const newItem = { ...itemToUpdate, ...currentItemChanges, [field]: value };
      updateTemplateItemById(
        editableTemplate.id,
        itemToUpdate.id,
        newItem,
      ).then((item) => {
        if (item) {
          dispatch(createAction('templateItems/updateItemLocal')({
            item,
            field,
          }));
          dispatch(fetchItemImages({
            id: item.id,
            offset: 0,
          }));
          dispatch(fetchSimilarImages({
            id: item.id,
          }));
        }
      });
    }
    return payload;
  },
);

export const revertItem = createAsyncThunk(
  'templateItems/revertItem',
  (payload, { getState, dispatch }) => {
    const { id } = payload;
    const { templateItems: { items }, editableTemplate } = getState();
    const itemToRevert = items.find((it) => it.id === id);
    if (itemToRevert) {
      updateTemplateItemById(
        editableTemplate.id,
        itemToRevert.id,
        itemToRevert,
      ).then((item) => {
        dispatch(createAction('templateItems/updateItemLocal')({
          item,
          id,
          force: true,
        }));
      });
    }
  },
);

export const deleteItem = createAsyncThunk(
  'templateItems/deleteItem',
  async (id, { getState }) => {
    const { editableTemplate } = getState();
    await deleteTemplateItemById(editableTemplate.id, id);
    return id;
  },
);

export const deleteItemEverywhere = createAsyncThunk(
  'templateItems/deleteItemEverywhere',
  async (item) => {
    if (item.product_id) {
      await deleteTemplateItemByProductId(item.product_id);
    }

    if (item.template_item_image_group_id) {
      await deleteTemplateItemsByImageGroupId(item.template_item_image_group_id);
    }

    return {
      product_id: item.product_id,
      template_item_image_group_id: item.template_item_image_group_id,
    };
  },
);

export const uploadImage = createAsyncThunk(
  'templateItems/uploadImage',
  async ({ id, image }) => uploadImageToTemplateItem(id, image),
);

export const focusFieldForNextUncompletedItem = (itemId, field) => async (_, getState) => {
  const { items } = getState().templateItems;
  const index = items.findIndex((item) => item.id === itemId);
  const nextUncompleted = items.find((item, idx) => idx > index && !item.completed);
  focusTemplateItemField(nextUncompleted.id, field);
};

const createEmptyItemWithFocus = async (dispatch, id) => {
  await dispatch(createItem(EMPTY_ITEM));
  dispatch(focusFieldForNextUncompletedItem(id, 'brands'));
  dispatch(createAction('templateItems/setImageSidebarFocus')(false));
};

export const chooseImage = createAsyncThunk(
  'templateItems/chooseImage',
  async ({ id, imageId }, { getState, dispatch }) => {
    const response = await chooseImageFromDB(id, imageId);
    const { items } = getState().templateItems;

    await dispatch(createAction('templateItems/mergeItemChanges')(response.id));

    if (response.completed && items[items.length - 1].id === response.id) {
      await createEmptyItemWithFocus(dispatch, response.id);
    }

    return response;
  },
);

export const deleteImage = createAsyncThunk(
  'templateItems/deleteImage',
  async ({ id, imageId }) => deleteUploadedImage(id, imageId),
);

export const resetImages = createAsyncThunk(
  'templateItems/resetImages',
  async (id) => unsetImageFromDb(id),
);

export const copyFromSimilar = createAsyncThunk(
  'templateItems/copyFromSimilar',
  async ({ id, similarId }, { getState, dispatch }) => {
    const response = await copyFromSimilarTemplate(id, similarId);
    const { items } = getState().templateItems;

    await dispatch(createAction('templateItems/mergeItemChanges')(response.id));

    if (response.completed && items[items.length - 1].id === response.id) {
      await createEmptyItemWithFocus(dispatch, response.id);
    }

    return response;
  },
);
