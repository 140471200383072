import axios from 'axios';
import { formatDateAsValue } from './date';

axios.defaults.withCredentials = true;

const HTTP_METHODS = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  DELETE: 'DELETE',
};

const BASE_API_URL = process.env.REACT_APP_API_URL;

const API_ROUTES = {
  CSRF: () => `${BASE_API_URL}/csrf-cookie`,
  LOGIN: () => `${BASE_API_URL}/login`,
  LOGOUT: () => `${BASE_API_URL}/logout`,
  GET_USER: () => `${BASE_API_URL}/user`,
  TEMPLATES: (id = '') => `${BASE_API_URL}/templates/${id}`,
  TEMPLATES_ITEMS: (templateId, itemId = '') => `${BASE_API_URL}/templates/${templateId}/items/${itemId}`,
  TEMPLATES_ITEMS_BY_PRODUCT_ID: (productId) => `${BASE_API_URL}/template-items-by-product-id/${productId}`,
  TEMPLATES_ITEMS_BY_IMAGE_GROUP_ID: (templateItemImageGroupId) => `${BASE_API_URL}/template-items-by-image-group-id/${templateItemImageGroupId}`,
  MATCHERS: (id = '') => `${BASE_API_URL}/matchers/${id}`,
  MATCHERS_METRICS: () => `${BASE_API_URL}/matchers/metrics`,
  DICTIONARIES: () => `${BASE_API_URL}/dictionaries`,
  TEMPLATE_ITEMS_AUTOCOMPLETE: () => `${BASE_API_URL}/template-items/autocomplete`,
  TEMPLATE_ITEMS_IMAGE: (id) => `${BASE_API_URL}/template-items/${id}/image`,
  TEMPLATE_ITEMS_IMAGES_SEARCH: (id, imageId = '') => `${BASE_API_URL}/template-items/${id}/search/${imageId}`,
  TEMPLATE_ITEMS_SIMILAR: (id) => `${BASE_API_URL}/template-items/${id}/similar`,
  TEMPLATE_ITEMS_COPY_SIMILAR: (id, similarId) => `${BASE_API_URL}/template-items/${id}/copy-similar/${similarId}`,
  TEMPLATE_ITEMS_IMAGE_ID: (id) => `${BASE_API_URL}/template-items/${id}/image-id`,
  TEMPLATE_ITEMS_IMAGES: (id, imageId) => `${BASE_API_URL}/template-items/${id}/images/${imageId}`,
  TEMPLATE_ITEMS_RESET_IMAGES: (id) => `${BASE_API_URL}/template-items/${id}/reset-images`,
  TEMPLATE_ITEMS_WORDS: (id) => `${BASE_API_URL}/template-items/${id}/words`,
  TEMPLATE_ITEMS_FINALIZE: (id) => `${BASE_API_URL}/template_items/${id}/finalize`,
  SEARCH_FEED: () => `${BASE_API_URL}/search-feed`,
  IMAGES_FEED: (id = '') => `${BASE_API_URL}/images-feed/${id}`,
  IMAGES_FEED_V2: (id = '') => `${BASE_API_URL}/images-feed2/${id}`,
  PRODUCTS_ACTION: (itemId, productId) => `${BASE_API_URL}/template_items/${itemId}/products/${productId}/action`,
  PRODUCTS_BULK_SKIP: (itemId) => `${BASE_API_URL}/template_items/${itemId}/bulk-skip`,
  MISMATCHES_SEARCH_FEED: () => `${BASE_API_URL}/mismatches/search-feed`,
  MISMATCHES_IMAGES_FEED: (id) => `${BASE_API_URL}/mismatches/images-feed2/${id}`,
  MISMATCHES_FINAL_MATCH: () => `${BASE_API_URL}/mismatches/final-match`,
  MISMATCHES_BULK_SKIP: (id) => `${BASE_API_URL}/mismatches/${id}/final-bulk-skip`,
  MISMATCHES_FINALIZE: (id) => `${BASE_API_URL}/mismatches/${id}/finalize`,
  FEEDBACK_SEARCH_FEED: () => `${BASE_API_URL}/feedback/search-feed`,
  FEEDBACK_IMAGES_FEED: (id) => `${BASE_API_URL}/feedback/images-feed/${id}`,
  START: () => `${BASE_API_URL}/start`,
  PROLONG: () => `${BASE_API_URL}/prolong`,
};

const mapListToQueryParam = (name, list) => list
  .map((item) => `&${name}[]=${item}`)
  .join('');

const unsafeApiCall = async (url, method = HTTP_METHODS.GET, data = undefined) => {
  const response = await axios({
    method,
    url,
    data,
  });

  return response?.data;
};

const apiCall = async (url, defaultValue = false, method = HTTP_METHODS.GET, data = undefined) => {
  try {
    const response = await axios({
      method,
      url,
      data,
    });
    return response.data || true;
  } catch (err) {
    return defaultValue;
  }
};

export const getCSRF = async () => {
  axios.defaults.xsrfCookieName = 'MATCHING-XSRF-TOKEN';
  await axios.get(API_ROUTES.CSRF());
};

export const login = (
  name,
  password,
) => apiCall(API_ROUTES.LOGIN(), false, HTTP_METHODS.POST, { name, password });

export const logOut = () => apiCall(API_ROUTES.LOGOUT(), true, HTTP_METHODS.POST);

export const getUser = () => apiCall(API_ROUTES.GET_USER(), null);

export const getTemplates = () => apiCall(API_ROUTES.TEMPLATES(), []);

export const getTemplateById = (id) => apiCall(API_ROUTES.TEMPLATES(id), null);

export const getTemplateItems = (id) => apiCall(API_ROUTES.TEMPLATES_ITEMS(id), []);

export const createTemplateItem = (
  id,
  newItem,
) => apiCall(API_ROUTES.TEMPLATES_ITEMS(id), null, HTTP_METHODS.POST, newItem);

export const updateTemplateItemById = (
  templateId,
  itemId,
  newItem,
) => apiCall(API_ROUTES.TEMPLATES_ITEMS(templateId, itemId), null, HTTP_METHODS.PUT, newItem);

export const deleteTemplateItemById = (
  templateId,
  itemId,
) => apiCall(API_ROUTES.TEMPLATES_ITEMS(templateId, itemId), false, HTTP_METHODS.DELETE);

export const deleteTemplateItemByProductId = (
  productId,
) => apiCall(API_ROUTES.TEMPLATES_ITEMS_BY_PRODUCT_ID(productId), null, HTTP_METHODS.DELETE);

export const deleteTemplateItemsByImageGroupId = (
  templateItemImageGroupId,
) => apiCall(
  API_ROUTES.TEMPLATES_ITEMS_BY_IMAGE_GROUP_ID(templateItemImageGroupId),
  null,
  HTTP_METHODS.DELETE,
);

export const deleteTemplateById = (
  id,
) => apiCall(API_ROUTES.TEMPLATES(id), false, HTTP_METHODS.DELETE);

export const updateTemplateById = (
  id,
  newTemplate,
) => apiCall(API_ROUTES.TEMPLATES(id), null, HTTP_METHODS.PUT, newTemplate);

export const createTemplate = (name, dueDate, submissionDate) => apiCall(
  API_ROUTES.TEMPLATES(),
  null,
  HTTP_METHODS.POST,
  { name, due_date: dueDate, submission_date: submissionDate },
);

export const getMatchers = () => apiCall(API_ROUTES.MATCHERS(), []);

export const updateMatcherById = (
  id,
  newMatcher,
) => apiCall(API_ROUTES.MATCHERS(id), false, HTTP_METHODS.PUT, newMatcher);

export const createMatcher = (
  name,
  password,
  enabled,
) => apiCall(API_ROUTES.MATCHERS(), false, HTTP_METHODS.POST, { name, password, enabled });

export const deleteMatcherById = (
  id,
) => apiCall(API_ROUTES.MATCHERS(id), false, HTTP_METHODS.DELETE);

export const getMetricsForAllMatchers = (
  from,
  to,
) => apiCall(
  `${API_ROUTES.MATCHERS_METRICS()}?date_from=${formatDateAsValue(from)}&date_to=${formatDateAsValue(to)}`,
  {},
);

export const getDictionaries = () => apiCall(API_ROUTES.DICTIONARIES(), null);

export const getAutocompleteValue = (inputValue, brands) => apiCall(
  `${API_ROUTES.TEMPLATE_ITEMS_AUTOCOMPLETE()}?query=${inputValue}${mapListToQueryParam('brands', brands)}`,
  [],
);

export const uploadImageToTemplateItem = (itemId, image) => {
  const body = new FormData();
  body.append('image', image);
  return apiCall(API_ROUTES.TEMPLATE_ITEMS_IMAGE(itemId), null, HTTP_METHODS.POST, body);
};

export const chooseImageFromDB = (itemId, imageId) => apiCall(
  API_ROUTES.TEMPLATE_ITEMS_IMAGE_ID(itemId),
  null,
  HTTP_METHODS.PUT,
  { image_id: imageId },
);

export const deleteUploadedImage = (itemId, imageId) => apiCall(
  API_ROUTES.TEMPLATE_ITEMS_IMAGES(itemId, imageId),
  null,
  HTTP_METHODS.DELETE,
);

export const unsetImageFromDb = (itemId) => apiCall(
  API_ROUTES.TEMPLATE_ITEMS_RESET_IMAGES(itemId),
  null,
  HTTP_METHODS.PUT,
);

export const getItemImages = (itemId, offset = 0) => apiCall(
  `${API_ROUTES.TEMPLATE_ITEMS_IMAGES_SEARCH(itemId)}?offset=${offset}`,
  [],
);

export const getSimilarImages = (itemId) => apiCall(
  `${API_ROUTES.TEMPLATE_ITEMS_SIMILAR(itemId)}`,
  [],
);

export const copyFromSimilarTemplate = (itemId, similarId) => apiCall(
  `${API_ROUTES.TEMPLATE_ITEMS_COPY_SIMILAR(itemId, similarId)}`,
  null,
  HTTP_METHODS.POST,
);

export const getTemplateItemWords = (
  itemId,
) => apiCall(API_ROUTES.TEMPLATE_ITEMS_WORDS(itemId), []);

export const getSearchFeed = () => apiCall(API_ROUTES.SEARCH_FEED(), []);

export const finilizeTemplateItem = (itemId) => apiCall(
  API_ROUTES.TEMPLATE_ITEMS_FINALIZE(itemId),
  null,
  HTTP_METHODS.POST,
);

export const getProductsFeed = (itemId, offset = 0, count = 20) => apiCall(
  `${API_ROUTES.IMAGES_FEED_V2(itemId)}?offset=${offset}&count=${count}`,
  [],
);

export const setMatchActionForProductThrowsError = (
  itemId,
  productId,
  action,
  merge,
) => unsafeApiCall(
  API_ROUTES.PRODUCTS_ACTION(itemId, productId),
  HTTP_METHODS.POST,
  { action, merge_approval: merge },
);

export const setBulkSkipActionForProductTrowsError = (
  itemId,
  productIds,
) => unsafeApiCall(
  API_ROUTES.PRODUCTS_BULK_SKIP(itemId),
  HTTP_METHODS.POST,
  { item_ids: productIds },
);

export const getMismatchedSearchFeed = () => apiCall(API_ROUTES.MISMATCHES_SEARCH_FEED(), []);

export const getMismatchedProductsFeed = (
  itemId,
) => apiCall(API_ROUTES.MISMATCHES_IMAGES_FEED(itemId), []);

export const setFinalMatch = (
  itemId,
  productId,
  action,
  note,
) => apiCall(API_ROUTES.MISMATCHES_FINAL_MATCH(), null, HTTP_METHODS.POST, {
  template_item_id: itemId,
  item_id: productId,
  action,
  note,
});

export const setBulkSkipMismatchedAction = (
  itemId,
  productIds,
) => apiCall(
  API_ROUTES.MISMATCHES_BULK_SKIP(itemId),
  null,
  HTTP_METHODS.POST,
  { item_ids: productIds },
);

export const finalizeMismatchTemplateItem = (
  itemId,
) => apiCall(
  API_ROUTES.MISMATCHES_FINALIZE(itemId),
  null,
  HTTP_METHODS.POST,
);

export const getFeedbackSearchFeed = () => apiCall(API_ROUTES.FEEDBACK_SEARCH_FEED(), []);

export const getFeedbackProductsFeed = (
  itemId,
) => apiCall(API_ROUTES.FEEDBACK_IMAGES_FEED(itemId), []);

export const eraseLastMatch = (
  itemId,
  productId,
) => apiCall(API_ROUTES.PRODUCTS_ACTION(itemId, productId), {}, HTTP_METHODS.DELETE);

export const sendStartEvent = () => apiCall(API_ROUTES.START(), {}, HTTP_METHODS.POST);

export const sendProlongEvent = () => apiCall(API_ROUTES.PROLONG(), {}, HTTP_METHODS.POST);
