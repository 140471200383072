import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { withDebounce } from '../../utils/debounce';

import { KEYS } from '../../utils/keycode';
import { EVENTS } from '../../utils/event';

import { fetchTemplateById, updateTemplateById } from '../../store/editableTemplate';
import {
  fetchTemplateItems,
  chooseImage,
  copyFromSimilar,
  setImageSidebarFocus,
} from '../../store/templateItems';
import { fetchDictionaries } from '../../store/dictionaries';
import { loadColumnsFromLocalStorage } from '../../store/columns';

import AdminLayout from '../../components/AdminLayout';
import EditTemplateTable from '../../components/EditTemplateTable';
import EditTemplateForm from '../../components/EditTemplateForm';
import Loader from '../../components/Loader';
import ImageSelectorSidebar from '../../components/ImageSelectorSidebar';

const AdminEditTemplatePage = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [loaded, setLoaded] = useState(false);
  const staticColumn = useSelector((store) => store.columns.items.static);
  const staticColumnMinimized = useSelector((store) => store.columns.options.minimizeStaticColumn);

  const onButtonPressed = (e) => {
    if (e.code === KEYS.BACKSLASH) {
      document.activeElement.blur();
      window.dispatchEvent(new Event(EVENTS.CLOSE_ALL_DROPDOWNS));
      dispatch(setImageSidebarFocus(true));
      e.preventDefault();
      return false;
    }

    return true;
  };

  useEffect(() => {
    const init = async () => {
      await dispatch(loadColumnsFromLocalStorage());
      await dispatch(fetchTemplateById(id));
      await dispatch(fetchTemplateItems(id));
      await dispatch(fetchDictionaries());
      window.addEventListener('keypress', onButtonPressed);
      setLoaded(true);
    };

    init();

    return () => {
      window.removeEventListener('keypress', onButtonPressed);
    };
  }, [dispatch, id]);

  const onUpdate = withDebounce((field, value) => dispatch(updateTemplateById({ field, value })));

  const onImageChoose = (itemId, imageId) => {
    dispatch(chooseImage({ id: itemId, imageId }));
  };

  const onSimilarChoose = (itemId, rowId) => {
    dispatch(copyFromSimilar({ id: itemId, similarId: rowId }));
  };

  return (
    <div onKeyPress={onButtonPressed}>
      <AdminLayout contentStyle={{ padding: '10px 0 0 0' }}>
        {loaded ? (
          <div
            style={{
              width: staticColumn ? `calc(100% - ${staticColumnMinimized ? 200 : 540}px)` : '100%',
              display: 'flex',
              flexDirection: 'column',
              overflowY: 'hidden',
              height: 'calc(100vh - 66px)',
            }}
          >
            <EditTemplateForm onUpdate={onUpdate} />
            <EditTemplateTable />
          </div>
        ) : (
          <Loader />
        )}
      </AdminLayout>
      {staticColumn && (
        <ImageSelectorSidebar onChoose={onImageChoose} onSimilarChoose={onSimilarChoose} />
      )}
    </div>
  );
};

export default AdminEditTemplatePage;
