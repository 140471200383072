import React, { useState } from 'react';
import PropTypes from 'prop-types';
import _chunk from 'lodash/chunk';
import cx from 'classnames';

import {
  Form, Button, Alert,
} from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { deleteTemplate } from '../../store/templates';
import { updateLocal } from '../../store/editableTemplate';

import DeleteButtonWithModal from '../DeleteButtonWithModal';
import EditTemplateColumnsPicker from '../EditTemplateColumnsPicker';

import { formatDateAsValue } from '../../utils/date';

import styles from './styles.module.css';

const EditTemplateForm = ({ onUpdate, onCreate }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    name,
    currency,
    percent,
    percent2,
    percent3,
    percent4,
    due_date: dueDate,
    submission_date: submissionDate,
    id,
  } = useSelector((store) => store.editableTemplate);
  const itemsOnCurrentPage = useSelector((store) => {
    const { items, currentPage, pageSize } = store.templateItems;

    const chunks = _chunk(items, pageSize);

    if (chunks.length === 0) {
      return [];
    }

    return chunks[currentPage];
  });
  const [errorText, setErrorText] = useState('');

  const onDelete = async () => {
    await dispatch(deleteTemplate(id));
    history.push('/admin/home');
  };

  const onChange = async (e) => {
    const field = e.target.getAttribute('field');
    const isNumber = e.target.getAttribute('type') === 'number';
    const value = isNumber ? Number(e.target.value) : e.target.value;
    dispatch(updateLocal({ field, value }));
    if (onUpdate) {
      onUpdate(field, value);
    }
  };

  const validateDate = (date, setCallback) => {
    const now = new Date();

    if (new Date(date) <= now) {
      setErrorText('Due and submission dates must be in the future');
    } else {
      setErrorText('');
      setCallback(date);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.input}>
        <Form.Text className={styles.label}>Name</Form.Text>
        <Form.Control
          value={name || ''}
          field="name"
          onChange={onChange}
          placeholder="Template name"
          size="sm"
          required
        />
      </div>

      <div className={styles.input}>
        <Form.Text className={styles.label}>Due date</Form.Text>
        <Form.Control
          className={styles.dateInput}
          type="date"
          field="due_date"
          value={formatDateAsValue(dueDate) || ''}
          onChange={(e) => validateDate(e.target.value, () => onChange(e))}
          size="sm"
          required
        />
      </div>

      <div className={styles.input}>
        <Form.Text className={styles.label}>Submission Date</Form.Text>
        <Form.Control
          className={styles.dateInput}
          type="date"
          field="submission_date"
          value={formatDateAsValue(submissionDate) || ''}
          onChange={(e) => validateDate(e.target.value, () => onChange(e))}
          size="sm"
          required
        />
      </div>

      <div className={cx(styles.percentInputWrapper, styles.input)}>
        <Form.Text className={styles.label}>Percent 1</Form.Text>
        <Form.Control
          className={cx(styles.percentInput, styles.numberInput)}
          value={percent}
          field="percent"
          onChange={onChange}
          placeholder="Percent"
          type="number"
          min={0}
          size="sm"
          required
        />
      </div>

      <div className={cx(styles.percentInputWrapper, styles.input)}>
        <Form.Text className={styles.label}>Percent 2</Form.Text>
        <Form.Control
          className={cx(styles.percentInput, styles.numberInput)}
          value={percent2}
          field="percent2"
          onChange={onChange}
          placeholder="Percent 2"
          type="number"
          min={0}
          size="sm"
          required
        />
      </div>

      <div className={cx(styles.percentInputWrapper, styles.input)}>
        <Form.Text className={styles.label}>Percent 3</Form.Text>
        <Form.Control
          className={cx(styles.percentInput, styles.numberInput)}
          value={percent3}
          field="percent3"
          onChange={onChange}
          placeholder="Percent 3"
          type="number"
          min={0}
          size="sm"
          required
        />
      </div>

      <div className={cx(styles.percentInputWrapper, styles.input)}>
        <Form.Text className={styles.label}>Percent 4</Form.Text>
        <Form.Control
          className={cx(styles.percentInput, styles.numberInput)}
          value={percent4}
          field="percent4"
          onChange={onChange}
          placeholder="Percent 4"
          type="number"
          min={0}
          size="sm"
          required
        />
      </div>

      <div className={styles.input}>
        <Form.Text className={styles.label}>Currency</Form.Text>
        <Form.Control
          className={cx(styles.percentInput, styles.numberInput)}
          value={currency}
          field="currency"
          onChange={onChange}
          placeholder="Currency"
          type="number"
          min={1}
          step={0.01}
          size="sm"
          required
        />
      </div>

      <div>
        {onCreate ? (
          <Button
            type="submit"
            variant="primary"
            className={styles.button}
            onClick={() => {
              if (name && dueDate && submissionDate) {
                onCreate(name, dueDate, submissionDate);
              }
            }}
            size="sm"
          >
            Create
          </Button>
        ) : (
          <DeleteButtonWithModal
            size="sm"
            onDelete={onDelete}
            text="Destroy"
            modalTitle="Destroy Template"
            modalText="Are you sure you want to destroy this template item?"
            buttonClassName={styles.button}
          />
        )}
      </div>

      {onUpdate && <EditTemplateColumnsPicker className={styles.button} />}

      {onUpdate && (
        <Button
          type="submit"
          variant="primary"
          className={styles.button}
          onClick={() => {
            itemsOnCurrentPage.forEach((item) => {
              window.open(`${process.env.REACT_APP_API_URL}/template-items/${item.id}/view-query`, item.id);
            });
          }}
          size="sm"
        >
          View All Queries
        </Button>
      )}

      {errorText && (
        <div>
          <Alert variant="danger">{errorText}</Alert>
        </div>
      )}
    </div>
  );
};

EditTemplateForm.propTypes = {
  onUpdate: PropTypes.func,
  onCreate: PropTypes.func,
};

EditTemplateForm.defaultProps = {
  onUpdate: null,
  onCreate: null,
};

export default EditTemplateForm;
