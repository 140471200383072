import React from 'react';
import { useDispatch } from 'react-redux';
import cx from 'classnames';
import { VscArrowBoth } from 'react-icons/vsc';

import ButtonWithIcon from '../../ButtonWithIcon';

import { toggleMinimizeStaticColumnWithLocalStorage } from '../../../store/columns';

import styles from './styles.module.css';

const MinimizeButton = ({ minimized }) => {
  const dispatch = useDispatch();

  return (
    <ButtonWithIcon
      Icon={<VscArrowBoth />}
      className={cx(
        'pg-none',
        'shadow-none',
        styles.button,
        minimized && styles.minimized,
      )}
      variant="light"
      size="sm"
      onClick={() => dispatch(toggleMinimizeStaticColumnWithLocalStorage())}
    />
  );
};

export default MinimizeButton;
