import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Modal, Button } from 'react-bootstrap';

import styles from './styles.module.css';

const BulkModal = ({ show, onCancel, onConfirm }) => {
  const leftImage = useSelector((store) => store.productsFeed.currentItem?.large_images?.[0]);
  const rightImage = useSelector((store) => store.searchFeed.currentItem?.large_images?.[0]);

  return (
    <Modal show={show} size="lg">
      <Modal.Header>
        <span>
          Please confirm these two items are a
          {' '}
          <b>MODEL</b>
          {' '}
          match
        </span>
      </Modal.Header>
      <Modal.Body>
        <div className={styles.images}>
          <img
            src={leftImage}
            alt="left"
            className={styles.image}
          />
          <img
            src={rightImage}
            alt="right"
            className={styles.image}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="light" onClick={onCancel}>
          No
        </Button>
        <Button variant="primary" onClick={onConfirm}>
          Yes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

BulkModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default BulkModal;
