import _chunk from 'lodash/chunk';

export const BULK_CHUNK_COUNT = 5;

export const NEXT_CHUNK_FIRST_ITEM = {
  FIRST: 'first',
  LAST: 'last',
};

export const getChunkNumber = (items, currentItem, chunkSize = BULK_CHUNK_COUNT) => {
  const index = items.findIndex((it) => it.id === currentItem.id);
  if (index === -1) {
    return -1;
  }

  return Math.ceil((index + 1) / chunkSize) - 1;
};

export const getCurrentChunk = (items, currentItem, chunkSize = BULK_CHUNK_COUNT) => {
  const chunkNumber = getChunkNumber(items, currentItem, chunkSize);
  if (chunkNumber === -1) {
    return [];
  }

  return _chunk(items, chunkSize)[chunkNumber];
};

export const getNextChunk = (items, currentItem, chunkSize = BULK_CHUNK_COUNT) => {
  const chunkNumber = getChunkNumber(items, currentItem, chunkSize);
  if (chunkNumber === -1) {
    return [];
  }

  return _chunk(items, chunkSize)[chunkNumber + 1] || [];
};

export const getPrevChunk = (items, currentItem, chunkSize = BULK_CHUNK_COUNT) => {
  const chunkNumber = getChunkNumber(items, currentItem, chunkSize);
  if (chunkNumber === -1) {
    return [];
  }

  return _chunk(items, chunkSize)[chunkNumber - 1] || [];
};

export const isFirstChunk = (items, currentItem, chunkSize = BULK_CHUNK_COUNT) => {
  const chunkNumber = getChunkNumber(items, currentItem, chunkSize);
  return chunkNumber === 0;
};
