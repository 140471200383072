import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { EVENTS } from '../../../utils/event';

import styles from './styles.module.css';

const AutocompleteSuggest = ({
  onOutsideClick, options, onOptionClick, keyboardIndex,
}) => {
  useEffect(() => {
    window.addEventListener('click', onOutsideClick);
    window.addEventListener(EVENTS.CLOSE_ALL_DROPDOWNS, onOutsideClick);

    return () => {
      window.removeEventListener('click', onOutsideClick);
      window.addEventListener(EVENTS.CLOSE_ALL_DROPDOWNS, onOutsideClick);
    };
  }, []);

  return (
    <div className={styles.suggest}>
      {options.slice(0, 5).map((opt, idx) => (
        <div
          key={opt}
          className={cx(styles.option, idx === keyboardIndex && styles.keyboard)}
          onMouseDown={(e) => onOptionClick(e, opt)}
        >
          {opt}
        </div>
      ))}
    </div>
  );
};

AutocompleteSuggest.propTypes = {
  onOutsideClick: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onOptionClick: PropTypes.func.isRequired,
  keyboardIndex: PropTypes.number.isRequired,
};

export default AutocompleteSuggest;
