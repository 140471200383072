import { createSlice } from '@reduxjs/toolkit';

import { getPrevChunk } from '../../utils/chunk';
import { PRODUCT_ACTIONS } from '../../utils/actions';

import {
  fetchMismatchedProductsFeed,
  setMismatchedMatchAction,
  bulkSkipMismatchedAction,
  finilizeCurrentItem,
  setNextCurrentItem,
} from './middleware';

const initialState = {
  items: [],
  currentItem: null,
  mainImageIndex: 0,
  note: '',
  noteInputActive: false,
  totalProducts: 0,
  finishModal: false,
};

export const mismatchedProductsFeedSlice = createSlice({
  name: 'mismatchedProductsFeed',
  initialState,
  reducers: {
    setMainImageIndex: (state, action) => {
      const index = action.payload;

      if (state.currentItem && state.currentItem.images[index]) {
        state.mainImageIndex = index;
      }
    },
    moveMainImageIndex: (state, action) => {
      const diff = action.payload;

      if (!state.currentItem) {
        return;
      }

      let index = state.mainImageIndex + diff;

      if (index < 0) {
        index = state.currentItem.images.length - 1;
      } else if (index >= state.currentItem.images.length) {
        index = 0;
      }

      if (state.currentItem.images[index]) {
        state.mainImageIndex = index;
      }
    },
    setNote: (state, action) => {
      state.note = action.payload;
    },
    setNoteInputStatus: (state, action) => {
      state.noteInputActive = action.payload;
    },
    setNextCurrentItemSimple: (state) => {
      const index = state.items.findIndex((i) => i.id === state.currentItem.id);
      const newCurrentItem = state.items[index + 1];
      if (newCurrentItem) {
        state.currentItem = newCurrentItem;
        state.mainImageIndex = 0;
      }
    },
    setPrevCurrentItem: (state) => {
      const currentIndex = state.items.findIndex((i) => i.id === state.currentItem.id);
      const newIndex = Math.max(0, currentIndex - 1);
      state.currentItem = state.items[newIndex];
      state.mainImageIndex = 0;
    },
    setCurrentItem: (state, action) => {
      const id = action.payload;

      if (id === state.currentItem.id) {
        return;
      }

      const newCurrentItem = state.items.find((i) => i.id === id);

      state.currentItem = newCurrentItem;
      state.mainImageIndex = 0;
    },
    mismatchedPrevChunk: (state) => {
      const chunk = getPrevChunk(state.items, state.currentItem);

      if (chunk?.length) {
        state.currentItem = chunk[chunk.length - 1];
        state.mainImageIndex = 0;
      }
    },
    mismatchedDoubleNextChunk: (state) => {
      const nextItem = state.items.find((it) => !it.action);

      if (nextItem) {
        state.currentItem = nextItem;
        state.mainImageIndex = 0;
      }
    },
    setFinishModal: (state, action) => {
      state.finishModal = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchMismatchedProductsFeed.fulfilled, (state, action) => {
      state.items = action.payload.products;
      const [firstItem] = action.payload.products;
      state.totalProducts = action.payload.count;
      state.currentItem = firstItem || null;
    });

    builder.addCase(setMismatchedMatchAction.fulfilled, (state, action) => {
      if (!action.payload) {
        return;
      }

      const item = state.items.find((i) => i.id === action.payload.id);

      if (item) {
        item.action = action.payload.action;
        state.note = '';
        state.currentItem.action = action.payload.action;
      }
    });

    builder.addCase(bulkSkipMismatchedAction.fulfilled, (state, action) => {
      const { nextId, ids } = action.payload;

      if (!nextId || !ids) {
        return;
      }

      const next = state.items.find((it) => it.id === nextId);

      state.items.forEach((it) => {
        if (ids.includes(it.id)) {
          it.action = PRODUCT_ACTIONS.SKIP;
        }
      });

      state.note = '';
      state.currentItem = next;
      state.mainImageIndex = 0;
    });
  },
});

export {
  fetchMismatchedProductsFeed,
  setMismatchedMatchAction,
  bulkSkipMismatchedAction,
  finilizeCurrentItem,
  setNextCurrentItem,
};

export const {
  setMainImageIndex,
  moveMainImageIndex,
  setNote,
  setNoteInputStatus,
  setCurrentItem,
  setPrevCurrentItem,
  mismatchedPrevChunk,
  mismatchedDoubleNextChunk,
  setFinishModal,
  setNextCurrentItemSimple,
} = mismatchedProductsFeedSlice.actions;

export default mismatchedProductsFeedSlice.reducer;
