import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import cx from 'classnames';

import styles from './styles.module.css';

const ButtonWithIcon = ({
  Icon, text, className, ...rest
}) => (
  <Button variant="warning" className={cx(className, styles.button)} {...rest}>
    {React.cloneElement(Icon, { className: cx(styles.icon, !text && styles.noText) })}
    {text}
  </Button>
);

ButtonWithIcon.propTypes = {
  Icon: PropTypes.node.isRequired,
  text: PropTypes.string,
  className: PropTypes.string,
};

ButtonWithIcon.defaultProps = {
  text: '',
  className: '',
};

export default ButtonWithIcon;
