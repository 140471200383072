import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Button } from 'react-bootstrap';

const EMPTY_MATHCER = {
  name: '',
  password: '',
  enabled: true,
};

const NewMatcherRow = ({ onCreate, formId }) => {
  const [matcher, setMatcher] = useState(EMPTY_MATHCER);

  const onSubmit = async (e) => {
    e.preventDefault();
    if (matcher.name && matcher.password) {
      await onCreate(matcher.name, matcher.password, matcher.enabled);
      setMatcher(EMPTY_MATHCER);
    }
  };

  return (
    <tr>
      <td><Form id={formId} onSubmit={onSubmit} /></td>
      <td>
        <Form.Control
          value={matcher.name || ''}
          onChange={(e) => setMatcher({ ...matcher, name: e.target.value })}
          placeholder="New user login"
          required
          form={formId}
        />
      </td>
      <td>
        <Form.Control
          type="password"
          form={formId}
          value={matcher.password || ''}
          placeholder="New user password"
          onChange={(e) => setMatcher({ ...matcher, password: e.target.value })}
          required
        />
      </td>
      <td>
        <Form.Check
          type="checkbox"
          form={formId}
          checked={matcher.enabled}
          onChange={() => setMatcher({ ...matcher, enabled: !matcher.enabled })}
        />
      </td>
      <td />
      <td />
      <td />
      <td />
      <td>
        <Button variant="success" form={formId} type="submit">Create</Button>
      </td>
    </tr>
  );
};

NewMatcherRow.propTypes = {
  onCreate: PropTypes.func.isRequired,
  formId: PropTypes.string.isRequired,
};

export default NewMatcherRow;
