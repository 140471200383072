import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import {
  moveMainImageIndex,
  setMatchAction,
  setItemByDirection,
  resetItems,
  eraseLastItem,
  fetchProductsFeed,
  setBulkOffset,
  setCurrentItemNumber,
  bulkSkipAction,
  addBulkMatchedIds,
  setNextCurrentItem,
  setFinalizeModal,
  checkAllMatched,
  setBulkBackwardOffset,
  bulkNextPage,
} from '../../store/productsFeed';
import { markCurrentItemAsFinished, finalizeCurrentTemplateItem } from '../../store/searchFeed';
import { toggleNextItemToast } from '../../store/toasts';

import { PRODUCT_ACTIONS } from '../../utils/actions';
import { BULK_CHUNK_COUNT, NEXT_CHUNK_FIRST_ITEM } from '../../utils/chunk';
import { KEYS } from '../../utils/keycode';
import { withThrottle, THROTTLE_MATCH_MS } from '../../utils/debounce';

import KeyboardProvider from '../KeyboardProvider';
import MergeModal from '../MergeModal';
import BulkModal from '../BulkModal';
import FinishTemplateItemModal from '../FinishTemplateItemModal';

const BulkMatchPageKeyboardKeysProvider = ({ children }) => {
  const lock = useRef(false);
  const dispatch = useDispatch();
  const currentProductItem = useSelector((store) => store.productsFeed.currentItem);
  const offset = useSelector((store) => store.productsFeed.bulkOffset);
  const finishItemModal = useSelector((store) => store.productsFeed.finalizeModal);
  const currentNumber = useSelector((store) => {
    const current = store.productsFeed.currentItem;
    const all = store.productsFeed.items;
    if (!current) {
      return null;
    }

    return all.findIndex((i) => i.id === current.id);
  });

  const [mergeModal, setMergeModal] = useState(false);
  const [bulkModal, setBulkModal] = useState(false);

  useEffect(() => {
    if (!mergeModal && !bulkModal && !finishItemModal) {
      lock.current = false;
    }
  }, [mergeModal, bulkModal, finishItemModal]);

  const proccessFinished = async (shouldSendFinalize = true) => {
    dispatch(setFinalizeModal(false));
    if (shouldSendFinalize) {
      await dispatch(finalizeCurrentTemplateItem()).unwrap();
    }
    dispatch(markCurrentItemAsFinished());
    dispatch(toggleNextItemToast());
    dispatch(resetItems());
  };

  const onConfirmMerge = async () => {
    await dispatch(setMatchAction({
      action: PRODUCT_ACTIONS.EXACT,
      mergeApproval: true,
    })).unwrap();
    setMergeModal(false);
    proccessFinished(false);
  };

  const onConfirmBulk = async () => {
    const response = await dispatch(setMatchAction({
      action: PRODUCT_ACTIONS.MODEL,
      mergeApproval: true,
    })).unwrap();
    setBulkModal(false);
    dispatch(addBulkMatchedIds(response.bulk_matched_ids));
    dispatch(setNextCurrentItem());
  };

  const onMoveKeyPressed = async (direction) => {
    if (!currentProductItem || lock.current) {
      return;
    }
    lock.current = true;

    if (await dispatch(checkAllMatched()).unwrap() && direction === 1) {
      const response = await dispatch(bulkSkipAction()).unwrap();
      if (response) {
        dispatch(setFinalizeModal(true));
        return;
      }
    }

    const nextNumber = currentNumber + direction;
    if (nextNumber < 0 || nextNumber > BULK_CHUNK_COUNT - 1) {
      const nextOffset = offset + direction * BULK_CHUNK_COUNT;
      if (nextOffset < 0) {
        lock.current = false;
        return;
      }
      dispatch(setBulkOffset({ offset: nextOffset }));
      if (direction === 1) {
        dispatch(setBulkBackwardOffset({ offset: nextOffset }));
        const response = await dispatch(bulkSkipAction()).unwrap();
        const allMatched = await dispatch(checkAllMatched()).unwrap();
        dispatch(setCurrentItemNumber(nextOffset + 1));
        if (response && allMatched) {
          dispatch(setFinalizeModal(true));
          return;
        }
      } else {
        dispatch(setCurrentItemNumber(nextOffset + BULK_CHUNK_COUNT));
      }
      const next = direction === 1 ? NEXT_CHUNK_FIRST_ITEM.FIRST : NEXT_CHUNK_FIRST_ITEM.LAST;
      dispatch(fetchProductsFeed({
        offset: nextOffset,
        count: BULK_CHUNK_COUNT,
        force: true,
        next,
      }));
      lock.current = false;
      return;
    }

    dispatch(setItemByDirection({ direction }));
    lock.current = false;
  };

  const onMatchKeyPressed = async (action) => {
    if (!currentProductItem || lock.current) {
      return;
    }
    lock.current = true;

    const response = await dispatch(setMatchAction({
      action: currentProductItem.action === action ? PRODUCT_ACTIONS.SKIP : action,
    })).unwrap();

    if (response) {
      const allMatched = await dispatch(checkAllMatched()).unwrap();
      if (!response.success) {
        if (response.sub_code === 1) {
          setMergeModal({ images1: response.images1, images2: response.images2 });
        } else if (response.sub_code === 2) {
          setBulkModal(true);
        }
      } else if (allMatched) {
        dispatch(setFinalizeModal(true));
      } else {
        if (currentNumber + 1 <= BULK_CHUNK_COUNT - 1) {
          dispatch(setItemByDirection({ direction: 1 }));
        }
        lock.current = false;
      }
    } else {
      lock.current = false;
    }
  };

  const onKeyUp = withThrottle(async (e) => {
    const { code } = e;

    switch (code) {
      case KEYS.SPACE:
        e.stopPropagation();
        if (!lock.current) {
          lock.current = true;
          dispatch(bulkNextPage());
          lock.current = false;
        }
        break;
      default:
        break;
    }
  }, THROTTLE_MATCH_MS);

  const onKeyDown = withThrottle(async (e) => {
    const { code } = e;

    const preventDeafult = () => {
      e.preventDefault();
      e.stopPropagation();
    };

    switch (code) {
      case KEYS.ARROW_DOWN:
        preventDeafult();
        if (!lock.current) {
          await onMoveKeyPressed(1);
        }
        break;
      case KEYS.ARROW_UP:
        preventDeafult();
        if (!lock.current) {
          await onMoveKeyPressed(-1);
        }
        break;
      case KEYS.KEY_A:
        await onMatchKeyPressed(PRODUCT_ACTIONS.MODEL);
        break;
      case KEYS.KEY_S:
        await onMatchKeyPressed(PRODUCT_ACTIONS.EXACT);
        break;
      case KEYS.ARROW_RIGHT:
        dispatch(moveMainImageIndex(1));
        break;
      case KEYS.ARROW_LEFT:
        dispatch(moveMainImageIndex(-1));
        break;
      case KEYS.BACKSPACE:
        await dispatch(eraseLastItem()).unwrap();
        break;
      default:
        break;
    }
  }, THROTTLE_MATCH_MS);

  return (
    <>
      <KeyboardProvider onKeyDown={onKeyDown} onKeyUp={onKeyUp}>
        {children}
      </KeyboardProvider>
      <MergeModal
        show={!!mergeModal}
        onCancel={() => setMergeModal(false)}
        onConfirm={onConfirmMerge}
        data={mergeModal}
      />
      <BulkModal
        show={bulkModal}
        onCancel={() => setBulkModal(false)}
        onConfirm={onConfirmBulk}
      />
      <FinishTemplateItemModal
        show={finishItemModal}
        onCancel={() => dispatch(setFinalizeModal(false))}
        onConfirm={() => proccessFinished()}
      />
    </>
  );
};

BulkMatchPageKeyboardKeysProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default BulkMatchPageKeyboardKeysProvider;
