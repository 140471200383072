import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { Form } from 'react-bootstrap';

import { setNote, setNoteInputStatus } from '../../store/mismatchedProductsFeed';

import styles from './styles.module.css';

const InspectionNote = ({ className }) => {
  const note = useSelector((store) => store.mismatchedProductsFeed.note);
  const dispatch = useDispatch();

  const onChange = (e) => {
    dispatch(setNote(e.target.value));
  };

  return (
    <Form.Control
      placeholder="Note..."
      as="textarea"
      rows="3"
      className={cx(styles.text, className)}
      value={note}
      onChange={onChange}
      onFocus={() => dispatch(setNoteInputStatus(true))}
      onBlur={() => dispatch(setNoteInputStatus(false))}
    />
  );
};

InspectionNote.propTypes = {
  className: PropTypes.string,
};

InspectionNote.defaultProps = {
  className: '',
};

export default InspectionNote;
