import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Dropdown, Container } from 'react-bootstrap';

import { setSelectedMatcherByIdLocal } from '../../store/matchers';

const MatchersSelector = () => {
  const dispatch = useDispatch();
  const matchers = useSelector((store) => store.matchers.matchers);
  const selectedMatcher = useSelector((store) => store.matchers.selectedMatcher);

  const onSelect = (matcherId) => {
    dispatch(setSelectedMatcherByIdLocal(matcherId));
  };

  return (
    <Container>
      <Dropdown onSelect={onSelect}>
        <Dropdown.Toggle variant="light">
          {selectedMatcher ? selectedMatcher.name : 'Select Matcher'}
        </Dropdown.Toggle>

        <Dropdown.Menu>
          {matchers.map((matcher) => (
            <Dropdown.Item
              key={matcher.id}
              eventKey={matcher.id}
            >
              {matcher.name}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </Container>
  );
};

export default MatchersSelector;
