import React from 'react';
import PropTypes from 'prop-types';

import ThreeColumnLayout from '../ThreeColumnLayout';

import styles from './styles.module.css';

const SectionHeader = ({ leftSideComponent, rightSideComponent, children }) => (
  <div className={styles.container}>
    <ThreeColumnLayout leftComponent={leftSideComponent} rightComponent={rightSideComponent}>
      {children}
    </ThreeColumnLayout>
  </div>
);

SectionHeader.propTypes = {
  leftSideComponent: PropTypes.node,
  rightSideComponent: PropTypes.node,
  children: PropTypes.node,
};

SectionHeader.defaultProps = {
  leftSideComponent: null,
  rightSideComponent: null,
  children: null,
};

export default SectionHeader;
