import React from 'react';
import PropTypes from 'prop-types';
import {
  BsFillCloudSlashFill,
  BsFillCloudCheckFill,
} from 'react-icons/bs';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import ButtonWithIcon from '../ButtonWithIcon';

const CompleteButtonWithModal = ({
  value,
  onChange,
  field,
}) => (
  <OverlayTrigger placement="top" overlay={<Tooltip>{value ? 'Un-Complete' : 'Complete'}</Tooltip>}>
    <div>
      <ButtonWithIcon
        Icon={value ? <BsFillCloudSlashFill /> : <BsFillCloudCheckFill />}
        size="sm"
        variant={value ? 'danger' : 'success'}
        onFocus={(e) => {
          e.target.blur();
        }}
        onClick={() => onChange(!value, field)}
        style={{ height: '30px' }}
      />
    </div>
  </OverlayTrigger>
);

CompleteButtonWithModal.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.bool.isRequired,
  field: PropTypes.string,
};

CompleteButtonWithModal.defaultProps = {
  field: '',
};

export default CompleteButtonWithModal;
