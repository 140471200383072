import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import {
  Navbar, Container, Nav,
} from 'react-bootstrap';
import { BsBoxArrowInLeft } from 'react-icons/bs';

import ButtonWithIcon from '../ButtonWithIcon';

import useAuth from '../../hooks/useAuth';

import styles from './styles.module.css';

const MatcherLayout = ({ children, contentClassName }) => {
  const { user, logOut } = useAuth();

  return (
    <div>
      <Navbar bg="light" fixed="top">
        <Container>
          <Navbar.Brand>
            <Link to="/matcher/home" className={styles.menuLinkItem}>
              Matcher
            </Link>
          </Navbar.Brand>
          <Nav>
            <Nav.Link>
              <Link to="/matcher/feedback" className={styles.menuLinkItem}>
                Feedback
              </Link>
            </Nav.Link>
            <Nav.Link disabled>{user.name}</Nav.Link>
            <ButtonWithIcon
              text="Log out"
              variant="danger"
              onClick={logOut}
              Icon={<BsBoxArrowInLeft />}
            />
          </Nav>
        </Container>
      </Navbar>
      <div className={cx(styles.pageContent, contentClassName)}>
        {children}
      </div>
    </div>
  );
};

MatcherLayout.propTypes = {
  children: PropTypes.node,
  contentClassName: PropTypes.string,
};

MatcherLayout.defaultProps = {
  children: null,
  contentClassName: '',
};

export default MatcherLayout;
