import React from 'react';
import { Link } from 'react-router-dom';
import { BsPlusCircle } from 'react-icons/bs';

import AdminLayout from '../../components/AdminLayout';
import TemplatesList from '../../components/TemplatesList';
import ButtonWithIcon from '../../components/ButtonWithIcon';
import TemplatesSearch from '../../components/TemplatesSearch';

import styles from './styles.module.css';

const AdminHomePage = () => (
  <AdminLayout>
    <TemplatesSearch className={styles.search} />
    <TemplatesList />
    <Link to="/admin/templates">
      <ButtonWithIcon
        variant="warning"
        className={styles.newTemplateButton}
        text="New Template"
        Icon={<BsPlusCircle />}
      />
    </Link>
  </AdminLayout>
);

export default AdminHomePage;
