import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Form } from 'react-bootstrap';
import { KEYS } from '../../utils/keycode';
import { EVENTS } from '../../utils/event';

import styles from './styles.module.css';

const DropdownInput = ({
  id,
  options,
  onChange,
  initialValue,
  name,
  field,
  disabled,
  onFocus,
  onBlur,
  ...rest
}) => {
  const [selected, setSelected] = useState(initialValue);
  const [inputValue, setInputValue] = useState('');
  const [suggestVisible, setSuggestVisible] = useState(false);
  const [filtered, setFiltered] = useState(options);
  const [keyboardIndex, setKeyboardIndex] = useState(-1);

  const formatInput = useCallback((newSelected) => {
    if (newSelected.length === 0) {
      setInputValue('');
    } else if (newSelected.length === 1) {
      setInputValue(newSelected[0].label);
    } else {
      setInputValue(`${newSelected.length} ${name}`);
    }
  }, [name]);

  useEffect(() => {
    setSelected(initialValue);
    formatInput(initialValue);
  }, [initialValue, formatInput]);

  useEffect(() => {
    const target = document.getElementById(`${id}-opt-${keyboardIndex}`);
    if (target) {
      target.scrollIntoView({
        block: 'nearest',
        inline: 'nearest',
        behavior: 'smooth',
      });
    }
  }, [keyboardIndex, id]);

  const onOutsideClick = useCallback((e) => {
    if (!e || !e.target.getAttribute || e.target.getAttribute('id') !== id) {
      setSuggestVisible(false);
      setKeyboardIndex(-1);
      if (onBlur) {
        onBlur();
      }
      window.removeEventListener('click', onOutsideClick);
      window.removeEventListener(EVENTS.CLOSE_ALL_DROPDOWNS, onOutsideClick);
    }
  }, [id]);

  useEffect(() => () => {
    window.removeEventListener('click', onOutsideClick);
    window.removeEventListener(EVENTS.CLOSE_ALL_DROPDOWNS, onOutsideClick);
  }, [onOutsideClick]);

  const onInputChange = async (e) => {
    setKeyboardIndex(-1);
    setInputValue(e.target.value);
    setFiltered(options.filter(
      (item) => item.label.toLowerCase().includes(e.target.value.toLowerCase()),
    ));
  };

  const onOptionClick = (e, opt, isSelected = false) => {
    e.stopPropagation();
    let newSelected = [];
    if (isSelected) {
      newSelected = [...selected];
      newSelected.splice(
        selected.findIndex((item) => item.value === opt.value),
        1,
      );
    } else {
      newSelected = [...selected, opt];
    }

    setSelected(newSelected);
    onChange(newSelected, field);
    formatInput(newSelected);
  };

  const onFocusHandler = (e) => {
    if (onFocus) {
      onFocus(e);
    }
    setInputValue('');
    setFiltered(options);
    e.stopPropagation();
    e.preventDefault();
    window.addEventListener('click', onOutsideClick);
    window.addEventListener(EVENTS.CLOSE_ALL_DROPDOWNS, onOutsideClick);
    setSuggestVisible(true);
  };

  const optionsToShow = filtered
    .filter((item) => selected.findIndex((i) => i.value === item.value) === -1);

  const onKeyDown = (e) => {
    if (e.key === 'Tab') {
      onOutsideClick();
    } else if (e.key === KEYS.ARROW_DOWN) {
      setKeyboardIndex(Math.min(Math.max(0, keyboardIndex + 1), options.length - 1));
    } else if (e.key === KEYS.ARROW_UP) {
      setKeyboardIndex(Math.max(0, keyboardIndex - 1));
    } else if (e.key === KEYS.ENTER) {
      let newSelected = selected;
      if (keyboardIndex < selected.length) {
        newSelected = selected.filter((_, idx) => idx !== keyboardIndex);
      } else {
        newSelected = [
          ...selected,
          optionsToShow[keyboardIndex - selected.length],
        ];
      }

      setSelected(newSelected);
      formatInput(newSelected);
      onChange(newSelected, field);
    }
  };

  return (
    <div className={styles.container}>
      <Form.Control
        className={styles.input}
        value={inputValue}
        onChange={onInputChange}
        onFocus={onFocusHandler}
        onBlur={() => formatInput(selected)}
        onKeyDown={onKeyDown}
        id={id}
        disabled={disabled}
        {...rest}
      />
      {suggestVisible && optionsToShow.length > 0 && !disabled && (
        <div className={styles.suggest}>
          {selected.map((opt, idx) => (
            <div
              key={opt.value}
              className={cx(
                styles.option,
                styles.selected,
                idx === keyboardIndex && styles.keyboard,
              )}
              id={`${id}-opt-${idx}`}
              onClick={(e) => onOptionClick(e, opt, true)}
            >
              {opt.label}
            </div>
          ))}
          {optionsToShow.map((opt, idx) => (
            <div
              key={opt.value}
              className={cx(
                styles.option,
                idx + selected.length === keyboardIndex && styles.keyboard,
              )}
              id={`${id}-opt-${idx + selected.length}`}
              onClick={(e) => onOptionClick(e, opt)}
            >
              {opt.label}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

DropdownInput.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  })).isRequired,
  initialValue: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  })),
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  onFocus: PropTypes.func,
  field: PropTypes.string,
  disabled: PropTypes.bool,
};

DropdownInput.defaultProps = {
  onChange: null,
  onBlur: null,
  initialValue: [],
  field: '',
  disabled: false,
  onFocus: null,
};

export default DropdownInput;
