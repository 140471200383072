import React, { useState } from 'react';
import cx from 'classnames';

import LensImage from '../../LensImage';
import SideArrowButton from './SideArrowButton';
import Dots from './Dots';

import styles from './styles.module.css';

const ImageRowCarousel = ({
  images,
  rowId,
  currentRowId,
  imageClassName,
  rowClassName,
  onImageClick,
  similar,
}) => {
  const [index, setIndex] = useState(0);

  const move = (value) => {
    if (index + value < 0 || index + value >= images.length) {
      return;
    }

    setIndex(index + value);
  };

  return (
    <div id={rowId} className={cx(rowClassName, styles.row)}>
      <SideArrowButton onClick={() => move(-1)} reversed disabled={index === 0} />
      <LensImage
        className={imageClassName}
        onClick={() => onImageClick(images[index].id)}
        position="left"
        imageUrl={images[index].url}
        bigImageUrl={images[index].medium_url}
        id={similar
          ? `similar-images-${currentRowId}-${index}`
          : `images-${currentRowId}-${images[index].id}`}
        disableLazy
      />
      <SideArrowButton onClick={() => move(1)} disabled={index === images.length - 1} />
      <Dots
        className={styles.dots}
        current={index}
        total={images.length - 1}
      />
    </div>
  );
};

export default ImageRowCarousel;
