import { createAsyncThunk, createAction } from '@reduxjs/toolkit';

import {
  getSearchFeed,
  finilizeTemplateItem,
} from '../../utils/api';

export const fetchSearchFeed = createAsyncThunk(
  'searchFeed/fetchSearchFeed',
  async (_, { getState, dispatch }) => {
    const { items, currentItem } = getState().searchFeed;
    const responseItems = await getSearchFeed();
    if (currentItem && !items.find((it) => it.id === currentItem.id)) {
      // Current item was removed
      dispatch(createAction('productsFeed/resetItems')());
      dispatch(createAction('toasts/toggleNextItemToast')());
      return responseItems;
    }

    return responseItems;
  },
);

export const finalizeCurrentTemplateItem = createAsyncThunk(
  'searchFeed/finalizeTemplateItem',
  async (_, { getState }) => {
    const currentSearchItem = getState().searchFeed.currentItem;
    if (!currentSearchItem) {
      return;
    }

    await finilizeTemplateItem(currentSearchItem.id);
  },
);
