import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ItemsScroll from '../ItemsScroll';

import { fetchFeedbackProductsFeed, setCurrentItem } from '../../store/feedbackProductsFeed';
import { getScrollItemsFromProducts } from '../../utils/options';

const FeedbackProductsScroll = () => {
  const dispatch = useDispatch();
  const currentSearchItem = useSelector((store) => store.feedbackSearchFeed.currentItem);
  const items = useSelector((store) => store.feedbackProductsFeed.items);
  const currentItem = useSelector((store) => store.feedbackProductsFeed.currentItem);

  useEffect(() => {
    if (currentSearchItem) {
      dispatch(fetchFeedbackProductsFeed(currentSearchItem.id));
    }
  }, [currentSearchItem, dispatch]);

  const onItemClick = (itemId) => {
    dispatch(setCurrentItem(itemId));
  };

  return (
    <ItemsScroll
      items={getScrollItemsFromProducts(items, 'correct_action')}
      currentItemId={currentItem ? currentItem.id : null}
      onClick={onItemClick}
      rtl
    />
  );
};

export default FeedbackProductsScroll;
