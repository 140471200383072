import { createSlice } from '@reduxjs/toolkit';
import isEqual from 'lodash/isEqual';

import {
  chooseImage,
  copyFromSimilar,
  createItem,
  deleteImage,
  deleteItem,
  deleteItemEverywhere,
  EMPTY_ITEM,
  fetchItemImages,
  fetchSimilarImages,
  fetchTemplateItems,
  focusFieldForNextUncompletedItem,
  resetImages,
  revertItem,
  updateItem,
  uploadImage,
} from './middleware';

const initialState = {
  items: [],
  currentItemId: null,
  imageSidebarFocused: false,
  currentItemChanges: {},
  images: [],
  similarImages: [],
  imagesOffset: 0,
  imagesMore: false,
  hoveredImageSrc: '',
  currentPage: 0,
  pageSize: 50,
};

export const templateItemsSlice = createSlice({
  name: 'templateItems',
  initialState,
  reducers: {
    setHoveredImageSrc: (state, action) => {
      state.hoveredImageSrc = action.payload;
    },
    setImageSidebarFocus: (state, action) => {
      state.imageSidebarFocused = action.payload;
    },
    mergeItemChanges: (state, action) => {
      const id = action.payload;
      const prevItemIdx = state.items.findIndex((i) => i.id === id);
      if (prevItemIdx !== -1) {
        state.items = [
          ...state.items.slice(0, prevItemIdx),
          {
            ...state.items[prevItemIdx],
            ...state.currentItemChanges,
          },
          ...state.items.slice(prevItemIdx + 1),
        ];
      }
    },
    setCurrentItemId: (state, action) => {
      const { id } = action.payload;
      if (state.currentItemId !== id) {
        const prevItemIdx = state.items.findIndex((it) => it.id === state.currentItemId);
        if (prevItemIdx !== -1) {
          state.items = [
            ...state.items.slice(0, prevItemIdx),
            {
              ...state.items[prevItemIdx],
              ...state.currentItemChanges,
            },
            ...state.items.slice(prevItemIdx + 1),
          ];
        }
        state.currentItemChanges = {};
      }
      state.currentItemId = id;
    },
    updateItemLocal: (state, action) => {
      const {
        item, field, force,
      } = action.payload;

      if (field === 'manual_price' && item?.linked_items?.length) {
        const itemsToUpdate = state.items.filter((i) => item.linked_items?.includes(i.id));
        itemsToUpdate.forEach((it) => {
          it.manual_price = item.manual_price;
          it.price_updated_at = item.price_updated_at;
        });
      }

      if (field === 'manual_price') {
        const updatedItem = state.items.find((i) => i.id === item.id);
        updatedItem.price_updated_at = item.price_updated_at;
      }

      if (force) {
        state.currentItemChanges = {};
      } else {
        state.currentItemChanges.count = item.count;
      }
    },
    setItemChanges: (state, action) => {
      const { id, field, value } = action.payload;
      const item = state.items.find((it) => it.id === id);
      if (item) {
        // eslint-disable-next-line no-mixed-operators
        if (isEqual(item[field], value) || field === 'manual_price' && `${item[field]}` === value) {
          delete state.currentItemChanges[field];
        } else {
          state.currentItemChanges[field] = value;
        }
      }
    },
    resetAllImages: (state) => {
      state.images = [];
      state.similarImages = [];
      state.imagesOffset = 0;
      state.imagesMore = false;
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setPageSize: (state, action) => {
      state.pageSize = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchTemplateItems.fulfilled, (state, action) => {
      state.items = action.payload;
    });
    builder.addCase(createItem.fulfilled, (state, action) => {
      state.items.push(action.payload);
    });
    builder.addCase(deleteItem.fulfilled, (state, action) => {
      const id = action.payload;
      const index = state.items.findIndex((i) => i.id === id);
      if (index !== -1) {
        state.items.splice(index, 1);
      }
    });
    builder.addCase(deleteItemEverywhere.fulfilled, (state, action) => {
      const {
        product_id: productId,
        template_item_image_group_id: templateItemImageGroupId,
      } = action.payload;

      if (productId) {
        state.items = state.items.filter((item) => item.product_id !== productId);
      } else if (templateItemImageGroupId) {
        state.items = state.items.filter(
          (item) => item.template_item_image_group_id !== templateItemImageGroupId,
        );
      }
    });
    builder.addCase(uploadImage.fulfilled, (state, action) => {
      const item = state.items.find((i) => i.id === action.payload.id);

      if (item) {
        item.template_images = action.payload.template_images;
        item.product_images = action.payload.product_images;
      }
    });
    builder.addCase(deleteImage.fulfilled, (state, action) => {
      const item = state.items.find((i) => i.id === action.payload.id);

      if (item) {
        item.template_images = action.payload.template_images;
      }
    });
    builder.addCase(resetImages.fulfilled, (state, action) => {
      const item = state.items.find((i) => i.id === action.payload.id);
      if (item) {
        item.product_images = action.payload.product_images;
        item.product_id = action.payload.product_id;
      }
    });
    builder.addCase(copyFromSimilar.fulfilled, (state, action) => {
      if (!action.payload) {
        return;
      }

      const itemToUpdateIndex = state.items.findIndex((i) => i.id === action.payload.id);
      if (itemToUpdateIndex !== -1) {
        state.items[itemToUpdateIndex] = action.payload;
      }
      state.currentItemChanges = {};
    });
    builder.addCase(chooseImage.fulfilled, (state, action) => {
      if (!action.payload) {
        return;
      }

      const itemToUpdate = state.items.find((i) => i.id === action.payload.id);
      if (itemToUpdate) {
        itemToUpdate.product_images = action.payload.product_images;
        itemToUpdate.completed = action.payload.completed;
        itemToUpdate.product_id = action.payload.product_id;

        if (action.payload.duplicate_id !== null) {
          itemToUpdate.manual_price = action.payload.manual_price;
          itemToUpdate.duplicate_id = action.payload.duplicate_id;
        }
      }
      state.currentItemChanges = {};
    });
    builder.addCase(fetchItemImages.fulfilled, (state, action) => {
      state.images = action.payload.fromOffset === 0
        ? action.payload.images
        : [...state.images, ...action.payload.images];
      state.imagesOffset = action.payload.imagesOffset;
      state.imagesMore = action.payload.imagesMore;
    });
    builder.addCase(fetchSimilarImages.fulfilled, (state, action) => {
      state.similarImages = action.payload.similarImages;
    });
  },
});

export {
  fetchTemplateItems,
  createItem,
  updateItem,
  deleteItem,
  deleteItemEverywhere,
  uploadImage,
  chooseImage,
  deleteImage,
  resetImages,
  revertItem,
  focusFieldForNextUncompletedItem,
  copyFromSimilar,
  fetchItemImages,
  fetchSimilarImages,
  EMPTY_ITEM,
};

export const {
  updateWordsAndCountForItemLocal,
  updateWordsWithMerge,
  setCurrentItemId,
  setImageSidebarFocus,
  updateItemLocal,
  setItemChanges,
  resetAllImages,
  mergeItemChanges,
  setHoveredImageSrc,
  setCurrentPage,
  setPageSize,
} = templateItemsSlice.actions;

export default templateItemsSlice.reducer;
