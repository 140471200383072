import { PRODUCT_ACTIONS } from './actions';
import { COLORS } from './colors';

export const BORDER_WIDTH = 4;

export const getBorderByAction = (action) => {
  if (action === PRODUCT_ACTIONS.MODEL) {
    return { colors: [COLORS.FINISHED_PRODUCT] };
  }

  if (action === PRODUCT_ACTIONS.EXACT) {
    return { colors: [COLORS.FINISHED_PRODUCT, COLORS.FINISHED_ITEM] };
  }

  return null;
};

export const getBorderCssStyleValue = (border) => {
  if (!border || border.colors.length < 1 || border.colors.length > 2) {
    return undefined;
  }

  return `4px solid ${border.colors[0]}`;
};

export const getOutlineCssStyleValue = (border) => {
  if (!border || border.colors.length !== 2) {
    return undefined;
  }

  return `4px solid ${border.colors[1]}`;
};

export const getBoxShadow = (border, isActive) => {
  let iteration = 1;
  const shadows = [];

  if (border && border.colors.length) {
    border.colors.reverse().forEach((color) => {
      shadows.push(`0 0 0 ${BORDER_WIDTH * iteration}px ${color}`);
      iteration += 1;
    });
  }

  if (isActive) {
    shadows.push(`0 0 0 ${BORDER_WIDTH * iteration}px ${COLORS.ACTIVE_ITEM}`);
    iteration += 1;
  }

  return shadows.join(',');
};
