import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  fetchProductsFeed,
  setAnyCurrentItem,
  setCurrentItemNumber,
  setBulkOffset,
  bulkNextPage,
  bulkPrevPage,
  setBulkBackwardOffset,
  resetItems as resetProductsFeed,
} from '../../store/productsFeed';
import {
  resetItems as resetSearchFeed,
} from '../../store/searchFeed';
import {
  isLastChunk,
} from '../../store/productsFeed/selectors';
import { getScrollItemsFromProducts } from '../../utils/options';
import { BULK_CHUNK_COUNT, NEXT_CHUNK_FIRST_ITEM } from '../../utils/chunk';

import ItemsScroll from '../ItemsScroll';
import BulkArrows from '../BulkArrows';

import styles from './styles.module.css';

const BulkProductsScroll = () => {
  const dispatch = useDispatch();
  const currentSearchItem = useSelector((store) => store.searchFeed.currentItem);
  const productItems = useSelector((store) => store.productsFeed.items);
  const totalProducts = useSelector((store) => store.productsFeed.totalProducts);
  const currentProductItem = useSelector((store) => store.productsFeed.currentItem);
  const offset = useSelector((store) => store.productsFeed.bulkOffset);
  const backwardOffset = useSelector((store) => store.productsFeed.bulkBackwardOffset);
  const isLastPage = useSelector(isLastChunk);

  // Уходим со страницы. Сбрасываем всё, чтобы корректно вернуться
  useEffect(() => () => {
    dispatch(resetProductsFeed());
    dispatch(resetSearchFeed());
  }, []);

  useEffect(() => {
    if (currentSearchItem) {
      let nextOffset = Math.floor(currentSearchItem.offset / 5) * 5;

      // Все template items помечены, но search item не finished.
      // Загружаем предыдущий чанк, тк следующий не существует.
      if (nextOffset >= totalProducts && totalProducts > 0) {
        nextOffset -= BULK_CHUNK_COUNT;
      }

      dispatch(setCurrentItemNumber(nextOffset + 1));
      dispatch(setBulkOffset({ offset: nextOffset }));
      dispatch(setBulkBackwardOffset({ offset: nextOffset }));

      dispatch(fetchProductsFeed({
        offset: nextOffset,
        count: BULK_CHUNK_COUNT,
        force: true,
        next: NEXT_CHUNK_FIRST_ITEM.FIRST,
      }));
    }
  }, [currentSearchItem, dispatch, totalProducts]);

  const onItemClick = (itemId) => {
    dispatch(setAnyCurrentItem({ id: itemId }));
  };

  const onPrevClick = async () => {
    dispatch(bulkPrevPage());
  };

  const onNextClick = async () => {
    dispatch(bulkNextPage());
  };

  const onDoubleSkipClick = async () => {
    const nextOffset = backwardOffset;
    dispatch(setCurrentItemNumber(nextOffset + 1));
    dispatch(setBulkOffset({ offset: nextOffset }));
    dispatch(fetchProductsFeed({
      offset: nextOffset,
      count: BULK_CHUNK_COUNT,
      force: true,
      next: NEXT_CHUNK_FIRST_ITEM.FIRST,
    }));
  };

  return (
    <div className={styles.container}>
      <ItemsScroll
        items={getScrollItemsFromProducts(productItems)}
        currentItemId={currentProductItem ? currentProductItem.id : null}
        onClick={onItemClick}
        rtl
      />
      <BulkArrows
        onUp={onPrevClick}
        onDown={onNextClick}
        onDoubleSkip={onDoubleSkipClick}
        disableUp={offset === 0}
        disableDown={isLastPage}
        canDoubleSkip={backwardOffset > offset}
      />
    </div>
  );
};

export default BulkProductsScroll;
