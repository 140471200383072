import { createSlice } from '@reduxjs/toolkit';

import {
  fetchFeedbackProductsFeed,
} from './middleware';

const initialState = {
  items: [],
  currentItem: null,
  mainImageIndex: 0,
};

export const feedbackProductsFeedSlice = createSlice({
  name: 'feedbackProductsFeed',
  initialState,
  reducers: {
    setCurrentItem: (state, action) => {
      const nextCurrentItem = state.items.find((i) => i.id === action.payload);
      if (nextCurrentItem) {
        state.currentItem = nextCurrentItem;
        state.mainImageIndex = 0;
      }
    },
    setMainImageIndex: (state, action) => {
      const index = action.payload;

      if (state.currentItem && state.currentItem.images[index]) {
        state.mainImageIndex = index;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchFeedbackProductsFeed.fulfilled, (state, action) => {
      state.items = action.payload;
      const [firstItem] = action.payload;
      if (firstItem) {
        state.currentItem = firstItem;
      }
    });
  },
});

export {
  fetchFeedbackProductsFeed,
};

export const {
  setCurrentItem,
  setMainImageIndex,
} = feedbackProductsFeedSlice.actions;

export default feedbackProductsFeedSlice.reducer;
