import { createSlice } from '@reduxjs/toolkit';

import {
  fetchTemplateById,
  updateTemplateById,
} from './middleware';

const emptyItem = {
  id: null,
  name: null,
  due_date: null,
  submission_date: null,
};

const initialState = emptyItem;

export const editableTemplateSlice = createSlice({
  name: 'editableTemplate',
  initialState,
  reducers: {
    updateLocal: (state, action) => {
      const { field, value } = action.payload;
      state[field] = value;
    },
    resetLocal: () => emptyItem,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchTemplateById.fulfilled, (state, action) => {
      state.name = action.payload.name;
      state.due_date = action.payload.due_date;
      state.submission_date = action.payload.submission_date;
      state.id = action.payload.id;
      state.currency = action.payload.currency;
      state.percent = action.payload.percent;
      state.percent2 = action.payload.percent2;
      state.percent3 = action.payload.percent3;
      state.percent4 = action.payload.percent4;
    });
    builder.addCase(updateTemplateById.fulfilled, (state, action) => {
      state.name = action.payload.name;
      state.due_date = action.payload.due_date;
      state.submission_date = action.payload.submission_date;
      state.id = action.payload.id;
      state.currency = action.payload.currency;
      state.percent = action.payload.percent;
      state.percent2 = action.payload.percent2;
      state.percent3 = action.payload.percent3;
      state.percent4 = action.payload.percent4;
    });
  },
});

export const { updateLocal, resetLocal } = editableTemplateSlice.actions;

export {
  fetchTemplateById,
  updateTemplateById,
};

export default editableTemplateSlice.reducer;
