import { createSlice } from '@reduxjs/toolkit';

import { fetchTemplates, createTemplate, deleteTemplate } from './middleware';

const initialState = {
  items: [],
  searchQuery: '',
  currentPage: 0,
  pageSize: 10,
};

export const templatesSlice = createSlice({
  name: 'templates',
  initialState,
  reducers: {
    setSearchQuery: (state, action) => {
      state.searchQuery = action.payload;
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setPageSize: (state, action) => {
      state.pageSize = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchTemplates.fulfilled, (state, action) => {
      state.items = action.payload;
    });
    builder.addCase(createTemplate.fulfilled, (state, action) => {
      state.items.push(action.payload);
    });
    builder.addCase(deleteTemplate.fulfilled, (state, action) => {
      state.items = state.items.filter((item) => item.id !== action.meta.arg);
    });
  },
});

export { fetchTemplates, createTemplate, deleteTemplate };

export const { setSearchQuery, setCurrentPage, setPageSize } = templatesSlice.actions;

export default templatesSlice.reducer;
