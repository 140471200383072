import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  getTemplates,
  createTemplate as createTemplateApiCall,
  deleteTemplateById,
} from '../../utils/api';

export const fetchTemplates = createAsyncThunk(
  'templates/fetchTemplates',
  () => getTemplates(),
);

export const createTemplate = createAsyncThunk(
  'templates/createTemplate',
  ({ name, dueDate, submissionDate }) => createTemplateApiCall(name, dueDate, submissionDate),
);

export const deleteTemplate = createAsyncThunk(
  'templates/deleteTemplate',
  (id) => deleteTemplateById(id),
);
