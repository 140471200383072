import React, { useState, useEffect, useRef } from 'react';
import { Form } from 'react-bootstrap';
import cx from 'classnames';

import styles from './styles.module.css';

const MAX_LENGTH_TO_HOVER = 115;

const REGEXP = /(\w+-[\wа-я]+)|([\wа-я]+)|(\((\s|[\wа-я])+\))|(-[\wа-я]+)/g;

const TOKEN_COLOR_CLASS = {
  '(': 'text-primary',
  '-': 'text-danger',
};

const colorizeValue = (value) => {
  if (!value) {
    return null;
  }

  const tokens = value.match(REGEXP);

  if (!tokens) {
    return null;
  }

  return tokens.map((token) => (
    <span className={cx(
      styles.token,
      TOKEN_COLOR_CLASS[token[0]],
    )}
    >
      {token}
    </span>
  ));
};

const ColorizedInput = ({
  ...rest
}) => {
  const [focused, setFocused] = useState(false);
  const [width, setWidth] = useState(0);
  const [value, setValue] = useState(rest.defaultValue || '');
  const span = useRef();

  useEffect(() => {
    if (rest.value !== undefined) {
      setValue(rest.value);
    }
  }, [rest.value]);

  useEffect(() => {
    if (span.current) {
      setWidth(span.current.offsetWidth);
    }
  }, [value]);

  const scrollTableToStart = () => {
    document.getElementById('tableContainer').scrollTo({ left: 0 });
  };

  const onInputClick = () => {
    if (rest.disabled) {
      return;
    }
    if (span.current) {
      setWidth(span.current.offsetWidth);
    }
    setFocused(true);
  };

  if (focused) {
    return (
      <div className={styles.focusedInputWrapper}>
        <span ref={span} className={styles.hidden}>{value}</span>
        <Form.Control
          {...rest}
          value={value}
          style={{ width: `${width}px` }}
          className={cx(styles.inputControl, rest.className)}
          onChange={(e) => {
            setValue(e.target.value);
            if (rest.onChange) {
              rest.onChange(e);
            }
          }}
          onBlur={(e) => {
            scrollTableToStart();
            setFocused(false);
            if (rest.onBlur) {
              rest.onBlur(e);
            }
          }}
          autoFocus
        />
      </div>
    );
  }

  return (
    <div className={styles.inputContainer}>
      <span ref={span} className={styles.hidden}>{value}</span>
      {/* Focus handler for the "Tab" key */}
      <input onFocus={onInputClick} className={styles.hidden} />
      <div
        id={rest.id}
        onClick={onInputClick}
        className={cx(
          styles.input,
          span.current && span.current.offsetWidth >= MAX_LENGTH_TO_HOVER && styles.inputHoverable,
        )}
        style={rest.style || {}}
      >
        {colorizeValue(value)}
      </div>
    </div>
  );
};

export default ColorizedInput;
