import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ButtonGroup, ToggleButton } from 'react-bootstrap';

import { PRODUCT_ACTIONS } from '../../utils/actions';

const BulkMatchButtons = ({ itemSelector, matchAction, finalizeIfNeed }) => {
  const dispatch = useDispatch();
  const currentProductItem = useSelector(itemSelector);

  const onClick = async (action, e) => {
    if (e) {
      e.target.blur();
    }
    if (currentProductItem.action === action) {
      await dispatch(matchAction({
        action: PRODUCT_ACTIONS.SKIP,
      })).unwrap();
    } else {
      await dispatch(matchAction({
        action,
      })).unwrap();
    }

    if (finalizeIfNeed) {
      await finalizeIfNeed();
    }
  };

  return (
    <ButtonGroup>
      <ToggleButton
        className="shadow-none"
        type="radio"
        variant={currentProductItem.action === PRODUCT_ACTIONS.MODEL ? 'danger' : 'light'}
        checked={currentProductItem.action === PRODUCT_ACTIONS.MODEL}
        onClick={(e) => onClick(PRODUCT_ACTIONS.MODEL, e)}
      >
        Model
      </ToggleButton>
      <ToggleButton
        className="shadow-none"
        type="radio"
        variant={currentProductItem.action === PRODUCT_ACTIONS.EXACT ? 'success' : 'light'}
        checked={currentProductItem.action === PRODUCT_ACTIONS.EXACT}
        onClick={(e) => onClick(PRODUCT_ACTIONS.EXACT, e)}
      >
        Exact
      </ToggleButton>
    </ButtonGroup>
  );
};

export default BulkMatchButtons;
