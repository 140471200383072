import React from 'react';

import SectionBodyLayout from '../SectionBodyLayout';
import MismatchedTemplateItemsScroll from '../MismatchedTemplateItemsScroll';
import MismatchedTemplateMainImage from '../MismatchedTemplateMainImage';

const TemplatesMismatchedSection = () => (
  <SectionBodyLayout listPosition="right" listComponent={<MismatchedTemplateItemsScroll />}>
    <MismatchedTemplateMainImage />
  </SectionBodyLayout>
);

export default TemplatesMismatchedSection;
