import React from 'react';
import _chunk from 'lodash/chunk';
import { useSelector, useDispatch } from 'react-redux';
import {
  Table,
} from 'react-bootstrap';

import {
  EMPTY_ITEM,
  createItem,
  focusFieldForNextUncompletedItem,
  setCurrentPage,
  setPageSize,
} from '../../store/templateItems';

import ExistingTemplateItemRow from './ExistingTemplateItemRow';
import PaginationBlock from '../PaginationBlock';

import styles from './styles.module.css';

const EditTemplateTable = () => {
  const items = useSelector((store) => store.templateItems.items);
  const columns = useSelector((store) => store.columns.items);
  const currentPage = useSelector((store) => store.templateItems.currentPage);
  const pageSize = useSelector((store) => store.templateItems.pageSize);
  const dispatch = useDispatch();

  const chunks = _chunk(items, pageSize);

  const onItemCompleted = async (itemId) => {
    if (items[items.length - 1].id === itemId) {
      await dispatch(createItem(EMPTY_ITEM));
    }

    dispatch(focusFieldForNextUncompletedItem(itemId, 'sku'));
  };

  const onPageChange = (page) => {
    dispatch(setCurrentPage(page));
  };

  const onPageSizeChange = (size) => {
    dispatch(setPageSize(size));
  };

  return (
    <div className={styles.tableContainer} id="tableContainer">
      <Table className={styles.table}>
        <thead>
          <tr>
            {columns.number && <td>#</td>}
            {columns.sku && <td>SKU</td>}
            {columns.manual_price && <td>Manual&nbsp;Price</td>}
            {columns.image && <td>Image</td>}
            {columns.brands && <td>Brand</td>}
            {columns.name && <td>Name</td>}
            {columns.colors && <td>Color</td>}
            {columns.description && <td>Description</td>}
            {columns.categories && <td>Categories</td>}
            {columns.fabric && <td>Fabric</td>}
            {columns.note && <td>Note</td>}
          </tr>
        </thead>
        <tbody>
          {chunks.length > 0 && chunks[currentPage].map((item, idx) => (
            <ExistingTemplateItemRow
              key={item.id}
              item={item}
              onItemCompleted={() => onItemCompleted(item.id)}
              withoutDelete={idx + currentPage * pageSize === items.length - 1}
              idx={idx + currentPage * pageSize}
              last={idx + currentPage * pageSize === items.length - 1}
              rawIndex={idx}
            />
          ))}
        </tbody>
      </Table>
      <PaginationBlock
        current={currentPage}
        total={chunks.length}
        onChange={onPageChange}
        pageSize={pageSize}
        onChangePageSize={onPageSizeChange}
      />
    </div>
  );
};

export default EditTemplateTable;
