import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import styles from './styles.module.css';

const SectionBodyLayout = ({ listPosition, listComponent, children }) => (
  <div className={styles.container}>
    <div className={cx(styles.left, listPosition === 'right' && styles.justify)}>
      {listPosition === 'left' ? listComponent : children }
    </div>
    <div className={cx(styles.right, listPosition === 'left' && styles.justify)}>
      {listPosition === 'right' ? listComponent : children }
    </div>
  </div>
);

SectionBodyLayout.propTypes = {
  listPosition: PropTypes.oneOf(['left', 'right']).isRequired,
  listComponent: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
};

export default SectionBodyLayout;
