import { createSlice } from '@reduxjs/toolkit';
import { getNowAndOneMonthAgo } from '../../utils/date';

import {
  getMatchers,
  createMatcher,
  updateMatcher,
  deleteMatcher,
  getMetrics,
  setDateFrom,
  setDateTo,
} from './middleware';

const { now, prev } = getNowAndOneMonthAgo();

const initialState = {
  matchers: [],
  selectedMatcher: null,
  metrics: {},
  dateFrom: prev,
  dateTo: now,
  error: '',
};

export const matchersSlice = createSlice({
  name: 'matchers',
  initialState,
  reducers: {
    setError: (state, action) => {
      state.error = action.payload.error;
    },
    setDateFromLocal: (state, action) => {
      state.dateFrom = action.payload;
    },
    setDateToLocal: (state, action) => {
      state.dateTo = action.payload;
    },
    setSelectedMatcherByIdLocal: (state, action) => {
      const matcher = state.matchers.find((m) => `${m.id}` === action.payload);
      if (matcher) {
        state.selectedMatcher = matcher;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getMatchers.fulfilled, (state, action) => {
      state.matchers = action.payload;
    });
    builder.addCase(updateMatcher.fulfilled, (state, action) => {
      let matcherToUpdate = state.matchers.find((matcher) => matcher.id === action.payload.id);
      if (matcherToUpdate) {
        matcherToUpdate = action.payload;
      }
    });
    builder.addCase(getMetrics.fulfilled, (state, action) => {
      state.metrics = action.payload;
    });
  },
});

export {
  getMatchers,
  createMatcher,
  updateMatcher,
  deleteMatcher,
  getMetrics,
  setDateFrom,
  setDateTo,
};

export const {
  setDateFromLocal,
  setDateToLocal,
  setError,
  setSelectedMatcherByIdLocal,
} = matchersSlice.actions;

export default matchersSlice.reducer;
