import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { Form, InputGroup } from 'react-bootstrap';
import { BsXCircle } from 'react-icons/bs';

import ButtonWithIcon from '../ButtonWithIcon';

import { setSearchQuery } from '../../store/templates';

const TemplatesSearch = ({ className }) => {
  const dispatch = useDispatch();
  const query = useSelector((state) => state.templates.searchQuery);

  return (
    <InputGroup className={cx(className)}>
      <Form.Control
        autoFocus
        value={query}
        onChange={(e) => dispatch(setSearchQuery(e.target.value))}
        placeholder="Search by template name"
      />
      {query && (
        <ButtonWithIcon
          Icon={<BsXCircle />}
          variant="outline-secondary"
          onClick={() => dispatch(setSearchQuery(''))}
        />
      )}
    </InputGroup>
  );
};

TemplatesSearch.propTypes = {
  className: PropTypes.string,
};

TemplatesSearch.defaultProps = {
  className: '',
};

export default TemplatesSearch;
