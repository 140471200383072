import React, { useEffect } from 'react';
import _chunk from 'lodash/chunk';
import { useSelector, useDispatch } from 'react-redux';
import {
  ListGroup, Container, Row, Col,
} from 'react-bootstrap';

import { fetchTemplates, setCurrentPage, setPageSize } from '../../store/templates';

import TemplateSummary from '../TemplateSummary';
import PaginationBlock from '../PaginationBlock';

const TemplatesList = () => {
  const templates = useSelector((state) => {
    const { items, searchQuery } = state.templates;
    return items.filter((item) => item.name.toLowerCase().includes(searchQuery.toLowerCase()));
  });
  const pageSize = useSelector((store) => store.templates.pageSize);
  const currentPage = useSelector((store) => store.templates.currentPage);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchTemplates());
  }, [dispatch]);

  const chunks = _chunk(templates, pageSize);
  const items = chunks[currentPage] || [];

  const completed = items
    .filter((template) => template.completed_count < template.items_count);
  const incompleted = items
    .filter((template) => template.completed_count >= template.items_count);

  const onPageChange = (page) => {
    dispatch(setCurrentPage(page));
  };

  const onPageSizeChange = (size) => {
    dispatch(setPageSize(size));
  };

  const renderTemplatesSummary = (list) => list.map((template) => (
    <TemplateSummary
      key={template.id}
      name={template.name}
      date={template.submission_date}
      id={template.id}
      progress={template.completed_count}
      from={template.items_count}
    />
  ));

  return (
    <Container>
      <Row>
        <Col>
          <ListGroup>
            {renderTemplatesSummary(completed)}
          </ListGroup>
        </Col>
        <Col>
          <ListGroup>
            {renderTemplatesSummary(incompleted)}
          </ListGroup>
        </Col>
      </Row>
      <br />
      <PaginationBlock
        current={currentPage}
        total={chunks.length}
        onChange={onPageChange}
        pageSize={pageSize}
        onChangePageSize={onPageSizeChange}
        options={[10, 30, 50]}
      />
    </Container>
  );
};

export default TemplatesList;
