import { createContext, useContext, useState } from 'react';

import {
  login as apiLogin, getUser, getCSRF, logOut as apiLogOut,
} from '../utils/api';

export const authContext = createContext();

export const useProvideAuth = () => {
  const [user, setUser] = useState(null);

  const check = async () => {
    await getCSRF();
    const userInfo = await getUser();
    setUser(userInfo);
  };

  const logIn = async (username, password) => {
    const success = await apiLogin(username, password);
    if (success) {
      await check();
    }
    return success;
  };

  const logOut = async () => {
    await apiLogOut();
    setUser(null);
  };

  return {
    user, logIn, logOut, check, isLoggedIn: !!user,
  };
};

const useAuth = () => useContext(authContext);

export default useAuth;
